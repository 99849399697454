import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import {
  ReviewStatusParam,
  RiskAggregationOrderByParam,
  RiskLevelsParam,
} from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  calculatedAtFrom: DateTimeParam,
  calculatedAtTo: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  finalScoreFrom: NumberParam,
  finalScoreTo: NumberParam,
  last: NumberParam,
  lastReviewedFrom: DateTimeParam,
  lastReviewedTo: DateTimeParam,
  orderBy: RiskAggregationOrderByParam,
  playerGlobalId: StringParam,
  reviewStatus: ReviewStatusParam,
  riskLevels: RiskLevelsParam,
};
