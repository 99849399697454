import { useParams } from '@reach/router';
import Tippy from '@tippyjs/react';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerComplianceHistorySearchOrder } from '@/globalTypes';
import { assert } from '@/utils/error';
import {
  renderAmlRiskLevel,
  renderAmlStatus,
  renderCraRiskLevel,
  renderDecision,
  renderExpendingLowerBound,
  renderFundingOptions,
  renderNationality,
  renderOtherFundingDescription,
  renderPlaceOfBirth,
  renderReason,
  renderRequestedFundingOptions,
  renderSalaryLowerBound,
} from '../../hooks/sow-history/outcomeRenderers';
import useGenericSowHistory from '../../hooks/sow-history/useGenericSowHistory';
import {
  PlayerUuidQuery,
  PlayerUuidQueryVariables,
} from './__generated__/component';

const SOWTypesTranslations: Record<string, string> = {
  SubmittedSourceOfWealthEvent: 'Submitted SOW',
  SourceOfWealthVerifiedEvent: 'Source Verified',
  FundingDocumentsRequestedEvent: 'Funding Documents Requested',
  SourceOfWealthRequiredEvent: 'SOW Required',
  AmlRiskLevelChangedEvent: 'AML Risk Level Changed',
  CustomerRiskChangedEvent: 'CRA Risk Level Changed',
};

export const Fragment = graphql`
  fragment SanityPlayerSowHistoryBlock on SanityPlayerSowHistoryBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerUuid($playerId: ID!) {
    player(playerId: $playerId) {
      id
      riskAssessment {
        playerGlobalId
      }
    }
  }
`;

const PlayerSowHistoryBlock: FC<{
  block: Queries.SanityPlayerSowHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const [{ data }] = useQuery<PlayerUuidQuery, PlayerUuidQueryVariables>({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useGenericSowHistory(data?.player?.riskAssessment?.playerGlobalId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerSowHistory',
    data: history,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: PlayerComplianceHistorySearchOrder.processedAt,
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.stringValue({
        field: 'eventType',
        title: 'Event Type',
        filterField: 'eventType',
        getValue: ({ row }) => (
          <Tippy content={row.eventType}>
            <span>{SOWTypesTranslations[row.eventType] ?? row.eventType}</span>
          </Tippy>
        ),
      }),
      s.stringValue({
        field: 'outcome',
        title: 'Outcome',
        wrap: ({ row }) => (
          <ul>
            {renderDecision(row)}
            {renderFundingOptions(row)}
            {renderSalaryLowerBound(row)}
            {renderExpendingLowerBound(row)}
            {renderNationality(row)}
            {renderPlaceOfBirth(row)}
            {renderOtherFundingDescription(row)}
            {renderRequestedFundingOptions(row)}
            {renderAmlStatus(row)}
            {renderAmlRiskLevel(row)}
            {renderReason(row)}
            {renderCraRiskLevel(row)}
          </ul>
        ),
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerSowHistoryBlock;
