import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerRgRiskAssessmentOverviewPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { assert } from '@/utils/error';
import usePlayerRgAutomationHistory from './usePlayerRgAutomationHistory';

export const Fragment = graphql`
  fragment SanityPlayerRgAutomationHistoryBlock on SanityPlayerRgAutomationHistoryBlock {
    title {
      ...SanityLocaleString
    }
    processedAt {
      ...SanityLocaleString
    }
    eventId {
      ...SanityLocaleString
    }
    eventType {
      ...SanityLocaleString
    }
    extraInformation {
      ...SanityLocaleString
    }
    regionId {
      ...SanityLocaleString
    }
    regionName {
      ...SanityLocaleString
    }
    riskId {
      ...SanityLocaleString
    }
    riskName {
      ...SanityLocaleString
    }
    riskLevelBefore {
      ...SanityLocaleString
    }
    riskLevelAfter {
      ...SanityLocaleString
    }
  }
`;

const formatEvent = (event: string) => {
  return event
    .split(/(?=[A-Z])/)
    .join(' ')
    .toString();
};

const PlayerRgAutomationHistoryBlock: FC<{
  block: Queries.SanityPlayerRgAutomationHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    defaultFilter,
    updateFilter,
  } = usePlayerRgAutomationHistory();

  const getRgRiskAssessmentOverviewPage =
    useGetPlayerRgRiskAssessmentOverviewPageLink();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerRgAutomationHistory',
    data: history,
    fetching,
    setOrderBy: () => {},
    desc: true,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) =>
      (row?.playerGlobalId &&
        getRgRiskAssessmentOverviewPage(row.playerGlobalId)) ??
      '',
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: t(block.processedAt),
        fromFilterField: 'from',
        toFilterField: 'to',
      }),
      s.stringValue({
        field: 'eventId',
        title: t(block.eventId),
        getValue: ({ row }) => row.eventId,
      }),
      s.stringValue({
        field: 'eventType',
        title: t(block.eventType),
        getValue: ({ row }) => formatEvent(row.eventType),
      }),
      s.stringValue({
        field: 'extraInformation',
        title: t(block.extraInformation),
        getValue: ({ row }) => row.extraInformation ?? '',
      }),
      s.stringValue({
        field: 'regionId',
        title: t(block.regionId),
        getValue: ({ row }) => row.regionId,
        hidden: true,
      }),
      s.stringValue({
        field: 'regionName',
        title: t(block.regionName),
        getValue: ({ row }) => row.regionName,
      }),
      s.stringValue({
        field: 'riskId',
        title: t(block.riskId),
        getValue: ({ row }) => row.riskId,
        hidden: true,
      }),
      s.stringValue({
        field: 'riskName',
        title: t(block.riskName),
        getValue: ({ row }) => row.riskName,
      }),
      s.stringValue({
        field: 'riskLevelBefore',
        title: t(block.riskLevelBefore),
        getValue: ({ row }) => row.riskLevelBefore,
      }),
      s.stringValue({
        field: 'riskLevelAfter',
        title: t(block.riskLevelAfter),
        getValue: ({ row }) => row.riskLevelAfter,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerRgAutomationHistoryBlock;
