import { useCallback, useMemo } from 'react';
import { gql, useQuery } from 'urql';

import { useDataSource } from '@/contexts';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { swap } from '@/utils/swap';
import {
  PlayerAnalyticsQuery,
  PlayerAnalyticsQueryVariables,
} from './__generated__/usePlayerAnalytics';
import {
  AddAnalyticsParamFn,
  RemoveAnalyticsParamFn,
  SetStartOrEndDate,
  SetTimeRange,
  SwitchAnalyticsParamsPositionsFn,
  mapTimeRangeToVariables,
  stringVariablesFromDates,
} from './helpers';
import { playerQueryParams } from './queryParams';
import { getValueConfig, timePeriodDimensionsMap } from './schema';

export const mapAnalyticsParamToVariables = (
  identifier: string,
): Partial<PlayerAnalyticsQueryVariables> => {
  if (identifier in timePeriodDimensionsMap) {
    return mapTimeRangeToVariables(identifier);
  }

  const valueConfig = getValueConfig(identifier);

  if (valueConfig) {
    return {
      [valueConfig.fieldName || valueConfig.name]: true,
    };
  }

  return { [identifier]: true };
};

const PLAYER_STATS_QUERY = gql`
  query PlayerAnalytics(
    $playerId: ID!
    $affiliateId: Boolean!
    $allWins: Boolean!
    $amountAdjustments: Boolean!
    $amountAllBonus: Boolean!
    $amountBonusAdjustments: Boolean!
    $amountCashbacks: Boolean!
    $amountDeposits: Boolean!
    $amountFailedDeposits: Boolean!
    $amountFailedWithdrawals: Boolean!
    $amountFreeSpinWins: Boolean!
    $amountFtd: Boolean!
    $amountMoneyDrops: Boolean!
    $amountReversals: Boolean!
    $amountWithdrawableWinnings: Boolean!
    $amountWithdrawals: Boolean!
    $AvgBetSize: Boolean!
    $AvgDepositAmount: Boolean!
    $AvgDepositPerUser: Boolean!
    $AvgRevenuePerUser: Boolean!
    $brand: Boolean!
    $calculatedBalance: Boolean!
    $campaignId: Boolean!
    $cashBackEnableLookup: Boolean!
    $currency: Boolean!
    $device: Boolean!
    $exchangeRateBaseCurrency: String
    $exchangeRateDate: LocalDate
    $from: OffsetDateTime!
    $ftd: Boolean!
    $ftdOfNrc: Boolean!
    $gameDescriptor: Boolean!
    $gameId: Boolean!
    $gameCategoryLookup: Boolean!
    $gameProvider: Boolean!
    $gameNameLookup: Boolean!
    $ggr: Boolean!
    $granularity: GranularityEnum!
    $isIncognito: Boolean!
    $isLiveCasinoGameLookup: Boolean!
    $jpc: Boolean!
    $margin: Boolean!
    $moneyDropType: Boolean!
    $NetDeposits: Boolean!
    $ngr: Boolean!
    $ngrInRewards: Boolean!
    $nrc: Boolean!
    $nrcByNsc: Boolean!
    $nsc: Boolean!
    $numAdjustments: Boolean!
    $numBlockAccountRequests: Boolean!
    $numCancelSelfExclusionRequests: Boolean!
    $numCashbacks: Boolean!
    $numCloseAccountRequests: Boolean!
    $numDeposits: Boolean!
    $numFailedDeposits: Boolean!
    $numFailedWithdrawals: Boolean!
    $numReopenAccountRequests: Boolean!
    $numReversals: Boolean!
    $numSelfExclusionRequests: Boolean!
    $numTotalCloseAccountRequests: Boolean!
    $numUniqueActivePlayers: Boolean!
    $numUniqueDepositors: Boolean!
    $numUniqueActiveDepositors: Boolean!
    $numUniquePlayers: Boolean!
    $numUniqueActiveGlobalPlayers: Boolean!
    $numUniqueSessions: Boolean!
    $numWithdrawals: Boolean!
    $orderBy: [ColumnOrderSpecType!]
    $os: Boolean!
    $paymentProvider: Boolean!
    $pendingWithdrawals: Boolean!
    $playerAccountStatusLookup: Boolean!
    $residenceCountryCode: Boolean!
    $timePeriod: Boolean!
    $timeZone: String
    $to: OffsetDateTime!
    $turnover: Boolean!
    $volatilityLookup: Boolean!
    $wagers: Boolean!
    $dataSourceVersion: DataSourceVersionEnum
  ) {
    player(playerId: $playerId) {
      id
      stats(
        dataSourceVersion: $dataSourceVersion
        from: $from
        to: $to
        granularity: $granularity
        orderBy: $orderBy
        exchangeRateBaseCurrency: $exchangeRateBaseCurrency
        exchangeRateDate: $exchangeRateDate
        timeZone: $timeZone
      ) {
        error
        exportedUrl
        rows {
          timePeriod @include(if: $timePeriod)
          gameDescriptor @include(if: $gameDescriptor) {
            json
          }
          affiliateId @include(if: $affiliateId)
          allWins @include(if: $allWins)
          amountAdjustments @include(if: $amountAdjustments)
          amountAllBonus @include(if: $amountAllBonus)
          amountBonusAdjustments @include(if: $amountBonusAdjustments)
          amountCashbacks @include(if: $amountCashbacks)
          amountDeposits @include(if: $amountDeposits)
          amountFailedDeposits @include(if: $amountFailedDeposits)
          amountFailedWithdrawals @include(if: $amountFailedWithdrawals)
          amountFtd @include(if: $amountFtd)
          amountMoneyDrops @include(if: $amountMoneyDrops)
          amountReversals @include(if: $amountReversals)
          amountWithdrawableWinnings @include(if: $amountWithdrawableWinnings)
          amountWithdrawals @include(if: $amountWithdrawals)
          AvgBetSize @include(if: $AvgBetSize)
          AvgDepositAmount @include(if: $AvgDepositAmount)
          AvgDepositPerUser @include(if: $AvgDepositPerUser)
          AvgRevenuePerUser @include(if: $AvgRevenuePerUser)
          brand @include(if: $brand)
          calculatedBalance @include(if: $calculatedBalance)
          campaignId @include(if: $campaignId)
          cashBackEnableLookup @include(if: $cashBackEnableLookup)
          currency @include(if: $currency)
          device @include(if: $device)
          amountFreeSpinWins @include(if: $amountFreeSpinWins)
          ftd @include(if: $ftd)
          ftdOfNrc @include(if: $ftdOfNrc)
          gameId @include(if: $gameId)
          gameCategoryLookup @include(if: $gameCategoryLookup)
          gameProvider @include(if: $gameProvider)
          gameNameLookup @include(if: $gameNameLookup)
          ggr @include(if: $ggr)
          isIncognito @include(if: $isIncognito)
          isLiveCasinoGameLookup @include(if: $isLiveCasinoGameLookup)
          jpc @include(if: $jpc)
          margin @include(if: $margin)
          moneyDropType @include(if: $moneyDropType)
          NetDeposits @include(if: $NetDeposits)
          ngr @include(if: $ngr)
          ngrInRewards @include(if: $ngrInRewards)
          nrc @include(if: $nrc)
          nrcByNsc @include(if: $nrcByNsc)
          nsc @include(if: $nsc)
          numAdjustments @include(if: $numAdjustments)
          numBlockAccountRequests @include(if: $numBlockAccountRequests)
          numCancelSelfExclusionRequests
            @include(if: $numCancelSelfExclusionRequests)
          numCashbacks @include(if: $numCashbacks)
          numCloseAccountRequests @include(if: $numCloseAccountRequests)
          numDeposits @include(if: $numDeposits)
          numFailedDeposits @include(if: $numFailedDeposits)
          numFailedWithdrawals @include(if: $numFailedWithdrawals)
          numReopenAccountRequests @include(if: $numReopenAccountRequests)
          numReversals @include(if: $numReversals)
          numSelfExclusionRequests @include(if: $numSelfExclusionRequests)
          numTotalCloseAccountRequests
            @include(if: $numTotalCloseAccountRequests)
          numUniqueActivePlayers @include(if: $numUniqueActivePlayers)
          numUniqueDepositors @include(if: $numUniqueDepositors)
          numUniqueActiveDepositors @include(if: $numUniqueActiveDepositors)
          numUniquePlayers @include(if: $numUniquePlayers)
          numUniqueActiveGlobalPlayers
            @include(if: $numUniqueActiveGlobalPlayers)
          numUniqueSessions @include(if: $numUniqueSessions)
          numWithdrawals @include(if: $numWithdrawals)
          os @include(if: $os)
          paymentProvider @include(if: $paymentProvider)
          pendingWithdrawals @include(if: $pendingWithdrawals)
          playerAccountStatusLookup @include(if: $playerAccountStatusLookup)
          residenceCountryCode @include(if: $residenceCountryCode)
          turnover @include(if: $turnover)
          volatilityLookup @include(if: $volatilityLookup)
          wagers @include(if: $wagers)
        }
      }
    }
  }
`;

export function usePlayerAnalytics({ playerId }: { playerId: string }) {
  const [query, setQuery] = useQueryParamsWithTime(playerQueryParams);
  const mappedVariables = mapVariables(query);
  const { dataSource } = useDataSource();

  const updateFilter = useCallback<(values: {}) => void>(
    (values) => setQuery(values, 'replaceIn'),
    [setQuery],
  );

  const addParam = useCallback<AddAnalyticsParamFn>(
    ({ key, value }) => {
      setQuery({ [key]: [...query[key], value] });
    },
    [query, setQuery],
  );

  const removeParam = useCallback<RemoveAnalyticsParamFn>(
    ({ key, value }) => {
      setQuery({ [key]: query[key].filter((a) => a !== value) });
    },
    [query, setQuery],
  );

  const setTimeRange = useCallback<SetTimeRange>(
    (newRange) => {
      setQuery(
        {
          timeRange: newRange,
          startDate: undefined,
          endDate: undefined,
        },
        'replaceIn',
      );
    },
    [setQuery],
  );

  const setStartDate = useCallback<SetStartOrEndDate>(
    (newStartDate) => {
      setQuery({ startDate: newStartDate }, 'replaceIn');
    },
    [setQuery],
  );

  const setEndDate = useCallback<SetStartOrEndDate>(
    (newEndDate) => {
      setQuery({ endDate: newEndDate }, 'replaceIn');
    },
    [setQuery],
  );

  const switchQueryIndexes = useCallback<SwitchAnalyticsParamsPositionsFn>(
    ({ key, fromIndex, toIndex }) => {
      setQuery(
        {
          [key]: swap(query[key], fromIndex, toIndex),
        },
        'replaceIn',
      );
    },
    [setQuery, query],
  );

  const analyticsVariables = [
    ...(query.columns as string[]),
    ...(query.rows as string[]),
    ...(query.values as string[]),
  ].reduce((acc, entry) => {
    return {
      ...acc,
      ...mapAnalyticsParamToVariables(entry),
    };
  }, {});

  const hasValues = query.values.length > 0;

  // broken out to own useMemo for easier debugging than inlining it
  const variables = useMemo(() => {
    return {
      ...mappedVariables,
      ...analyticsVariables,
      ...stringVariablesFromDates(
        query.timeRange,
        query.startDate,
        query.endDate,
      ),
    };
  }, [
    analyticsVariables,
    mappedVariables,
    query.endDate,
    query.startDate,
    query.timeRange,
  ]);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerAnalyticsQuery,
    PlayerAnalyticsQueryVariables
  >({
    query: PLAYER_STATS_QUERY,
    variables: {
      ...variables,
      playerId,
      dataSourceVersion: dataSource,
    },
    pause: !hasValues || !playerId,
    requestPolicy: 'network-only',
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter,
    addParam,
    removeParam,
    columns: query.columns as string[],
    rows: query.rows as string[],
    values: query.values as string[],
    startDate: query.startDate,
    endDate: query.endDate,
    timeRange: query.timeRange,
    setTimeRange,
    setStartDate,
    setEndDate,
    switchQueryIndexes,
    stats: hasValues ? data?.player?.stats?.rows : [],
    exportedUrl: hasValues ? data?.player?.stats?.exportedUrl : null,
  };
}
