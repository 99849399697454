export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** GQL player's affiliate id */
  AffiliateId: { input: string; output: string; }
  /** GQL player's affiliate token */
  AffiliateToken: { input: string; output: string; }
  /** The `BigDecimal` scalar type represents signed fractional values with arbitrary precision. */
  BigDecimal: { input: number; output: number; }
  /** Campaign linked to a reward(s) */
  CampaignId: { input: string; output: string; }
  Duration: { input: any; output: any; }
  EmailAddress: { input: string; output: string; }
  /** ISO Currency code with crypto support */
  ISOCurrencyCode: { input: string; output: string; }
  /** Raw JSON value */
  Json: { input: { [key: string]: any }; output: { [key: string]: any }; }
  LocalDate: { input: string; output: string; }
  /**
   * The `Long` scalar type represents non-fractional signed whole numeric values.
   * Long can represent values between -(2^63) and 2^63 - 1.
   */
  Long: { input: number; output: number; }
  /** Non Empty string type */
  NonEmptyString: { input: string; output: string; }
  OffsetDateTime: { input: string; output: string; }
  /** GQL player's phone number scalar type */
  PhoneNumber: { input: string; output: string; }
  /** Positive Big Decimal value */
  PositiveBigDecimal: { input: number; output: number; }
  /** Positive Int value */
  PositiveInt: { input: number; output: number; }
};

/** Player payment summary info */
export type AccountStatus = {
  __typename: 'AccountStatus';
  cause: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Statuses of a player accounts */
export enum AccountStatusEnum {
  Closed = 'Closed',
  Open = 'Open'
}

/** Adjustment Category. */
export enum AdjustmentCategory {
  Adjustment = 'Adjustment',
  Bonus = 'Bonus'
}

/** Adjustment Type */
export enum AdjustmentType {
  Bonus = 'Bonus',
  Cashdrop = 'Cashdrop',
  Gameplay = 'Gameplay',
  Goodwill = 'Goodwill',
  Payment = 'Payment',
  TestFunds = 'TestFunds'
}

/** Adjustment Type Data */
export type AdjustmentTypeData = {
  __typename: 'AdjustmentTypeData';
  /** category. */
  category: Scalars['String']['output'];
  /** type name. */
  typeName: Scalars['String']['output'];
};

/** AffiliateInfo with token and affiliate id. */
export type AffiliateInfo = {
  __typename: 'AffiliateInfo';
  affiliateId: Maybe<Scalars['String']['output']>;
  affiliateToken: Maybe<Scalars['String']['output']>;
};

export type AffiliateParameters = {
  id: Scalars['AffiliateId']['input'];
  token: Scalars['AffiliateToken']['input'];
};

/** Okta User (Agent) information */
export type Agent = {
  __typename: 'Agent';
  agentId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastLogin: Maybe<Scalars['OffsetDateTime']['output']>;
  lastName: Scalars['String']['output'];
};

export type AgentInitiator = {
  __typename: 'AgentInitiator';
  agent: Maybe<Agent>;
};

/** Agent role type */
export enum AgentRoleEnum {
  AccessAmok = 'AccessAmok',
  AccessBetInferno = 'AccessBetInferno',
  AccessDJack = 'AccessDJack',
  AccessDiceWise = 'AccessDiceWise',
  AccessF9 = 'AccessF9',
  AccessGemler = 'AccessGemler',
  AccessGoldroll = 'AccessGoldroll',
  AccessGreatSlots = 'AccessGreatSlots',
  AccessJackpotCow = 'AccessJackpotCow',
  AccessKim = 'AccessKim',
  AccessLuckyFuel = 'AccessLuckyFuel',
  AccessLuckyJungle = 'AccessLuckyJungle',
  AccessMadRush = 'AccessMadRush',
  AccessMonsino = 'AccessMonsino',
  AccessMonster = 'AccessMonster',
  AccessMountGold = 'AccessMountGold',
  AccessNifty = 'AccessNifty',
  AccessNitroWin = 'AccessNitroWin',
  AccessOverload = 'AccessOverload',
  AccessRaptor = 'AccessRaptor',
  AccessRazorSpin = 'AccessRazorSpin',
  AccessRefuelCasino = 'AccessRefuelCasino',
  AccessReko = 'AccessReko',
  AccessSlotCloud = 'AccessSlotCloud',
  AccessSlotHype = 'AccessSlotHype',
  AccessSlotOwl = 'AccessSlotOwl',
  AccessSlotWish = 'AccessSlotWish',
  AccessSlotable = 'AccessSlotable',
  AccessSpinero = 'AccessSpinero',
  AccessUnlimitCasino = 'AccessUnlimitCasino',
  AccessWinsly = 'AccessWinsly',
  AccessWinzie = 'AccessWinzie',
  Adjustments = 'Adjustments',
  AffiliateManager = 'AffiliateManager',
  CMS = 'CMS',
  CustomerSupport = 'CustomerSupport',
  CustomerSupportAgent = 'CustomerSupportAgent',
  CustomerSupportTL = 'CustomerSupportTL',
  Developers = 'Developers',
  Manager = 'Manager',
  Marketing = 'Marketing',
  PaymentAgent = 'PaymentAgent',
  PaymentTL = 'PaymentTL',
  PaymentsAndFraud = 'PaymentsAndFraud',
  VIPManager = 'VIPManager'
}

/** All linked accounts */
export type AllLinkedAccounts = {
  __typename: 'AllLinkedAccounts';
  addressLine: Maybe<Scalars['String']['output']>;
  birthDate: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  city: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedAccounts: Array<LinkedAccounts>;
  playerId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  zipCode: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type AllLinkedAccountsItemConnection = {
  __typename: 'AllLinkedAccountsItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AllLinkedAccountsItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AllLinkedAccountsItemEdge = {
  __typename: 'AllLinkedAccountsItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AllLinkedAccounts;
};

/** The aml risk level of a player */
export enum AmlRiskLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  NotSet = 'NotSet'
}

/** SoW history entry for AML Risk Level changed */
export type AmlRiskLevelChangedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'AmlRiskLevelChangedItem';
  amlRiskLevel: AmlRiskLevel;
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  reason: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** Authentication Method */
export type AuthenticationMethod = {
  __typename: 'AuthenticationMethod';
  authenticationMethodId: Scalars['String']['output'];
  brand: Brand;
  /**
   * Number of allowed invalid login attempts before player is locked from logging in
   * @deprecated Get info from `globalLoginAttemptsInfo` object instead
   */
  invalidGlobalLoginAttemptsAllowed: Scalars['Int']['output'];
  /**
   * Timestamp when player was locked due to too many invalid login attempts
   * @deprecated Get info from `globalLoginAttemptsInfo` object instead
   */
  maxGlobalLoginAttemptsReachedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  playerId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** Blocking limit. */
export type BlockingLimit = {
  __typename: 'BlockingLimit';
  /** Value of roles the agent had at the time of block/unblock. */
  agentRoles: Array<Scalars['String']['output']>;
  /** Value of the blocking status for the limit limit. */
  blocked: Scalars['Boolean']['output'];
  /** Date of configuration. */
  configuredAt: Scalars['OffsetDateTime']['output'];
  /** Value of the initiator who blocked/unblocked the limit. */
  initiatorId: Scalars['String']['output'];
  /** Value of the initiator type who blocked/unblocked the limit. */
  initiatorType: Scalars['String']['output'];
  /** Date when blocking limit will take effect. */
  validFrom: Scalars['OffsetDateTime']['output'];
};

export type BoSourceOfFund = {
  sourceOfFundType: SourceOfFundType;
  wasProvided: Scalars['Boolean']['input'];
};

export type BoSourceOfFundType = {
  __typename: 'BoSourceOfFundType';
  /** Type of the source of fund */
  sourceOfFundType: SourceOfFundType;
  /** Was provided? */
  wasProvided: Scalars['Boolean']['output'];
};

export type BoSourceOfFunds = {
  sourceOfFunds: Array<BoSourceOfFund>;
};

export type BoSourceOfFundsType = {
  __typename: 'BoSourceOfFundsType';
  /** list of provided source of funds */
  sourceOfFunds: Array<BoSourceOfFundType>;
};

/** Information about a single bonus */
export type Bonus = {
  __typename: 'Bonus';
  /** The original bonus amount given */
  amount: Scalars['BigDecimal']['output'];
  /** The amount converted to the real money balance */
  amountConverted: Maybe<Scalars['BigDecimal']['output']>;
  /** The bonus amount not converted to real money money balance at time of conversion */
  amountDiscarded: Maybe<Scalars['BigDecimal']['output']>;
  amountForfeited: Maybe<Scalars['BigDecimal']['output']>;
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  /** The current bonus balance */
  closingBalance: Scalars['BigDecimal']['output'];
  convertedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  createdAt: Scalars['OffsetDateTime']['output'];
  createdBy: Initiator;
  currency: Scalars['String']['output'];
  /** Time when expiration was triggered */
  expiredAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Time when bonus will expire */
  expiresAt: Scalars['OffsetDateTime']['output'];
  /** Reason why a bonus was forfeited */
  forfeitCause: Maybe<Scalars['String']['output']>;
  forfeitedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  /** The maximum amount that can converted to real money */
  maxPayout: Scalars['BigDecimal']['output'];
  playerId: Scalars['String']['output'];
  /** Time of the latest update to the bonus */
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
  status: BonusStatus;
  /** The amount that has been turned over so far */
  wageringProgress: Scalars['BigDecimal']['output'];
  /** The total amount that has to be turned over */
  wageringRequirement: Scalars['BigDecimal']['output'];
  /** The number of times the amount has to be turned over */
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** Bonus balance should have been updated but bonus was already non active */
export type BonusBalanceNotAffectedItem = BonusHistoryItem & {
  __typename: 'BonusBalanceNotAffectedItem';
  /** The amount that could not be given to the bonus balance */
  amountIgnored: Scalars['BigDecimal']['output'];
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  /** The reason that the amount could not be given to the bonus balance */
  reason: Scalars['String']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type BonusConnection = {
  __typename: 'BonusConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<BonusEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Bonus converted */
export type BonusConvertedItem = BonusHistoryItem & {
  __typename: 'BonusConvertedItem';
  /** The amount that was converted */
  amountConverted: Scalars['BigDecimal']['output'];
  /** The amount that was discarded */
  amountDiscarded: Scalars['BigDecimal']['output'];
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  id: Scalars['String']['output'];
  /** The maximum amount that can be paid out */
  maxPayout: Scalars['BigDecimal']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
};

/** Bonus created */
export type BonusCreatedItem = BonusHistoryItem & {
  __typename: 'BonusCreatedItem';
  /** The original bonus amount given */
  amount: Scalars['BigDecimal']['output'];
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  expiresAt: Scalars['OffsetDateTime']['output'];
  id: Scalars['String']['output'];
  initiator: Initiator;
  /** The maximum amount that can be paid out */
  maxPayout: Scalars['BigDecimal']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
  /** The amount that has to be turned over */
  wageringRequirement: Scalars['BigDecimal']['output'];
  /** The number of times the amount has to be turned over */
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BonusEdge = {
  __typename: 'BonusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Bonus;
};

/** Type of bonus event */
export enum BonusEventType {
  /** Bonus balance should have been updated but bonus was already non active */
  BonusBalanceNotAffected = 'BonusBalanceNotAffected',
  /** Bonus converted */
  BonusConverted = 'BonusConverted',
  /** Bonus created */
  BonusCreated = 'BonusCreated',
  /** Bonus expired */
  BonusExpired = 'BonusExpired',
  /** Bonus forfeited */
  BonusForfeited = 'BonusForfeited'
}

/** Bonus expired */
export type BonusExpiredItem = BonusHistoryItem & {
  __typename: 'BonusExpiredItem';
  /** The amount that was forfeited */
  amountForfeited: Scalars['BigDecimal']['output'];
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
  /** The amount that was wagered */
  wageringProgress: Scalars['BigDecimal']['output'];
  /** The number of times the amount has to be turned over */
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** Bonus forfeited */
export type BonusForfeitedItem = BonusHistoryItem & {
  __typename: 'BonusForfeitedItem';
  /** The amount that was forfeited */
  amountForfeited: Scalars['BigDecimal']['output'];
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  /** The reason why the bonus was forfeited */
  cause: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
  /** The amount that was wagered */
  wageringProgress: Scalars['BigDecimal']['output'];
  /** The number of times the amount has to be turned over */
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type BonusHistoryConnection = {
  __typename: 'BonusHistoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<BonusHistoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BonusHistoryEdge = {
  __typename: 'BonusHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BonusHistoryItem;
};

/** An entry in the bonus history */
export type BonusHistoryItem = {
  bonusId: Scalars['String']['output'];
  brand: BrandEnum;
  campaignId: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  eventType: BonusEventType;
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  playerSessionId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  rewardId: Maybe<Scalars['String']['output']>;
};

/** Bonus Money drop challenge reward type */
export type BonusMoneyDropChallengeReward = ChallengeRewardType & {
  __typename: 'BonusMoneyDropChallengeReward';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  maxPayout: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
  wageringRequirementFactor: Scalars['Int']['output'];
};

export type BonusMoneyDropInput = {
  amount: Scalars['BigDecimal']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
  maxPayout: Scalars['BigDecimal']['input'];
  wageringRequirementFactor: Scalars['Int']['input'];
};

/** Bonus Money drop reward type */
export type BonusMoneyDropReward = RewardType & {
  __typename: 'BonusMoneyDropReward';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  maxPayout: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** The field by which to order the results */
export enum BonusOrder {
  /** createdAt */
  createdAt = 'createdAt',
  /** processedAt */
  processedAt = 'processedAt'
}

/** Bonus status */
export enum BonusStatus {
  /** Active bonus */
  Active = 'Active',
  /** Converted bonus */
  Converted = 'Converted',
  /** Expired bonus */
  Expired = 'Expired',
  /** Forfeited bonus */
  Forfeited = 'Forfeited'
}

/** Data about a bonus transaction */
export type BonusTransactionData = {
  __typename: 'BonusTransactionData';
  /** The amount of the bonus */
  amount: Scalars['BigDecimal']['output'];
  /** The id of the bonus */
  bonusId: Scalars['String']['output'];
  /** The id of the campaign */
  campaignId: Maybe<Scalars['String']['output']>;
  /** The closing balance for the bonus transaction */
  closingBalance: Scalars['BigDecimal']['output'];
  /** The opening balance for the bonus transaction */
  openingBalance: Scalars['BigDecimal']['output'];
};

/** A brand. */
export type Brand = Node & {
  __typename: 'Brand';
  /** The brand code. */
  code: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  /** The name of the brand. */
  name: Scalars['String']['output'];
};

/** The brand of the site. */
export enum BrandEnum {
  AMOK = 'AMOK',
  DJACK = 'DJACK',
  DWISE = 'DWISE',
  F9CAS = 'F9CAS',
  GEMLR = 'GEMLR',
  GLDRL = 'GLDRL',
  GSLOT = 'GSLOT',
  JPCOW = 'JPCOW',
  KIMCN = 'KIMCN',
  LCKYJ = 'LCKYJ',
  LFUEL = 'LFUEL',
  MDRSH = 'MDRSH',
  MONKA = 'MONKA',
  MRGLD = 'MRGLD',
  MSINO = 'MSINO',
  MTGLD = 'MTGLD',
  NFRNO = 'NFRNO',
  NIFTY = 'NIFTY',
  NITRO = 'NITRO',
  OVRLD = 'OVRLD',
  RAPTR = 'RAPTR',
  RAZOR = 'RAZOR',
  REKO = 'REKO',
  RFLCN = 'RFLCN',
  SHYPE = 'SHYPE',
  SLCLD = 'SLCLD',
  SLOWL = 'SLOWL',
  SLTBL = 'SLTBL',
  SPNRO = 'SPNRO',
  SWISH = 'SWISH',
  UNLMT = 'UNLMT',
  WNSLY = 'WNSLY',
  WNZIE = 'WNZIE'
}

/** A bulk adjustment update error event triggered by the system (error/success). */
export type BulkAdjustmentProgressEventType = Node & {
  __typename: 'BulkAdjustmentProgressEventType';
  /** The id of the object. */
  id: Scalars['ID']['output'];
  /** result */
  result: Scalars['Json']['output'];
};

/** Bulk Adjustment */
export type BulkAdjustmentType = {
  __typename: 'BulkAdjustmentType';
  amountToProcess: Scalars['Int']['output'];
};

/** A bulkTransformPlayersResult */
export type BulkTransformPlayersResult = {
  __typename: 'BulkTransformPlayersResult';
  /** Report url */
  url: Scalars['String']['output'];
};

/** Cashback period information */
export type CashbackPeriodInfo = {
  __typename: 'CashbackPeriodInfo';
  appliedCashbackDates: Array<Scalars['OffsetDateTime']['output']>;
  from: Scalars['OffsetDateTime']['output'];
  to: Scalars['OffsetDateTime']['output'];
};

/** Cashback transaction item */
export type CashbackTransactionItem = {
  __typename: 'CashbackTransactionItem';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  currentCashbackPeriod: Scalars['OffsetDateTime']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  nextCashbackPeriod: Maybe<Scalars['OffsetDateTime']['output']>;
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  status: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type ChallengeBonusMoneyDropInput = {
  amount: Scalars['BigDecimal']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
  maxPayout: Scalars['BigDecimal']['input'];
  wageringRequirementFactor: Scalars['Int']['input'];
};

/** Action which a player has to perform to complete the challenge */
export enum ChallengeCriteria {
  CompleteSoW = 'CompleteSoW',
  Deposit = 'Deposit'
}

export type ChallengeDynamicBonusMoneyDropInput = {
  currency: Scalars['ISOCurrencyCode']['input'];
  /** The maximum amount of bonus money that can be dropped */
  maxAmount: Scalars['BigDecimal']['input'];
  /** The maximum amount that can be paid out from the bonus money */
  maxPayout: Scalars['BigDecimal']['input'];
  multiplier: Scalars['BigDecimal']['input'];
  wageringRequirementFactor: Scalars['Int']['input'];
};

export type ChallengeDynamicFreeSpins = {
  currency: Scalars['ISOCurrencyCode']['input'];
  desktopId?: InputMaybe<Scalars['String']['input']>;
  freeSpinValue: Scalars['Long']['input'];
  maxFreeSpinsAmount: Scalars['Int']['input'];
  mobileId?: InputMaybe<Scalars['String']['input']>;
  multiplier: Scalars['BigDecimal']['input'];
  payLines: Scalars['Int']['input'];
  provider: Scalars['String']['input'];
};

export type ChallengeDynamicMoneyDropInput = {
  currency: Scalars['ISOCurrencyCode']['input'];
  /** The maximum amount of bonus money that can be dropped */
  maxAmount: Scalars['BigDecimal']['input'];
  multiplier: Scalars['BigDecimal']['input'];
};

export type ChallengeFreeSpins = {
  amountFreeSpins: Scalars['Int']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
  desktopId?: InputMaybe<Scalars['String']['input']>;
  freeSpinValue: Scalars['Long']['input'];
  mobileId?: InputMaybe<Scalars['String']['input']>;
  payLines: Scalars['Int']['input'];
  provider: Scalars['String']['input'];
};

/** A challenge item */
export type ChallengeItem = {
  __typename: 'ChallengeItem';
  brands: Array<BrandEnum>;
  challengeActions: Array<ChallengeCriteria>;
  challengeId: Scalars['ID']['output'];
  challengeRewards: Array<ChallengeReward>;
  challengeUUID: Scalars['String']['output'];
  countries: Array<Scalars['String']['output']>;
  eligibleCriteria: Array<EligibilityCriteria>;
  endDate: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfPlayers: Scalars['Int']['output'];
  players: PlayerChallengeItemConnection;
  startDate: Scalars['OffsetDateTime']['output'];
  status: ChallengeStatus;
};


/** A challenge item */
export type ChallengeItemplayersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type ChallengeItemConnection = {
  __typename: 'ChallengeItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChallengeItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChallengeItemEdge = {
  __typename: 'ChallengeItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ChallengeItem;
};

/** A challenge item, with globalId field */
export type ChallengeItemV3 = {
  __typename: 'ChallengeItemV3';
  brands: Array<BrandEnum>;
  challengeActions: Array<ChallengeCriteria>;
  challengeId: Scalars['ID']['output'];
  challengeRewards: Array<ChallengeRewardV2>;
  challengeUUID: Scalars['String']['output'];
  countries: Array<Scalars['String']['output']>;
  eligibleCriteria: Array<EligibilityCriteria>;
  endDate: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfPlayers: Scalars['Int']['output'];
  players: PlayerChallengeItemV2Connection;
  startDate: Scalars['OffsetDateTime']['output'];
  status: ChallengeStatus;
};


/** A challenge item, with globalId field */
export type ChallengeItemV3playersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type ChallengeItemV3Connection = {
  __typename: 'ChallengeItemV3Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChallengeItemV3Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChallengeItemV3Edge = {
  __typename: 'ChallengeItemV3Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ChallengeItemV3;
};

export type ChallengeMoneyDropInput = {
  amount: Scalars['BigDecimal']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
};

/** Reward which will be created for a player once their challenge is completed */
export type ChallengeReward = {
  __typename: 'ChallengeReward';
  activateAfter: Maybe<Scalars['Duration']['output']>;
  expiresIn: Maybe<Scalars['Duration']['output']>;
  rewardType: RewardType;
};

export type ChallengeRewardInput = {
  activateAfter?: InputMaybe<Scalars['String']['input']>;
  claimType: ClaimType;
  expiresIn?: InputMaybe<Scalars['String']['input']>;
  rewardTypeInput: RewardTypeInput;
};

export type ChallengeRewardInputV2 = {
  activateAfter?: InputMaybe<Scalars['String']['input']>;
  challengeRewardTypeInput: ChallengeRewardTypeInput;
  claimType: ClaimType;
  expiresIn?: InputMaybe<Scalars['String']['input']>;
};

/** Details about a challenge reward */
export type ChallengeRewardType = {
  name: Scalars['String']['output'];
};

/** Challenge Reward types */
export enum ChallengeRewardTypeEnum {
  BonusMoneyDropType = 'BonusMoneyDropType',
  DynamicBonusMoneyDropType = 'DynamicBonusMoneyDropType',
  DynamicFreeSpinsType = 'DynamicFreeSpinsType',
  DynamicMoneyDropType = 'DynamicMoneyDropType',
  FreeSpinsType = 'FreeSpinsType',
  MoneyDropType = 'MoneyDropType',
  Unknown = 'Unknown'
}

export type ChallengeRewardTypeInput = {
  bonusMoneyDrop?: InputMaybe<ChallengeBonusMoneyDropInput>;
  challengeRewardType: ChallengeRewardTypeEnum;
  dynamicBonusMoneyDrop?: InputMaybe<ChallengeDynamicBonusMoneyDropInput>;
  dynamicFreeSpins?: InputMaybe<ChallengeDynamicFreeSpins>;
  dynamicMoneyDrop?: InputMaybe<ChallengeDynamicMoneyDropInput>;
  freeSpins?: InputMaybe<ChallengeFreeSpins>;
  moneyDrop?: InputMaybe<ChallengeMoneyDropInput>;
};

/** Reward which will be created for a player once their challenge is completed */
export type ChallengeRewardV2 = {
  __typename: 'ChallengeRewardV2';
  activateAfter: Maybe<Scalars['Duration']['output']>;
  challengeRewardType: ChallengeRewardType;
  expiresIn: Maybe<Scalars['Duration']['output']>;
};

/** Possible challenge statuses */
export enum ChallengeStatus {
  Activated = 'Activated',
  Expired = 'Expired',
  Removed = 'Removed',
  Scheduled = 'Scheduled'
}

/** Reward claim type */
export enum ClaimType {
  Instant = 'Instant',
  Manual = 'Manual'
}

/** Cause of closing account. */
export enum CloseAccountCauseV3 {
  AbusiveBehaviour = 'AbusiveBehaviour',
  AccountIssues = 'AccountIssues',
  AdverseMedia = 'AdverseMedia',
  BlockCustomerRequest = 'BlockCustomerRequest',
  ClosedInternalDecision = 'ClosedInternalDecision',
  ConfirmedFraud = 'ConfirmedFraud',
  ConfirmedPromotionAbuse = 'ConfirmedPromotionAbuse',
  CrossLicenseBlock = 'CrossLicenseBlock',
  ExceededSOWF = 'ExceededSOWF',
  FailureProvideDD = 'FailureProvideDD',
  InsufficientGameChoices = 'InsufficientGameChoices',
  MLRO = 'MLRO',
  MigrationCause = 'MigrationCause',
  MultipleAccounts = 'MultipleAccounts',
  NonCAPExtremeCRARisk = 'NonCAPExtremeCRARisk',
  NotEnoughBonusesOrPromotions = 'NotEnoughBonusesOrPromotions',
  PEPListMatch = 'PEPListMatch',
  PEPSanctionsListMatch = 'PEPSanctionsListMatch',
  PaymentIssues = 'PaymentIssues',
  PendingInternalChecks = 'PendingInternalChecks',
  PermanentCustomerRequest = 'PermanentCustomerRequest',
  ProhibitedCountry = 'ProhibitedCountry',
  ResponsibleGaming = 'ResponsibleGaming',
  RgBrandClosure = 'RgBrandClosure',
  RgPendingInternalChecks = 'RgPendingInternalChecks',
  SanctionsListMatch = 'SanctionsListMatch',
  SuspectedFraud = 'SuspectedFraud',
  SuspectedPromotionAbuse = 'SuspectedPromotionAbuse',
  TemporaryCustomerRequest = 'TemporaryCustomerRequest',
  ToBeCategorized = 'ToBeCategorized'
}

/** Cause of closing account. */
export enum CloseAccountCauseV4 {
  AbusiveBehaviour = 'AbusiveBehaviour',
  AccountIssues = 'AccountIssues',
  AdverseMedia = 'AdverseMedia',
  BlockCustomerRequest = 'BlockCustomerRequest',
  ClosedInternalDecision = 'ClosedInternalDecision',
  ConfirmedFraud = 'ConfirmedFraud',
  ConfirmedPromotionAbuse = 'ConfirmedPromotionAbuse',
  CrossLicenseBlock = 'CrossLicenseBlock',
  ExceededSOWF = 'ExceededSOWF',
  FailureProvideDD = 'FailureProvideDD',
  InsufficientGameChoices = 'InsufficientGameChoices',
  MLRO = 'MLRO',
  MigrationCause = 'MigrationCause',
  MultipleAccounts = 'MultipleAccounts',
  NonCAPExtremeCRARisk = 'NonCAPExtremeCRARisk',
  NotEnoughBonusesOrPromotions = 'NotEnoughBonusesOrPromotions',
  PEPListMatch = 'PEPListMatch',
  PaymentIssues = 'PaymentIssues',
  PendingInternalChecks = 'PendingInternalChecks',
  PermanentCustomerRequest = 'PermanentCustomerRequest',
  ProhibitedCountry = 'ProhibitedCountry',
  ResponsibleGaming = 'ResponsibleGaming',
  RgBrandClosure = 'RgBrandClosure',
  RgPendingInternalChecks = 'RgPendingInternalChecks',
  SanctionsListMatch = 'SanctionsListMatch',
  SuspectedFraud = 'SuspectedFraud',
  SuspectedPromotionAbuse = 'SuspectedPromotionAbuse',
  TemporaryCustomerRequest = 'TemporaryCustomerRequest',
  ToBeCategorized = 'ToBeCategorized'
}

/** A closing balances export details. */
export type ClosingBalancesExportDetails = {
  __typename: 'ClosingBalancesExportDetails';
  url: Scalars['String']['output'];
};

/** A closure search item. */
export type ClosureSearchItem = {
  __typename: 'ClosureSearchItem';
  comment: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  playerId: Scalars['ID']['output'];
  startDate: Maybe<Scalars['OffsetDateTime']['output']>;
  status: ClosureStatus;
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ClosureSearchItemConnection = {
  __typename: 'ClosureSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ClosureSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ClosureSearchItemEdge = {
  __typename: 'ClosureSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ClosureSearchItem;
};

/** The order to list closure search results. */
export enum ClosureSearchOrder {
  startDate = 'startDate'
}

/** The status of closure. */
export enum ClosureStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive'
}

/** Type of closure. */
export enum ClosureType {
  SelfExclusion = 'SelfExclusion',
  Timeout = 'Timeout'
}

/** A statistics column value sorting order specification. */
export type ColumnOrderSpecType = {
  column: Scalars['String']['input'];
  order: OrderingSpecType;
};

/** A compliance limit for an individual person across all brands */
export type ComplianceGlobalPlayerLimit = {
  __typename: 'ComplianceGlobalPlayerLimit';
  activeFrom: Maybe<Scalars['OffsetDateTime']['output']>;
  euroAmount: Scalars['BigDecimal']['output'];
  euroThreshold: Scalars['BigDecimal']['output'];
  latestChangeAt: Scalars['OffsetDateTime']['output'];
  limitName: Scalars['String']['output'];
  limitReached: Scalars['Boolean']['output'];
  rollingPeriod: RollingPeriod;
};

/** A content search item. */
export type ContentSearchItem = Node & {
  __typename: 'ContentSearchItem';
  contentId: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Maybe<Scalars['OffsetDateTime']['output']>;
  creator: Maybe<Initiator>;
  doc: Maybe<Scalars['Json']['output']>;
  draft: Maybe<Scalars['Json']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  link: Maybe<ContentSearchItem>;
  links: Array<ContentSearchItem>;
  publishedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  publisher: Maybe<Initiator>;
  repository: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  updater: Maybe<Initiator>;
};


/** A content search item. */
export type ContentSearchItemlinkArgs = {
  draft: Scalars['Boolean']['input'];
  path: Scalars['String']['input'];
};


/** A content search item. */
export type ContentSearchItemlinksArgs = {
  draft: Scalars['Boolean']['input'];
  path: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type ContentSearchItemConnection = {
  __typename: 'ContentSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ContentSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContentSearchItemEdge = {
  __typename: 'ContentSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ContentSearchItem;
};

/** The order to list content search results. */
export enum ContentSearchOrder {
  contentType = 'contentType',
  createdAt = 'createdAt',
  publishedAt = 'publishedAt',
  repository = 'repository',
  status = 'status',
  updatedAt = 'updatedAt'
}

/** Status of a document. */
export enum ContentStatusType {
  Archived = 'Archived',
  Draft = 'Draft',
  Published = 'Published'
}

/** SoW history entry for CRA Risk Level changed */
export type CraRiskLevelChangedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'CraRiskLevelChangedItem';
  customerRisk: CustomerRisk;
  eventType: Scalars['String']['output'];
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** Info about a crypto payment */
export type CryptoInformation = {
  __typename: 'CryptoInformation';
  /** The crypto network on which as payment took place */
  network: Maybe<CryptoNetworkEnum>;
  /** The fee on the crypto network */
  networkFee: Maybe<Fee>;
  /** Used for some crypto together with address to specify a destination */
  tag: Maybe<Scalars['String']['output']>;
  /** The address to which the payment is going */
  toWalletAddress: Maybe<Scalars['String']['output']>;
  /** Id to find the payment on the block chain */
  txHashId: Maybe<Scalars['String']['output']>;
};

/** The crypto network on which as payment took place */
export enum CryptoNetworkEnum {
  BCH = 'BCH',
  BCH_TESTNET = 'BCH_TESTNET',
  BSC = 'BSC',
  BTC_TESTNET = 'BTC_TESTNET',
  Bitcoin = 'Bitcoin',
  DogeCoin = 'DogeCoin',
  DogeCoin_TESTNET = 'DogeCoin_TESTNET',
  EOS = 'EOS',
  EOS_TESTNET = 'EOS_TESTNET',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  LiteCoin = 'LiteCoin',
  LiteCoin_TESTNET = 'LiteCoin_TESTNET',
  Rinkeby = 'Rinkeby',
  Sepolia = 'Sepolia',
  XRP = 'XRP',
  XRP_TESTNET = 'XRP_TESTNET'
}

/** A customer, an individual person across all brands. Represented by playerGlobalId. */
export type Customer = {
  __typename: 'Customer';
  customerClosure: Maybe<CustomerClosure>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isCancelWithdrawalBlocked: Scalars['Boolean']['output'];
  isCustomerWhitelisted: Scalars['Boolean']['output'];
  isDepositsBlocked: Scalars['Boolean']['output'];
  isIncreaseDepositLimitBlocked: Scalars['Boolean']['output'];
  isMarketingBlocked: Scalars['Boolean']['output'];
  isRewardsBlocked: Scalars['Boolean']['output'];
  isRgNetDepositLimitEnabled: Scalars['Boolean']['output'];
  playerGlobalId: Scalars['ID']['output'];
  playerGlobalUUID: Scalars['String']['output'];
  popUpsNeeded: Array<Scalars['String']['output']>;
};

/** Customer closure details */
export type CustomerClosure = {
  __typename: 'CustomerClosure';
  closeAccountCause: Scalars['String']['output'];
};

/** The CRA of a player */
export enum CustomerRisk {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export type CustomerRiskAssessment = {
  __typename: 'CustomerRiskAssessment';
  /** The last time the risk assessment was calculated */
  calculatedAt: Scalars['OffsetDateTime']['output'];
  /** The final risk score with weights for each region taken into account (0 to 100) */
  finalScore: Scalars['Int']['output'];
  /** Kyc Status for the player global id */
  kycCheckStatus: KycCheckStatus;
  /** The maximum total unweighted score that is possible */
  maxScore: Scalars['Int']['output'];
  /** If exists, this it the system risk level based on the score. This means that the risk level has been manually overwritten */
  overriddenRiskLevel: Maybe<RiskLevel>;
  /** Player Global Id */
  playerGlobalId: Scalars['String']['output'];
  /** Players that belong to this risk assessment */
  players: Array<Player>;
  /** A list of the different CRA regions that make up the final score */
  regions: Array<Region>;
  /** Current review status */
  reviewStatusInfo: ReviewStatusInfo;
  /** Needs to be passed back in submitReview mutation. Can be considered a token */
  reviewTrigger: ReviewTrigger;
  /** The final risk level of the player */
  riskLevel: RiskLevel;
  /** The total unweighted score */
  score: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type CustomerRiskAssessmentConnection = {
  __typename: 'CustomerRiskAssessmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomerRiskAssessmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerRiskAssessmentEdge = {
  __typename: 'CustomerRiskAssessmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomerRiskAssessment;
};

/** An entry in the risk history */
export type CustomerRiskAssessmentHistoryItem = {
  __typename: 'CustomerRiskAssessmentHistoryItem';
  comment: Maybe<Scalars['String']['output']>;
  eventId: Scalars['String']['output'];
  eventType: EventType;
  initiator: Maybe<Initiator>;
  outcomes: Array<Outcome>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
};

/** A connection to a list of items. */
export type CustomerRiskAssessmentHistoryItemConnection = {
  __typename: 'CustomerRiskAssessmentHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomerRiskAssessmentHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerRiskAssessmentHistoryItemEdge = {
  __typename: 'CustomerRiskAssessmentHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomerRiskAssessmentHistoryItem;
};

/** DataSource version to query */
export enum DataSourceVersionEnum {
  NEW = 'NEW',
  OLD = 'OLD'
}

export enum DecisionSearchType {
  Approve = 'Approve',
  Deny = 'Deny',
  NotSet = 'NotSet'
}

export enum DecisionType {
  Approve = 'Approve',
  Deny = 'Deny'
}

/** Deposit info with first and last deposit date. */
export type DepositInfo = {
  __typename: 'DepositInfo';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
};

/** Detailed cashback report */
export type DetailedCashbackReport = {
  __typename: 'DetailedCashbackReport';
  url: Scalars['String']['output'];
};

/** A document uploaded by the player. */
export type Document = Node & {
  __typename: 'Document';
  deletedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  deletedBy: Maybe<Initiator>;
  documentType: Maybe<DocumentType>;
  expirationDate: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  player: Maybe<Player>;
  status: Maybe<DocumentStatus>;
  uploadedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  uploadedBy: Maybe<Initiator>;
  uuid: Scalars['String']['output'];
  verificationTypes: Array<DocumentVerificationType>;
  verifiedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  verifiedBy: Maybe<Initiator>;
};

/** A connection to a list of items. */
export type DocumentConnection = {
  __typename: 'DocumentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DocumentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Document;
};

/** DocumentResponse */
export type DocumentResponse = Node & {
  __typename: 'DocumentResponse';
  document: Document;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  origin: origin;
};

/** A connection to a list of items. */
export type DocumentResponseConnection = {
  __typename: 'DocumentResponseConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DocumentResponseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DocumentResponseEdge = {
  __typename: 'DocumentResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DocumentResponse;
};

/** The order to list documents results. */
export enum DocumentSearchOrder {
  deletedAt = 'deletedAt',
  expirationDate = 'expirationDate',
  uploadedAt = 'uploadedAt',
  verifiedAt = 'verifiedAt'
}

/** The status of a document. */
export enum DocumentStatus {
  Approved = 'Approved',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

/** Type of document */
export enum DocumentType {
  BankStatement = 'BankStatement',
  CreditCard = 'CreditCard',
  DividendsSOW = 'DividendsSOW',
  DrivingLicense = 'DrivingLicense',
  ID = 'ID',
  InheritanceSOW = 'InheritanceSOW',
  Other = 'Other',
  Passport = 'Passport',
  SalarySOW = 'SalarySOW',
  SaleOfCompanySOW = 'SaleOfCompanySOW',
  SaleOfPropertySOW = 'SaleOfPropertySOW',
  SourceOfWealth = 'SourceOfWealth',
  UtilityBill = 'UtilityBill'
}

/** Document verification type */
export enum DocumentVerificationType {
  Address = 'Address',
  Age = 'Age',
  Email = 'Email',
  Identity = 'Identity',
  PhoneNumber = 'PhoneNumber',
  SourceOfWealth = 'SourceOfWealth'
}

/** Dynamic Bonus Money drop challenge reward type */
export type DynamicBonusMoneyDropChallengeReward = ChallengeRewardType & {
  __typename: 'DynamicBonusMoneyDropChallengeReward';
  currency: Scalars['String']['output'];
  maxAmount: Scalars['BigDecimal']['output'];
  maxPayout: Scalars['BigDecimal']['output'];
  multiplier: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
  wageringRequirementFactor: Scalars['Int']['output'];
};

/** Dynamic Free spins challenge reward type */
export type DynamicFreeSpinChallengeReward = ChallengeRewardType & {
  __typename: 'DynamicFreeSpinChallengeReward';
  betLevel: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  /** @deprecated use gameDescriptorV2 instead */
  gameDescriptor: Maybe<GameDescriptorOrError>;
  maxFreeSpinsAmount: Scalars['Int']['output'];
  multiplier: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

/** Dynamic Money drop challenge reward type */
export type DynamicMoneyDropChallengeReward = ChallengeRewardType & {
  __typename: 'DynamicMoneyDropChallengeReward';
  currency: Scalars['String']['output'];
  maxAmount: Scalars['BigDecimal']['output'];
  multiplier: Scalars['BigDecimal']['output'];
  name: Scalars['String']['output'];
};

/** Criteria which let a player see and take part on a challenge */
export enum EligibilityCriteria {
  PendingSoW = 'PendingSoW'
}

/** Information regarding the player's email */
export type EmailInfo = {
  __typename: 'EmailInfo';
  email: Maybe<Scalars['String']['output']>;
  isEmailMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified: Maybe<Scalars['Boolean']['output']>;
};

/** An entity with statistics. */
export type EntityWithStatistics = {
  /** Statistics. */
  stats: Maybe<StatsType>;
};


/** An entity with statistics. */
export type EntityWithStatisticsstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};

/** The value in euros */
export type EuroQuote = {
  __typename: 'EuroQuote';
  /** The amount in euros */
  amount: Scalars['BigDecimal']['output'];
  /** The exchange rate */
  exchangeRate: ExchangeRate;
};

/** The type of event in the risk history */
export enum EventType {
  ManualRiskCalculation = 'ManualRiskCalculation',
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewNotNeeded = 'ReviewNotNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected',
  RiskLevelOverridden = 'RiskLevelOverridden',
  RiskLevelOverrideRemoved = 'RiskLevelOverrideRemoved',
  RiskUpdated = 'RiskUpdated'
}

/** Exchange rate used */
export type ExchangeRate = {
  __typename: 'ExchangeRate';
  /** The id of the pricing service for this exchange rate */
  id: Scalars['String']['output'];
  /** The fee applied to the market price */
  internalFee: Scalars['BigDecimal']['output'];
  /** The internal price, with fee applied */
  internalPrice: Scalars['BigDecimal']['output'];
  /** The market price, without fee */
  marketPrice: Scalars['BigDecimal']['output'];
};

/** A fee */
export type Fee = {
  __typename: 'Fee';
  euroQuote: EuroQuote;
  money: Money;
};

/** The value in fiat currency */
export type FiatQuote = {
  __typename: 'FiatQuote';
  /** The amount in fiat currency */
  amount: Scalars['BigDecimal']['output'];
  /** Currency value */
  currency: Scalars['String']['output'];
  /** The exchange rate */
  exchangeRate: ExchangeRate;
};

/** The final/overall risk level has changed */
export type FinalRiskLevelUpdated = Outcome & {
  __typename: 'FinalRiskLevelUpdated';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  /** The final risk level after this event took place */
  riskLevel: RiskLevel;
  /** The final risk level before this event took place */
  riskLevelBefore: RiskLevel;
};

/** Free spins challenge reward type */
export type FreeSpinChallengeReward = ChallengeRewardType & {
  __typename: 'FreeSpinChallengeReward';
  amount: Scalars['Int']['output'];
  betLevel: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  /** @deprecated use gameDescriptorV2 instead */
  gameDescriptor: Maybe<GameDescriptorOrError>;
  name: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

/** Free spins reward type */
export type FreeSpinReward = RewardType & {
  __typename: 'FreeSpinReward';
  amount: Scalars['Int']['output'];
  betLevel: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  /** @deprecated use gameDescriptorV2 instead */
  gameDescriptor: Maybe<GameDescriptor>;
  /** @deprecated use gameDescriptorV2 instead */
  gameDescriptorV2: Maybe<GameDescriptorOrError>;
  name: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

export type FreeSpins = {
  amountFreeSpins: Scalars['Int']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
  desktopId?: InputMaybe<Scalars['String']['input']>;
  freeSpinValue: Scalars['Long']['input'];
  mobileId?: InputMaybe<Scalars['String']['input']>;
  payLines: Scalars['Int']['input'];
  provider: Scalars['String']['input'];
};

/** SoW history entry for funding document requested */
export type FundingDocumentsRequestedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'FundingDocumentsRequestedItem';
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  requestedFundingDocuments: Array<FundingOption>;
  uuid: Scalars['String']['output'];
};

export enum FundingOption {
  Dividends = 'Dividends',
  Inheritance = 'Inheritance',
  Other = 'Other',
  Salary = 'Salary',
  SaleOfCompany = 'SaleOfCompany',
  SaleOfProperty = 'SaleOfProperty'
}

/** Player Game Activity Report */
export type GameActivityReport = {
  __typename: 'GameActivityReport';
  games: Array<Scalars['String']['output']>;
  ggr: Array<PaymentSummaryInfo>;
  ngr: Array<PaymentSummaryInfo>;
  realCashBets: Array<PaymentSummaryInfo>;
  realCashWins: Array<PaymentSummaryInfo>;
  totalCasinoBets: Array<PaymentSummaryInfo>;
  totalCasinoWins: Array<PaymentSummaryInfo>;
};

/** A game descriptor fetched from the CMS. */
export type GameDescriptor = {
  __typename: 'GameDescriptor';
  json: Scalars['Json']['output'];
  url: Scalars['String']['output'];
};

/** An error detail from the game descriptor fetching process from the CMS. */
export type GameDescriptorFetchError = {
  __typename: 'GameDescriptorFetchError';
  error: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A game descriptor fetched from the CMS or an error from the fetching process */
export type GameDescriptorOrError = GameDescriptorFetchError | GameDescriptorV2;

/** A game descriptor fetched from the CMS. */
export type GameDescriptorV2 = {
  __typename: 'GameDescriptorV2';
  json: Scalars['Json']['output'];
  url: Scalars['String']['output'];
};

/** Name of game provider. */
export enum GameProvider {
  Aleaplay = 'Aleaplay',
  Authentic = 'Authentic',
  Betsoft = 'Betsoft',
  Booming = 'Booming',
  Delasport = 'Delasport',
  Elk = 'Elk',
  Endorphina = 'Endorphina',
  Everymatrix = 'Everymatrix',
  Evolution = 'Evolution',
  Ezugi = 'Ezugi',
  Gaming1x2 = 'Gaming1x2',
  Hub88 = 'Hub88',
  ISoftBet = 'ISoftBet',
  Microgaming = 'Microgaming',
  Netent = 'Netent',
  Nolimit = 'Nolimit',
  Pariplay = 'Pariplay',
  PlayNGo = 'PlayNGo',
  Pragmatic = 'Pragmatic',
  Quickspin = 'Quickspin',
  Reevo = 'Reevo',
  Relax = 'Relax',
  Rival = 'Rival',
  Scientific = 'Scientific',
  Swintt = 'Swintt',
  Thrilltech = 'Thrilltech',
  Thunderkick = 'Thunderkick',
  Wazdan = 'Wazdan',
  Yggdrasil = 'Yggdrasil'
}

/** A summarized game round. */
export type GameRoundItem = {
  __typename: 'GameRoundItem';
  /** The amount of the bonus bet */
  bonusBetAmount: Maybe<Scalars['BigDecimal']['output']>;
  /** The amount of the bonus wins */
  bonusWinAmount: Maybe<Scalars['BigDecimal']['output']>;
  brand: Brand;
  closingBalance: Maybe<Scalars['PositiveBigDecimal']['output']>;
  /** The closing bonus balance for the transaction */
  closingBonusBalance: Maybe<Scalars['BigDecimal']['output']>;
  completedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  currency: Scalars['String']['output'];
  /** @deprecated use gameDescriptorV2 instead */
  gameDescriptor: Maybe<GameDescriptor>;
  gameDescriptorV2: Maybe<GameDescriptorOrError>;
  gameId: Scalars['String']['output'];
  gameProvider: Scalars['String']['output'];
  gameRoundId: Scalars['String']['output'];
  openingBalance: Scalars['BigDecimal']['output'];
  /** The opening balance for the bonus transaction */
  openingBonusBalance: Maybe<Scalars['BigDecimal']['output']>;
  playerId: Scalars['ID']['output'];
  rawPlayerId: Scalars['String']['output'];
  startedAt: Scalars['OffsetDateTime']['output'];
  status: GameRoundStatus;
  totalBets: Scalars['BigDecimal']['output'];
  totalJackpotContribution: Scalars['BigDecimal']['output'];
  totalJackpotPayout: Scalars['BigDecimal']['output'];
  totalWins: Scalars['BigDecimal']['output'];
};

/** A connection to a list of items. */
export type GameRoundItemConnection = {
  __typename: 'GameRoundItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<GameRoundItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GameRoundItemEdge = {
  __typename: 'GameRoundItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GameRoundItem;
};

/** The order to list game rounds. */
export enum GameRoundSearchOrder {
  brand = 'brand',
  closingBalance = 'closingBalance',
  completedAt = 'completedAt',
  currency = 'currency',
  gameId = 'gameId',
  gameProvider = 'gameProvider',
  gameRoundId = 'gameRoundId',
  lastProcessedAt = 'lastProcessedAt',
  playerId = 'playerId',
  playerSessionId = 'playerSessionId'
}

/** The status of a game round. */
export enum GameRoundStatus {
  Loss = 'Loss',
  Pending = 'Pending',
  Win = 'Win'
}

/** Url for uploading a document document */
export type GenerateBulkDocument = {
  __typename: 'GenerateBulkDocument';
  /** The document id to upload the document into */
  documentId: Scalars['String']['output'];
  /** Url to upload a cashback document */
  url: Scalars['String']['output'];
};

/** Url for uploading a document document */
export type GetDocumentUploadUrl = {
  __typename: 'GetDocumentUploadUrl';
  /** The document id to upload the document into */
  documentId: Scalars['ID']['output'];
  /** Url of the document */
  url: Scalars['String']['output'];
};

export type GetKrofortWhitelistResponse = {
  __typename: 'GetKrofortWhitelistResponse';
  playerIds: Array<Scalars['String']['output']>;
};

/** Get kyc request count */
export type GetKycRequestCountRequest = {
  __typename: 'GetKycRequestCountRequest';
  count: Scalars['PositiveInt']['output'];
};

export type GetWhitelistResponse = {
  __typename: 'GetWhitelistResponse';
  playerIds: Array<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type GlobalLimitSearchItemConnection = {
  __typename: 'GlobalLimitSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<GlobalLimitSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GlobalLimitSearchItemEdge = {
  __typename: 'GlobalLimitSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LimitSearchItem;
};

/** Global login attempts info */
export type GlobalLoginAttemptsInfo = {
  __typename: 'GlobalLoginAttemptsInfo';
  /** Number of allowed invalid login attempts before player is locked from logging in */
  invalidGlobalLoginAttemptsAllowed: Scalars['Int']['output'];
  /** Timestamp when player was locked due to too many invalid login attempts */
  maxGlobalLoginAttemptsReachedAt: Maybe<Scalars['OffsetDateTime']['output']>;
};

/** GlobalOrigin */
export type GlobalOrigin = Node & {
  __typename: 'GlobalOrigin';
  code: Scalars['String']['output'];
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

/** A global player. */
export type GlobalPlayer = EntityWithStatistics & Node & {
  __typename: 'GlobalPlayer';
  /** Compliance related limits for the player across all brands */
  complianceGlobalPlayerLimits: Array<ComplianceGlobalPlayerLimit>;
  /** The customer which this player belongs to */
  customer: Customer;
  globalDocuments: DocumentResponseConnection;
  globalNotes: NoteResponseConnection;
  globalPayments: PaymentConnection;
  globalWallet: Maybe<GlobalWallet>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  kycMasterPlayer: Maybe<Player>;
  playerComplianceDetails: Maybe<PlayerCompliance>;
  playerComplianceDetailsV2: Maybe<PlayerComplianceV2>;
  playerGlobalUUID: Scalars['String']['output'];
  players: Array<Player>;
  providedSourceOfFunds: BoSourceOfFundsType;
  /** The RG customer risk assessment for the player */
  rgCraCustomerRiskAssessment: Maybe<RgCraCustomerRiskAssessment>;
  sourceOfWealth: Maybe<SourceOfWealth>;
  sowBlockedStatus: Maybe<SowBlockedStatus>;
  /** Statistics. */
  stats: Maybe<StatsType>;
};


/** A global player. */
export type GlobalPlayerglobalDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DocumentSearchOrder>;
};


/** A global player. */
export type GlobalPlayerglobalNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerNoteSearchOrder>>;
  priority?: InputMaybe<PriorityEnum>;
};


/** A global player. */
export type GlobalPlayerglobalPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PaymentSearchOrder>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A global player. */
export type GlobalPlayerstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};

/** A global wallet. */
export type GlobalWallet = {
  __typename: 'GlobalWallet';
  /** Amount of global balance. */
  balance: Scalars['BigDecimal']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  playerGlobalUUID: Scalars['String']['output'];
  /** Amount of global reserved balance. */
  reservedBalance: Scalars['BigDecimal']['output'];
  /** player wallets summary info */
  wallets: Array<PlayerWalletType>;
};

/** Granularity level for stats reports */
export enum GranularityEnum {
  All = 'All',
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Second = 'Second',
  Week = 'Week',
  Year = 'Year'
}

/** Hampi check result type */
export type HampiCheckResult = {
  __typename: 'HampiCheckResult';
  restrictions: Array<Restriction>;
};

/** A bo agent identity */
export type Identity = {
  __typename: 'Identity';
  brands: Array<BrandEnum>;
  orgs: Array<OrgEnum>;
  roles: Array<AgentRoleEnum>;
};

/** A bo agent identity */
export type IdentityV2 = {
  __typename: 'IdentityV2';
  agent: Maybe<Agent>;
  agentId: Scalars['String']['output'];
  brands: Array<BrandEnum>;
  orgs: Array<OrgEnum>;
  roles: Array<AgentRoleEnum>;
};

/** The player or agent responsible for performing a specific action. */
export type Initiator = AgentInitiator | PlayerInitiator;

/** The initiator type. */
export enum InitiatorTypeEnum {
  Agent = 'Agent',
  Player = 'Player'
}

/**
 * If exists, the block is set. If activeFrom is not set, the block is active. If
 * activeFrom is set, but we have not passed the timestamp, the block is pending.
 */
export type InstantBankingBlock = {
  __typename: 'InstantBankingBlock';
  activeFrom: Maybe<Scalars['OffsetDateTime']['output']>;
};

/** Krofort Kyc Request Status. */
export enum KrofortKycRequestStatus {
  KYC_REQUEST_STATUS_CREATE_KYC_FAILED = 'KYC_REQUEST_STATUS_CREATE_KYC_FAILED',
  KYC_REQUEST_STATUS_GET_KYC_FAILED = 'KYC_REQUEST_STATUS_GET_KYC_FAILED',
  KYC_REQUEST_STATUS_GOT_KYC = 'KYC_REQUEST_STATUS_GOT_KYC',
  KYC_REQUEST_STATUS_KYC_CREATED = 'KYC_REQUEST_STATUS_KYC_CREATED',
  KYC_REQUEST_STATUS_KYC_CREATED_NOT_FOUND = 'KYC_REQUEST_STATUS_KYC_CREATED_NOT_FOUND'
}

export enum KrofortPayoutStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Created = 'Created',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Rejected = 'Rejected'
}

/** Declares kyc check deadline, and the missing verifications if kyc check is not done */
export type KycCheckStatus = {
  __typename: 'KycCheckStatus';
  /** Time at which the player wont be able to play or interact */
  deadline: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Pending KYC flow for the missing verifications */
  kycFlow: Maybe<KycFlow>;
  /** KYC flows started by the player. */
  kycFlows: Array<KycFlow>;
  /**
   * Status of the KYC check
   * @deprecated Use statusV2 instead
   */
  status: Scalars['String']['output'];
  /** Status of the KYC check */
  statusV2: KycCheckStatusStatusType;
  /** The id of the player who did the last KYC flow. */
  sumSubEndUserId: Maybe<Scalars['String']['output']>;
  /** KYC verifications done for the player */
  verificationsDone: Array<VerificationTypeEnum>;
  /** KYC verifications missing for the player */
  verificationsMissing: Array<VerificationTypeEnum>;
};

/** KycCheckStatus.status */
export enum KycCheckStatusStatusType {
  Done = 'Done',
  NotNeed = 'NotNeed',
  Pending = 'Pending',
  Required = 'Required',
  WillBeRequired = 'WillBeRequired'
}

/** Give information about a specific KYC Flow */
export type KycFlow = {
  __typename: 'KycFlow';
  failureReason: Maybe<Scalars['String']['output']>;
  /** The id of the KYC Flow */
  flowId: Scalars['String']['output'];
  /** Time at which the player wont be able to play or interact */
  initializedAt: Scalars['OffsetDateTime']['output'];
  kycProvider: kycProvider;
  /** The id of the player who initiate the KYC Flow */
  playerId: Maybe<Scalars['String']['output']>;
  status: KycVerificationType;
  verifications: Array<VerificationTypeEnum>;
  verificationsApproved: Array<VerificationTypeEnum>;
  verificationsRejected: Array<VerificationTypeEnum>;
};

/** Kyc Provider */
export enum KycProviderType {
  Brite = 'Brite',
  Dino = 'Dino',
  Eniro = 'Eniro',
  Finshark = 'Finshark',
  Infinipi = 'Infinipi',
  Krofort = 'Krofort',
  Sofort = 'Sofort',
  SumSub = 'SumSub',
  Trustly = 'Trustly',
  Zimpler = 'Zimpler'
}

/** KYC Flow verification type */
export enum KycVerificationType {
  Failed = 'Failed',
  Initialized = 'Initialized',
  Pending = 'Pending',
  Succeed = 'Succeed'
}

/** Limit. */
export type Limit = {
  __typename: 'Limit';
  /** Limits are blocked to be modified. */
  blockingStatus: Maybe<BlockingLimit>;
  /** Day limit if one is set. */
  dayLimit: Maybe<PeriodLimit>;
  /** Month limit if one is set. */
  monthLimit: Maybe<PeriodLimit>;
  /** Week limit if one is set. */
  weekLimit: Maybe<PeriodLimit>;
};

/** Limit changes count report */
export type LimitChangesCountReport = {
  __typename: 'LimitChangesCountReport';
  url: Scalars['String']['output'];
};

/** A limit search item. */
export type LimitSearchItem = {
  __typename: 'LimitSearchItem';
  availableAt: Maybe<Scalars['OffsetDateTime']['output']>;
  confirmedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  limitStatus: Maybe<Scalars['String']['output']>;
  limitType: Maybe<Scalars['String']['output']>;
  /** New value. */
  newValue: Maybe<Scalars['BigDecimal']['output']>;
  period: Maybe<Scalars['String']['output']>;
  playerId: Scalars['ID']['output'];
  /** Previous value. */
  prevValue: Maybe<Scalars['BigDecimal']['output']>;
  requestedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  requestedBy: Maybe<Initiator>;
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type LimitSearchItemConnection = {
  __typename: 'LimitSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<LimitSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LimitSearchItemEdge = {
  __typename: 'LimitSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LimitSearchItem;
};

/** The order to list limit search results. */
export enum LimitSearchOrder {
  requestedAt = 'requestedAt'
}

/** Status of the limit. */
export enum LimitStatusEnum {
  LimitRemoved = 'LimitRemoved',
  LimitSet = 'LimitSet',
  PendingLimitCancelled = 'PendingLimitCancelled',
  PendingLimitSet = 'PendingLimitSet',
  PendingRemovalCancelled = 'PendingRemovalCancelled'
}

/** Type of limit. */
export enum LimitType {
  Deposit = 'Deposit',
  Loss = 'Loss',
  Session = 'Session',
  Wager = 'Wager'
}

/** The limit period type. */
export enum LimitTypeEnum {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week'
}

/** Player's linked accounts */
export type LinkedAccounts = {
  __typename: 'LinkedAccounts';
  addressLine: Maybe<Scalars['String']['output']>;
  birthDate: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  city: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  numberOfLinks: Scalars['Int']['output'];
  playerId: Scalars['ID']['output'];
  rawPlayerId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  triggerTypes: Array<Scalars['String']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Information about a player's last login */
export type LoginInfo = {
  __typename: 'LoginInfo';
  lastLoginAt: Maybe<Scalars['OffsetDateTime']['output']>;
  lastLoginIp: Maybe<Scalars['String']['output']>;
  lastLoginIpCountryCode: Maybe<Scalars['String']['output']>;
};

/** Long running players export details. */
export type LongRunningPlayerExportDetails = {
  __typename: 'LongRunningPlayerExportDetails';
  /** The name of the file where the report can be found after it's finished. */
  fileName: Scalars['String']['output'];
};

/** Manual decision for a failed reward type */
export enum ManualDecisionType {
  manualCredited = 'manualCredited',
  removeReward = 'removeReward'
}

/**
 * The players marketing preferences, containing the acceptance status of each
 * individual marketing type and if these can be changed by the user
 */
export type MarketingPreferences = {
  __typename: 'MarketingPreferences';
  emailMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  marketingBlocked: Scalars['Boolean']['output'];
  phoneMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  smsMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
};

/** Amount and currency */
export type Money = {
  __typename: 'Money';
  /** The amount */
  amount: Scalars['BigDecimal']['output'];
  /** The currency code */
  currency: Scalars['ISOCurrencyCode']['output'];
};

/** Money Adjustment */
export type MoneyAdjustedType = {
  __typename: 'MoneyAdjustedType';
  amount: Scalars['BigDecimal']['output'];
  brand: Maybe<Brand>;
  closingBalance: Scalars['BigDecimal']['output'];
  comment: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  initiator: Initiator;
  openingBalance: Scalars['BigDecimal']['output'];
  playerId: Scalars['ID']['output'];
  rawTransactionId: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
  transactionType: Scalars['String']['output'];
};

/** Money drop challenge reward type */
export type MoneyDropChallengeReward = ChallengeRewardType & {
  __typename: 'MoneyDropChallengeReward';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MoneyDropInput = {
  amount: Scalars['BigDecimal']['input'];
  currency: Scalars['ISOCurrencyCode']['input'];
};

/** Money drop reward type */
export type MoneyDropReward = RewardType & {
  __typename: 'MoneyDropReward';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  activateRewards: Scalars['Boolean']['output'];
  addFraudStatusOverwrite: screeningStatuses;
  addGlobalNote: NoteResponse;
  addGlobalNoteV2: NoteResponse;
  addNote: Note;
  addPepStatusOverwrite: screeningStatuses;
  /** Add player to whitelist, withdrawal blocks because of suspected abusive deposit history will not apply */
  addPlayerToFinsharkWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  /** Add player to whitelist, withdrawal blocks because of suspected abusive deposit history will not apply */
  addPlayerToKrofortWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  addSanctionedStatusOverwrite: screeningStatuses;
  adjustReservedBalance: Scalars['String']['output'];
  /** @deprecated use adjustmentDepositV2 instead */
  adjustmentDeposit: MoneyAdjustedType;
  /** @deprecated use adjustmentDepositV3 instead */
  adjustmentDepositV2: MoneyAdjustedType;
  adjustmentDepositV3: MoneyAdjustedType;
  adjustmentPaymentDeposit: MoneyAdjustedType;
  adjustmentPaymentWithdrawal: MoneyAdjustedType;
  /** @deprecated use adjustmentWithdrawalV2 instead */
  adjustmentWithdrawal: MoneyAdjustedType;
  /** @deprecated use adjustmentWithdrawalV3 instead */
  adjustmentWithdrawalV2: MoneyAdjustedType;
  adjustmentWithdrawalV3: MoneyAdjustedType;
  /**
   * Approve a document
   * @deprecated Use approveDocumentV2 instead
   */
  approveDocument: Document;
  /** Approve a document */
  approveDocumentV2: Document;
  approveFundingDocument: Document;
  approvePendingZimplerWithdrawal: Scalars['String']['output'];
  assignPlayers: Scalars['Boolean']['output'];
  blockDepositLimit: Wallet;
  /** @deprecated Use blockInstantBankingV2 which allows to set a grace period */
  blockInstantBanking: Player;
  blockInstantBankingV2: Player;
  blockSessionLimit: Wallet;
  blockWithdrawals: Player;
  /** Add note to a list of players. Always returning true, search for the report in the AWS S3 bucket */
  bulkAddNote: Scalars['Boolean']['output'];
  /** Add note to a list of player provided in a file. Always returning true, search for the report in the AWS S3 bucket */
  bulkAddNoteFromFile: Scalars['Boolean']['output'];
  bulkAdjustmentDeposit: BulkAdjustmentType;
  bulkBlockInstantBanking: Scalars['String']['output'];
  cancelDepositLimitRemoval: Wallet;
  cancelKrofortWithdrawal: Scalars['String']['output'];
  cancelPendingDepositLimit: Wallet;
  cancelPendingGlobalLossLimitByPlayerId: Wallet;
  cancelPendingLossLimit: Wallet;
  cancelPendingSelfExclusionV2: Player;
  cancelPendingSessionLimit: Wallet;
  cancelPendingWagerLimit: Wallet;
  cancelPendingZimplerWithdrawal: Scalars['String']['output'];
  cancelSelfExclusionV3: Player;
  changeAddress: Player;
  /** Change a player AML Risk Level */
  changeAmlRiskLevel: PlayerCompliance;
  /** Change a player AML Risk Level */
  changeAmlRiskLevelV2: PlayerComplianceV2;
  changeAssignedSsn: Player;
  changeCampaignId: Maybe<RewardItem>;
  changeContentStatus: Scalars['Boolean']['output'];
  changeEmail: Player;
  changeEmailMarketingSettings: Player;
  changeGlobalPlayerEmailMarketing: GlobalPlayer;
  changeGlobalPlayerMarketingBlock: GlobalPlayer;
  changeGlobalPlayerPhoneMarketing: GlobalPlayer;
  changeGlobalPlayerSmsMarketing: GlobalPlayer;
  changeKycMasterPlayer: GlobalPlayer;
  changeMarketingBlockSettings: Player;
  changePersonalDetails: Player;
  changePhoneMarketingSettings: Player;
  /** @deprecated Use changePhoneNumberV2 which has improved phone number parsing */
  changePhoneNumber: Player;
  changePhoneNumberV2: Player;
  changeProvidedSourceOfFunds: BoSourceOfFundsType;
  changeSmsMarketingSettings: Player;
  changeTranslatedPersonalInfo: TranslatedPersonalInfo;
  claimFailedRewards: Scalars['Boolean']['output'];
  closeGlobalPlayerAccounts: GlobalPlayer;
  /** @deprecated use closePlayerAccountV4 */
  closePlayerAccountV3: Player;
  closePlayerAccountV4: Player;
  createBonusMoneyDrop: Scalars['Boolean']['output'];
  /** @deprecated Moving to createChallengeV2 */
  createChallenge: Scalars['Boolean']['output'];
  createChallengeV2: Scalars['Boolean']['output'];
  createFreeSpins: Scalars['Boolean']['output'];
  createMoneyDrop: Scalars['Boolean']['output'];
  createPlayerSessionFromBO: Scalars['String']['output'];
  createPlayerV4: Player;
  /** Delete a document */
  deleteDocument: Document;
  deleteFundingDocument: Document;
  /** This method will emit a PlayerRegisteredEvent to link a player to a globalPlayerId */
  forceRegistration: Scalars['Boolean']['output'];
  /** Forfeit bonus for players */
  forfeitBonus: Scalars['Boolean']['output'];
  fundingDocumentUploaded: Document;
  generateBulkDocumentUrl: GenerateBulkDocument;
  generateBulkGetDocumentUrl: GenerateBulkDocument;
  /** Based on documentId, it will provide a signed url to upload the document */
  generateDocumentUrl: GetDocumentUploadUrl;
  /** Generates a report of players with non-settled deposits, return download url */
  generateFinsharkNonSettledDepositsReport: Scalars['String']['output'];
  /** Generates a report with payouts that failed with UnknownPayout in Finshark, returns download url */
  generateFinsharkUnknownPayoutsReport: Scalars['String']['output'];
  generateFundingDocumentDownloadUrl: GetDocumentUploadUrl;
  generateFundingDocumentUploadUrl: GetDocumentUploadUrl;
  /** Based on documentId, it will provide a signed url to retrieve the document */
  generateGetDocumentUrl: GetDocumentUploadUrl;
  /** Generates a report of players with non-settled deposits, return download url */
  generateKrofortNonSettledDepositsReport: Scalars['String']['output'];
  /** Generates a report with payouts that failed with UnknownPayout in Krofort, returns download url */
  generateKrofortUnknownPayoutsReport: Scalars['String']['output'];
  /** Returns all playerIds on white list */
  getFinsharkWithdrawalBlockWhitelist: GetWhitelistResponse;
  /** Returns all playerIds on white list */
  getKrofortWithdrawalBlockWhitelist: GetKrofortWhitelistResponse;
  /** Returns true if player is on whitelist, false if not */
  isPlayerOnFinsharkWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  /** Returns true if player is on whitelist, false if not */
  isPlayerOnKrofortWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  linkPlayer: Scalars['Boolean']['output'];
  manualKycCheck: Player;
  manualWithdrawalApproval: PaymentApproval;
  /** Removes phone number from 'oldPlayerId' and assigns it to another player */
  movePhoneNumberBetweenPlayers: Scalars['String']['output'];
  overrideRiskLevel: CustomerRiskAssessment;
  pendingReview: CustomerRiskAssessment;
  processStuckWithdrawals: StuckWithdrawalsReport;
  /**
   * Cancelling stuck withdrawals with a list of transaction ids to be cancelled.
   * The noteContent argument is optional. If not provided some generic note will
   * be used. Please have in mind, that when the transactionId is invalid - it will
   * be fully skipped.
   */
  processStuckWithdrawalsFromList: Scalars['Boolean']['output'];
  /**
   * Decline a document
   * @deprecated Use rejectDocumentV2 instead
   */
  rejectDocument: Document;
  /** Decline a document */
  rejectDocumentV2: Document;
  rejectFundingDocument: Document;
  /** Remove the affiliate parameters from a player. Note it is not removed from MyAffiliates */
  removeAffiliateParameters: Player;
  /**
   * Removes a challenge by id, always return true, this process is a 'fire and
   * forget' one and does not return the result of the operation.
   */
  removeChallenge: Scalars['Boolean']['output'];
  removeDepositLimit: Wallet;
  removeFraudStatusOverwrite: screeningStatuses;
  removeGlobalLossLimitByPlayerId: Wallet;
  removeLossLimit: Wallet;
  removePepStatusOverwrite: screeningStatuses;
  /** Remove player from whitelist, withdrawal blocks because of suspected abusive deposit history apply again */
  removePlayerFromFinsharkWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  /** Remove player from whitelist, withdrawal blocks because of suspected abusive deposit history apply again */
  removePlayerFromKrofortWithdrawalBlockWhitelist: Scalars['Boolean']['output'];
  removePlayers: Scalars['Boolean']['output'];
  removeReward: Scalars['Boolean']['output'];
  removeRiskLevelOverride: CustomerRiskAssessment;
  removeSanctionedStatusOverwrite: screeningStatuses;
  removeSessionLimit: Wallet;
  removeWagerLimit: Wallet;
  /**
   * Reopen the customer. This does not open any player accounts. It stops new
   * players for this customer from being automatically blocked on registration.
   */
  reopenCustomer: Customer;
  reopenPlayerAccount: Player;
  requestDocuments: PlayerCompliance;
  requestDocumentsV2: PlayerComplianceV2;
  /** Manually request SOW from a player */
  requestSow: Scalars['Boolean']['output'];
  resendVerificationEmail: VerificationEmailSentResponse;
  /**
   * Will reset the amount of invalid login attempts allowed for a player before login is locked
   * @deprecated Use resetGlobalInvalidLoginAttemptsLeftV2 instead
   */
  resetGlobalInvalidLoginAttemptsLeft: Player;
  /** Will reset the amount of invalid login attempts allowed for a player before login is locked in both Player and Keycloak */
  resetGlobalInvalidLoginAttemptsLeftV2: Player;
  resetKycEntityIdAuthMethodEntity: Scalars['String']['output'];
  resolveReward: Scalars['Boolean']['output'];
  retryKrofortKyc: Scalars['String']['output'];
  rgCraExportRiskAssessment: Scalars['String']['output'];
  rgCraIndicatorCommunications: Scalars['Boolean']['output'];
  rgCraOverrideRiskLevel: RgCraCustomerRiskAssessment;
  rgCraPayoutRequest: Scalars['Boolean']['output'];
  rgCraPendingReview: RgCraCustomerRiskAssessment;
  rgCraRemoveRiskLevelOverride: RgCraCustomerRiskAssessment;
  rgCraRewardRequest: Scalars['Boolean']['output'];
  rgCraSubmitReview: RgCraCustomerRiskAssessment;
  rgCraTriggerManualRiskUpdate: Scalars['Boolean']['output'];
  selfExcludeV3: Player;
  /** Set pop-ups needed to display to a customer. */
  setCustomerPopUpsNeeded: Customer;
  setDepositLimit: Wallet;
  setGlobalLossLimitByPlayerId: Wallet;
  setLossLimit: Wallet;
  setSessionLimit: Wallet;
  setWagerLimit: Wallet;
  submitReview: CustomerRiskAssessment;
  transformWagerLimitsToLossLimits: BulkTransformPlayersResult;
  triggerManualRiskUpdate: Scalars['Boolean']['output'];
  unblockDepositLimit: Wallet;
  unblockInstantBanking: Player;
  unblockSessionLimit: Wallet;
  unblockWithdrawals: Player;
  unlinkPlayer: Scalars['String']['output'];
  /** Updates the value of affiliate id and token */
  updateAffiliateParameters: Player;
  updateContent: Scalars['Boolean']['output'];
  /** Set and remove block that does not allow withdrawals to be cancelled */
  updateCustomerCancelWithdrawalBlock: Customer;
  /** Set and remove deposit block */
  updateCustomerDepositBlock: Customer;
  /** Set and remove increase deposit limit block */
  updateCustomerIncreaseDepositLimitBlock: Customer;
  /** Set and remove marketing block */
  updateCustomerMarketingBlock: Customer;
  /** Set and remove rewards block */
  updateCustomerRewardsBlock: Customer;
  /** Set and remove RG net deposit limit block */
  updateCustomerRgNetDepositLimitBlock: Customer;
  /**
   * Put the customer in the RG whitelist. This will save the player for RG blocks,
   * but not for periodic emails and communications.
   */
  updateCustomerWhitelistStatus: Customer;
  updatePep: Player;
  /**
   * Notify document has been uploaded successfully
   * @deprecated Use uploadDocumentV2 instead
   */
  uploadDocument: Document;
  /** Notify document has been uploaded successfully */
  uploadDocumentV2: Document;
  /** Approve of Reject players provided source of wealth documents */
  verifyPlayerSourceOfWealth: PlayerCompliance;
  /** Approve of Reject players provided source of wealth documents */
  verifyPlayerSourceOfWealthV2: PlayerComplianceV2;
};


export type MutationactivateRewardsArgs = {
  id: Scalars['String']['input'];
};


export type MutationaddFraudStatusOverwriteArgs = {
  decision: Scalars['Boolean']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationaddGlobalNoteArgs = {
  content: Scalars['String']['input'];
  playerGlobalUUID: Scalars['String']['input'];
  priority: PriorityEnum;
};


export type MutationaddGlobalNoteV2Args = {
  content: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
  priority: PriorityEnum;
};


export type MutationaddNoteArgs = {
  content: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
  priority: PriorityEnum;
};


export type MutationaddPepStatusOverwriteArgs = {
  decision: Scalars['Boolean']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationaddPlayerToFinsharkWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationaddPlayerToKrofortWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationaddSanctionedStatusOverwriteArgs = {
  decision: Scalars['Boolean']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationadjustReservedBalanceArgs = {
  adjustmentType: ReservedAdjustmentType;
  amount: Scalars['PositiveBigDecimal']['input'];
  comment: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationadjustmentDepositArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationadjustmentDepositV2Args = {
  adjustmentType: AdjustmentType;
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationadjustmentDepositV3Args = {
  adjustmentType: AdjustmentType;
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  transactionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationadjustmentPaymentDepositArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  transactionId: Scalars['String']['input'];
};


export type MutationadjustmentPaymentWithdrawalArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  transactionId: Scalars['String']['input'];
};


export type MutationadjustmentWithdrawalArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationadjustmentWithdrawalV2Args = {
  adjustmentType: AdjustmentType;
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationadjustmentWithdrawalV3Args = {
  adjustmentType: AdjustmentType;
  amount: Scalars['PositiveBigDecimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  transactionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationapproveDocumentArgs = {
  documentId: Scalars['ID']['input'];
  documentType: DocumentType;
  documentVerificationTypes: Array<DocumentVerificationType>;
  expirationDate?: InputMaybe<Scalars['LocalDate']['input']>;
};


export type MutationapproveDocumentV2Args = {
  comment: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  documentType: DocumentType;
  documentVerificationTypes: Array<DocumentVerificationType>;
  expirationDate?: InputMaybe<Scalars['LocalDate']['input']>;
};


export type MutationapproveFundingDocumentArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  fundingOption: FundingOption;
  playerId: Scalars['ID']['input'];
};


export type MutationapprovePendingZimplerWithdrawalArgs = {
  brand: BrandEnum;
  externalTransactionId: Scalars['String']['input'];
  playerSessionId: Scalars['String']['input'];
};


export type MutationassignPlayersArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['String']['input'];
  playerIds: Array<Scalars['String']['input']>;
};


export type MutationblockDepositLimitArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationblockInstantBankingArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationblockInstantBankingV2Args = {
  gracePeriodMinutes?: InputMaybe<Scalars['Long']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationblockSessionLimitArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationblockWithdrawalsArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationbulkAddNoteArgs = {
  content: Scalars['String']['input'];
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationbulkAddNoteFromFileArgs = {
  content: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};


export type MutationbulkAdjustmentDepositArgs = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  id: Scalars['String']['input'];
};


export type MutationbulkBlockInstantBankingArgs = {
  gracePeriodMinutes?: InputMaybe<Scalars['Long']['input']>;
  playerIds: Array<Scalars['ID']['input']>;
};


export type MutationcancelDepositLimitRemovalArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelKrofortWithdrawalArgs = {
  transactionId: Scalars['String']['input'];
};


export type MutationcancelPendingDepositLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingGlobalLossLimitByPlayerIdArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingLossLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingSelfExclusionV2Args = {
  comment: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingSessionLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingWagerLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcancelPendingZimplerWithdrawalArgs = {
  brand: BrandEnum;
  externalTransactionId: Scalars['String']['input'];
  playerSessionId: Scalars['String']['input'];
};


export type MutationcancelSelfExclusionV3Args = {
  comment: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangeAddressArgs = {
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  district?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};


export type MutationchangeAmlRiskLevelArgs = {
  amlLevel: AmlRiskLevel;
  playerId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationchangeAmlRiskLevelV2Args = {
  amlLevel: AmlRiskLevel;
  playerId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationchangeAssignedSsnArgs = {
  playerId: Scalars['ID']['input'];
  ssn: Scalars['String']['input'];
};


export type MutationchangeCampaignIdArgs = {
  campaignId: Scalars['CampaignId']['input'];
  id: Scalars['String']['input'];
};


export type MutationchangeContentStatusArgs = {
  contentId: Scalars['String']['input'];
  status: ContentStatusType;
};


export type MutationchangeEmailArgs = {
  brand: BrandEnum;
  email: Scalars['EmailAddress']['input'];
  playerId: Scalars['ID']['input'];
  sendVerificationEmail?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationchangeEmailMarketingSettingsArgs = {
  isEmailMarketingAccepted: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangeGlobalPlayerEmailMarketingArgs = {
  isEmailMarketingAccepted: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationchangeGlobalPlayerMarketingBlockArgs = {
  isMarketingBlocked: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationchangeGlobalPlayerPhoneMarketingArgs = {
  isPhoneMarketingAccepted: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationchangeGlobalPlayerSmsMarketingArgs = {
  isSmsMarketingAccepted: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationchangeKycMasterPlayerArgs = {
  playerGlobalId: Scalars['ID']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangeMarketingBlockSettingsArgs = {
  isMarketingBlocked: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangePersonalDetailsArgs = {
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['ID']['input'];
  secondLastName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationchangePhoneMarketingSettingsArgs = {
  isPhoneMarketingAccepted: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangePhoneNumberArgs = {
  brand: BrandEnum;
  phoneNumber: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangePhoneNumberV2Args = {
  brand: BrandEnum;
  phoneNumber: Scalars['PhoneNumber']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangeProvidedSourceOfFundsArgs = {
  playerId: Scalars['ID']['input'];
  providedSourceOfFunds: BoSourceOfFunds;
};


export type MutationchangeSmsMarketingSettingsArgs = {
  isSmsMarketingAccepted: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationchangeTranslatedPersonalInfoArgs = {
  city: Scalars['String']['input'];
  district?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
};


export type MutationclaimFailedRewardsArgs = {
  id: Scalars['String']['input'];
};


export type MutationcloseGlobalPlayerAccountsArgs = {
  closeCause: CloseAccountCauseV4;
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationclosePlayerAccountV3Args = {
  closeCause: CloseAccountCauseV3;
  playerId: Scalars['ID']['input'];
};


export type MutationclosePlayerAccountV4Args = {
  closeCause: CloseAccountCauseV4;
  playerId: Scalars['ID']['input'];
};


export type MutationcreateBonusMoneyDropArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  campaignId?: InputMaybe<Scalars['CampaignId']['input']>;
  claimType: ClaimType;
  comment: Scalars['String']['input'];
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  endsAt?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  expiresIn?: InputMaybe<Scalars['Duration']['input']>;
  maxPayout: Scalars['PositiveBigDecimal']['input'];
  playerIds: Array<Scalars['String']['input']>;
  rewardName: Scalars['String']['input'];
  startsAt: Scalars['OffsetDateTime']['input'];
  wageringRequirementFactor: Scalars['Int']['input'];
};


export type MutationcreateChallengeArgs = {
  assignedPlayers?: InputMaybe<Array<Scalars['String']['input']>>;
  brands: Array<BrandEnum>;
  challengeCriteria: Array<ChallengeCriteria>;
  challengeName: Scalars['String']['input'];
  countries: Array<Scalars['String']['input']>;
  eligibility: Array<EligibilityCriteria>;
  endDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  rewards: Array<ChallengeRewardInput>;
  startDate: Scalars['OffsetDateTime']['input'];
};


export type MutationcreateChallengeV2Args = {
  assignedPlayers?: InputMaybe<Array<Scalars['String']['input']>>;
  brands: Array<BrandEnum>;
  challengeCriteria: Array<ChallengeCriteria>;
  challengeName: Scalars['String']['input'];
  challengeRewards: Array<ChallengeRewardInputV2>;
  countries: Array<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  eligibility: Array<EligibilityCriteria>;
  endDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  startDate: Scalars['OffsetDateTime']['input'];
};


export type MutationcreateFreeSpinsArgs = {
  betLevel: Scalars['Int']['input'];
  campaignId?: InputMaybe<Scalars['CampaignId']['input']>;
  claimType: ClaimType;
  comment: Scalars['String']['input'];
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  endsAt?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  expiresIn?: InputMaybe<Scalars['Duration']['input']>;
  gameDesktopId?: InputMaybe<Scalars['String']['input']>;
  gameMobileId?: InputMaybe<Scalars['String']['input']>;
  gameProviderId: GameProvider;
  playerIds: Array<Scalars['String']['input']>;
  rewardName: Scalars['String']['input'];
  spinsNumber: Scalars['Int']['input'];
  spinsValue: Scalars['Long']['input'];
  startsAt: Scalars['OffsetDateTime']['input'];
};


export type MutationcreateMoneyDropArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  campaignId?: InputMaybe<Scalars['CampaignId']['input']>;
  claimType: ClaimType;
  comment: Scalars['String']['input'];
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  endsAt?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  expiresIn?: InputMaybe<Scalars['Duration']['input']>;
  playerIds: Array<Scalars['String']['input']>;
  rewardName: Scalars['String']['input'];
  startsAt: Scalars['OffsetDateTime']['input'];
};


export type MutationcreatePlayerSessionFromBOArgs = {
  brand: BrandEnum;
  org: OrgEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationcreatePlayerV4Args = {
  birthDate: Scalars['LocalDate']['input'];
  brand: BrandEnum;
  city: Scalars['String']['input'];
  closeCause?: InputMaybe<CloseAccountCauseV4>;
  countryCode: Scalars['String']['input'];
  district?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['PhoneNumber']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};


export type MutationdeleteDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationdeleteFundingDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationforceRegistrationArgs = {
  playerUUID: Scalars['String']['input'];
  registrationScheme: RegistrationScheme;
};


export type MutationforfeitBonusArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationfundingDocumentUploadedArgs = {
  documentId: Scalars['ID']['input'];
  fundingOption: FundingOption;
  playerId: Scalars['ID']['input'];
};


export type MutationgenerateBulkDocumentUrlArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationgenerateBulkGetDocumentUrlArgs = {
  id: Scalars['String']['input'];
};


export type MutationgenerateFinsharkNonSettledDepositsReportArgs = {
  createdAt?: InputMaybe<RangeDate>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationgenerateFinsharkUnknownPayoutsReportArgs = {
  payoutStatus?: InputMaybe<PayoutStatus>;
  unknownFailureAt?: InputMaybe<RangeDate>;
};


export type MutationgenerateFundingDocumentDownloadUrlArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationgenerateGetDocumentUrlArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationgenerateKrofortNonSettledDepositsReportArgs = {
  createdAt?: InputMaybe<RangeDate>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationgenerateKrofortUnknownPayoutsReportArgs = {
  payoutStatus?: InputMaybe<KrofortPayoutStatus>;
  unknownFailureAt?: InputMaybe<RangeDate>;
};


export type MutationisPlayerOnFinsharkWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationisPlayerOnKrofortWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationlinkPlayerArgs = {
  playerGlobalUUID: Scalars['String']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationmanualKycCheckArgs = {
  documentVerificationTypes: Array<DocumentVerificationType>;
  playerId: Scalars['ID']['input'];
  requestedAt?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


export type MutationmanualWithdrawalApprovalArgs = {
  decision: DecisionType;
  paymentId: Scalars['String']['input'];
};


export type MutationmovePhoneNumberBetweenPlayersArgs = {
  brand: BrandEnum;
  oldPlayerId: Scalars['ID']['input'];
  phoneNumber: Scalars['PhoneNumber']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationoverrideRiskLevelArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
  riskLevel: RiskLevel;
};


export type MutationpendingReviewArgs = {
  comment: Scalars['String']['input'];
  playerGlobalUUID: Scalars['String']['input'];
};


export type MutationprocessStuckWithdrawalsFromListArgs = {
  noteContent?: InputMaybe<Scalars['String']['input']>;
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationrejectDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationrejectDocumentV2Args = {
  comment: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
};


export type MutationrejectFundingDocumentArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  fundingOption: FundingOption;
  playerId: Scalars['ID']['input'];
};


export type MutationremoveAffiliateParametersArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationremoveChallengeArgs = {
  challengeId: Scalars['String']['input'];
};


export type MutationremoveDepositLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationremoveFraudStatusOverwriteArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationremoveGlobalLossLimitByPlayerIdArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationremoveLossLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationremovePepStatusOverwriteArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationremovePlayerFromFinsharkWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationremovePlayerFromKrofortWithdrawalBlockWhitelistArgs = {
  playerUUID: Scalars['String']['input'];
};


export type MutationremovePlayersArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['String']['input'];
  playerIds: Array<Scalars['String']['input']>;
};


export type MutationremoveRewardArgs = {
  id: Scalars['String']['input'];
  reason: Scalars['NonEmptyString']['input'];
};


export type MutationremoveRiskLevelOverrideArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationremoveSanctionedStatusOverwriteArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationremoveSessionLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationremoveWagerLimitArgs = {
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationreopenCustomerArgs = {
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationreopenPlayerAccountArgs = {
  overrideCooldown?: InputMaybe<Scalars['Boolean']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationrequestDocumentsArgs = {
  playerId: Scalars['ID']['input'];
  requestedSowDocuments: Array<FundingOption>;
};


export type MutationrequestDocumentsV2Args = {
  playerId: Scalars['ID']['input'];
  requestedSowDocuments: Array<FundingOption>;
};


export type MutationrequestSowArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationresendVerificationEmailArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationresetGlobalInvalidLoginAttemptsLeftArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationresetGlobalInvalidLoginAttemptsLeftV2Args = {
  brand: BrandEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationresetKycEntityIdAuthMethodEntityArgs = {
  brand: BrandEnum;
  kycEntityId: Scalars['String']['input'];
  playerUUID: Scalars['String']['input'];
};


export type MutationresolveRewardArgs = {
  id: Scalars['String']['input'];
  manualDecision: ManualDecisionType;
  playerId: Scalars['ID']['input'];
};


export type MutationretryKrofortKycArgs = {
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationrgCraIndicatorCommunicationsArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationrgCraOverrideRiskLevelArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
  rgCraRiskLevel: RgCraRiskLevel;
};


export type MutationrgCraPayoutRequestArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationrgCraPendingReviewArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationrgCraRemoveRiskLevelOverrideArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationrgCraRewardRequestArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationrgCraSubmitReviewArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
  rgCraReviewStatus: RgCraSubmitReviewStatus;
  rgCraReviewTrigger: RgCraReviewTriggerArg;
};


export type MutationrgCraTriggerManualRiskUpdateArgs = {
  comment: Scalars['String']['input'];
};


export type MutationselfExcludeV3Args = {
  comment: Scalars['String']['input'];
  exclusionEndsAt?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  playerId: Scalars['ID']['input'];
};


export type MutationsetCustomerPopUpsNeededArgs = {
  playerGlobalId: Scalars['ID']['input'];
  popUpIds: Array<Scalars['String']['input']>;
};


export type MutationsetDepositLimitArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationsetGlobalLossLimitByPlayerIdArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationsetLossLimitArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationsetSessionLimitArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationsetWagerLimitArgs = {
  amount: Scalars['PositiveBigDecimal']['input'];
  limitPeriodType: LimitTypeEnum;
  playerId: Scalars['ID']['input'];
};


export type MutationsubmitReviewArgs = {
  comment: Scalars['String']['input'];
  playerGlobalId: Scalars['ID']['input'];
  reviewStatus: SubmitReviewStatus;
  reviewTrigger: ReviewTriggerArg;
};


export type MutationtransformWagerLimitsToLossLimitsArgs = {
  playerIds: Array<Scalars['ID']['input']>;
};


export type MutationtriggerManualRiskUpdateArgs = {
  comment: Scalars['String']['input'];
};


export type MutationunblockDepositLimitArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationunblockInstantBankingArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationunblockSessionLimitArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationunblockWithdrawalsArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationunlinkPlayerArgs = {
  playerId: Scalars['ID']['input'];
};


export type MutationupdateAffiliateParametersArgs = {
  affiliateParameters: AffiliateParameters;
  playerId: Scalars['ID']['input'];
};


export type MutationupdateContentArgs = {
  contentId: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  doc: Scalars['Json']['input'];
  repository: Scalars['String']['input'];
};


export type MutationupdateCustomerCancelWithdrawalBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerDepositBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerIncreaseDepositLimitBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerMarketingBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerRewardsBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerRgNetDepositLimitBlockArgs = {
  enable: Scalars['Boolean']['input'];
  playerGlobalId: Scalars['ID']['input'];
};


export type MutationupdateCustomerWhitelistStatusArgs = {
  playerGlobalId: Scalars['ID']['input'];
  whitelisted: Scalars['Boolean']['input'];
};


export type MutationupdatePepArgs = {
  isPep: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationuploadDocumentArgs = {
  documentId: Scalars['ID']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationuploadDocumentV2Args = {
  comment?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  playerId: Scalars['ID']['input'];
};


export type MutationverifyPlayerSourceOfWealthArgs = {
  playerId: Scalars['ID']['input'];
  verificationDecision: VerificationDecision;
};


export type MutationverifyPlayerSourceOfWealthV2Args = {
  playerId: Scalars['ID']['input'];
  verificationDecision: VerificationDecision;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

/** Note */
export type Note = Node & {
  __typename: 'Note';
  addedBy: Initiator;
  content: Scalars['String']['output'];
  createdAt: Scalars['OffsetDateTime']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  playerId: Scalars['ID']['output'];
  priority: PriorityEnum;
  rawPlayerId: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type NoteConnection = {
  __typename: 'NoteConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<NoteEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NoteEdge = {
  __typename: 'NoteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Note;
};

/** NoteResponse */
export type NoteResponse = Node & {
  __typename: 'NoteResponse';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  note: Note;
  origin: origin;
};

/** A connection to a list of items. */
export type NoteResponseConnection = {
  __typename: 'NoteResponseConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<NoteResponseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NoteResponseEdge = {
  __typename: 'NoteResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: NoteResponse;
};

/** A sorting order specification. */
export enum OrderingSpecType {
  /** Ascending order. */
  ASCENDING = 'ASCENDING',
  /** Descending order. */
  DESCENDING = 'DESCENDING'
}

/** Organization name. */
export enum OrgEnum {
  DT = 'DT',
  Dummy = 'Dummy',
  FW = 'FW',
  HE = 'HE',
  IN = 'IN',
  MT = 'MT',
  TFF = 'TFF'
}

/** The outcome of a risk event */
export type Outcome = {
  /** The name of the outcome */
  name: Scalars['String']['output'];
};

/** The outcome of a risk event in the history */
export enum OutcomeEnum {
  FinalRiskLevelUpdated = 'FinalRiskLevelUpdated',
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected',
  RiskLevelUpdated = 'RiskLevelUpdated',
  RiskOverrideAdded = 'RiskOverrideAdded',
  RiskOverrideRemoved = 'RiskOverrideRemoved'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** A payment. */
export type Payment = {
  __typename: 'Payment';
  amount: Maybe<Scalars['BigDecimal']['output']>;
  brand: Maybe<Brand>;
  /**
   * Timestamp of when the payment status changed to cancelInitialized. None if the
   * state has not been through cancelInitialized yet.
   */
  cancelInitializedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Timestamp of when the payment status changed to cancelled. None if the state has not been through cancelled yet. */
  cancelledAt: Maybe<Scalars['OffsetDateTime']['output']>;
  closingBalance: Maybe<Scalars['BigDecimal']['output']>;
  /** Timestamp of when the payment status changed to completed. None if the state has not been through completed yet. */
  completedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  cryptoInformation: Maybe<CryptoInformation>;
  currency: Maybe<Scalars['String']['output']>;
  euroQuote: Maybe<EuroQuote>;
  extTransactionId: Maybe<Scalars['String']['output']>;
  initialAmount: Maybe<Scalars['BigDecimal']['output']>;
  /** Timestamp of when the payment status changed to initialized. None if the state has not been through initialized yet. */
  initializedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  initiator: Maybe<Initiator>;
  instrument: Maybe<Scalars['String']['output']>;
  integration: Maybe<PaymentIntegrationEnum>;
  /** Timestamp of last transactions */
  lastTransactionAt: Maybe<Scalars['OffsetDateTime']['output']>;
  openingBalance: Maybe<Scalars['BigDecimal']['output']>;
  paymentFee: Maybe<Fee>;
  paymentId: Scalars['ID']['output'];
  paymentMethod: Maybe<Scalars['String']['output']>;
  paymentStatus: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  playerId: Maybe<Scalars['ID']['output']>;
  playerSessionId: Maybe<Scalars['String']['output']>;
  /**
   * Timestamp of last processed transaction, can differ from lastTransactionAt in
   * rare occasions where transactions were processed out of order
   */
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  provider: Maybe<Scalars['String']['output']>;
  providerFee: Maybe<Fee>;
  rawPaymentId: Scalars['String']['output'];
  riskScore: Maybe<Scalars['String']['output']>;
  /** Timestamp of when the payment status changed to rollback. None if the state has not been through rollback yet. */
  rollbackAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /**
   * Timestamp of when the payment status changed to rollbackInitialized. None if
   * the state has not been through rollbackInitialized yet.
   */
  rollbackInitializedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  statusMessage: Maybe<Scalars['String']['output']>;
  targetExtTransactionId: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

/** Information about a payment approval */
export type PaymentApproval = {
  __typename: 'PaymentApproval';
  address: Scalars['String']['output'];
  amount: Money;
  autoDecision: DecisionType;
  initiator: Maybe<Initiator>;
  integration: PaymentIntegration;
  manualDecision: Maybe<DecisionType>;
  paymentId: Scalars['String']['output'];
  paymentType: PaymentType;
  playerId: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
};

/** A connection to a list of items. */
export type PaymentApprovalConnection = {
  __typename: 'PaymentApprovalConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentApprovalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentApprovalEdge = {
  __typename: 'PaymentApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PaymentApproval;
};

/** The order to list PaymentApproval search results */
export enum PaymentApprovalSearchOrder {
  playerId = 'playerId',
  processedAt = 'processedAt'
}

/** A connection to a list of items. */
export type PaymentConnection = {
  __typename: 'PaymentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentEdge = {
  __typename: 'PaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Payment;
};

/** A payment export details. */
export type PaymentExportDetails = {
  __typename: 'PaymentExportDetails';
  url: Scalars['String']['output'];
};

/** Integration with a payment provider */
export enum PaymentIntegration {
  Brite = 'Brite',
  Coinspaid = 'Coinspaid',
  Finshark = 'Finshark',
  Infinipi = 'Infinipi',
  Krofort = 'Krofort',
  PaymentIQ = 'PaymentIQ',
  Sofort = 'Sofort',
  Trustly = 'Trustly',
  Zimpler = 'Zimpler'
}

/** Payment integration name. */
export enum PaymentIntegrationEnum {
  Brite = 'Brite',
  Coinspaid = 'Coinspaid',
  Finshark = 'Finshark',
  Infinipi = 'Infinipi',
  Krofort = 'Krofort',
  PaymentIQ = 'PaymentIQ',
  Sofort = 'Sofort',
  Trustly = 'Trustly',
  Zimpler = 'Zimpler'
}

/** The order to list Payment search results */
export enum PaymentSearchOrder {
  brand = 'brand',
  cancelInitializedAt = 'cancelInitializedAt',
  cancelledAt = 'cancelledAt',
  completedAt = 'completedAt',
  initializedAt = 'initializedAt',
  playerId = 'playerId',
  processedAt = 'processedAt',
  provider = 'provider',
  rollbackAt = 'rollbackAt',
  rollbackInitializedAt = 'rollbackInitializedAt'
}

/** Status of a payment */
export enum PaymentStatus {
  CancelInitialized = 'CancelInitialized',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Initialized = 'Initialized',
  RollbackInitialized = 'RollbackInitialized',
  RolledBacked = 'RolledBacked'
}

/** Player payment summary info */
export type PaymentSummaryInfo = {
  __typename: 'PaymentSummaryInfo';
  amountTotal: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
  lastDays: Scalars['Int']['output'];
  paymentsCount: Scalars['Long']['output'];
  summaryType: Scalars['String']['output'];
};

/** Type of a payment */
export enum PaymentType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export enum PayoutStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Failed = 'Failed',
  Processed = 'Processed'
}

/** View of the pending KYC flow of a player */
export type PendingKycFlowViewItem = {
  __typename: 'PendingKycFlowViewItem';
  flowId: Scalars['ID']['output'];
  kycCheckDeadline: Scalars['OffsetDateTime']['output'];
  kycCheckStartedAt: Scalars['OffsetDateTime']['output'];
  playerGlobalId: Scalars['ID']['output'];
  playerGlobalUUID: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type PendingKycFlowViewItemConnection = {
  __typename: 'PendingKycFlowViewItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PendingKycFlowViewItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PendingKycFlowViewItemEdge = {
  __typename: 'PendingKycFlowViewItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PendingKycFlowViewItem;
};

/** The order to list pending KYC flow results */
export enum PendingKycFlowViewSearchOrder {
  kycCheckDeadline = 'kycCheckDeadline',
  kycCheckStartedAt = 'kycCheckStartedAt',
  playerGlobalId = 'playerGlobalId',
  playerId = 'playerId'
}

/** Pending limit. */
export type PendingLimit = {
  __typename: 'PendingLimit';
  /** Date of configuration. */
  configuredAt: Scalars['OffsetDateTime']['output'];
  /** Date when limit will take effect. */
  validFrom: Scalars['OffsetDateTime']['output'];
  /** Value of the pending limit. */
  value: Maybe<Scalars['BigDecimal']['output']>;
};

/** A PEP and sanctions player list export details. */
export type PepAndSanctionsPlayerListExportDetails = {
  __typename: 'PepAndSanctionsPlayerListExportDetails';
  url: Scalars['String']['output'];
};

/** Period limit. */
export type PeriodLimit = {
  __typename: 'PeriodLimit';
  /** Available amount. */
  available: Scalars['BigDecimal']['output'];
  /** Date of configuration. */
  configuredAt: Scalars['OffsetDateTime']['output'];
  /** Date when current limit period ends */
  currentPeriodEndTime: Scalars['OffsetDateTime']['output'];
  /** How long a period last before resetting. */
  limitPeriodType: Scalars['String']['output'];
  /** Period limit that is waiting to take effect. */
  pendingPeriodLimit: Maybe<PendingLimit>;
  /** Period removal limit that is waiting to take effect. */
  pendingPeriodLimitRemoval: Maybe<PendingLimit>;
  /** Value of the limit. */
  value: Scalars['BigDecimal']['output'];
};

/** A connection to a list of items. */
export type PhoneLoginHistoryConnection = {
  __typename: 'PhoneLoginHistoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PhoneLoginHistoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PhoneLoginHistoryEdge = {
  __typename: 'PhoneLoginHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PhoneLoginHistoryItem;
};

/** Phone login history item */
export type PhoneLoginHistoryItem = {
  __typename: 'PhoneLoginHistoryItem';
  authenticationMethodId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  initiator: Initiator;
  playerId: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
};

/** A player. */
export type Player = EntityWithStatistics & Node & {
  __typename: 'Player';
  accountStatus: AccountStatus;
  affiliateInfo: Maybe<AffiliateInfo>;
  areWithdrawalsBlocked: Scalars['Boolean']['output'];
  /** Information about the player's authentication method */
  authenticationMethod: Maybe<AuthenticationMethod>;
  birthDate: Maybe<Scalars['String']['output']>;
  brand: Brand;
  cashbacksV2: PlayerGameActivityReportConnection;
  city: Maybe<Scalars['String']['output']>;
  /** @deprecated Use selfExclusionHistory instead */
  closures: ClosureSearchItemConnection;
  /** Compliance related limits for the player across all brands */
  complianceGlobalPlayerLimits: Array<ComplianceGlobalPlayerLimit>;
  /** The country where the player is registered to live (ISO 3166-1). */
  countryCode: Maybe<Scalars['String']['output']>;
  /** The customer which this player belongs to */
  customer: Maybe<Customer>;
  depositInfoV2: Maybe<DepositInfo>;
  documents: DocumentConnection;
  email: Maybe<Scalars['String']['output']>;
  financialDataV2: Maybe<PlayerFinancialData>;
  firstName: Maybe<Scalars['String']['output']>;
  gameActivityReportV2: Maybe<GameActivityReport>;
  gameRounds: GameRoundItemConnection;
  gender: Maybe<Scalars['String']['output']>;
  /** Global Limits history */
  globalLimits: GlobalLimitSearchItemConnection;
  /** Information about the player's global login attempts */
  globalLoginAttemptsInfo: Maybe<GlobalLoginAttemptsInfo>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  instantBankingBlock: Maybe<InstantBankingBlock>;
  isEmailMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified: Scalars['Boolean']['output'];
  /** @deprecated Use instantBankingBlock instead, it contains more detailed info */
  isInstantBankingBlocked: Scalars['Boolean']['output'];
  isPep: Scalars['Boolean']['output'];
  isPhoneMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  isPhoneNumberVerified: Scalars['Boolean']['output'];
  isSmsMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  kycCheckStatus: Maybe<KycCheckStatus>;
  language: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  /** Limits history */
  limits: LimitSearchItemConnection;
  linkedAccounts: Array<LinkedAccounts>;
  loginInfo: Maybe<PlayerLoginInfo>;
  marketingInfoV2: Maybe<PlayerMarketingInfo>;
  marketingPreferences: MarketingPreferences;
  middleName: Maybe<Scalars['String']['output']>;
  notes: NoteConnection;
  notesV2: NoteConnection;
  payments: PaymentConnection;
  pendingCashbackV2: Maybe<CashbackTransactionItem>;
  phoneLoginHistory: PhoneLoginHistoryConnection;
  phoneNumber: Maybe<Scalars['String']['output']>;
  playerComplianceDetails: Maybe<PlayerCompliance>;
  playerComplianceDetailsV2: Maybe<PlayerComplianceV2>;
  playerGlobalId: Maybe<Scalars['ID']['output']>;
  playerGlobalUUID: Maybe<Scalars['String']['output']>;
  playerKycHistory: PlayerKycHistoryItemConnection;
  /** List of assigned rewards to players. It's sorted by updatedAt desc if none it's provided */
  playerRewards: PlayerRewardFlatItemConnection;
  /** Ids of all players that are tied to the same source of wealth requirements */
  playersLinkedBySourceOfWealth: Maybe<Array<playerLinkedBySoW>>;
  providedSourceOfFunds: BoSourceOfFundsType;
  rawPlayerId: Scalars['String']['output'];
  /** The RG customer risk assessment for the player */
  rgCraCustomerRiskAssessment: Maybe<RgCraCustomerRiskAssessment>;
  riskAssessment: Maybe<CustomerRiskAssessment>;
  /** Screening Statuses of the player */
  screeningStatuses: Maybe<screeningStatuses>;
  secondLastName: Maybe<Scalars['String']['output']>;
  selfExclusionDetails: Maybe<SelfExclusionDetails>;
  selfExclusionHistory: SelfExclusionHistoryItemConnection;
  shouldReopenAt: Maybe<Scalars['OffsetDateTime']['output']>;
  sourceOfWealth: Maybe<SourceOfWealth>;
  sowBlockedStatus: Maybe<SowBlockedStatus>;
  ssn: Maybe<Scalars['String']['output']>;
  /** Statistics. */
  stats: Maybe<StatsType>;
  street: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  transactionsV2: TransactionSearchItemConnection;
  translatedPersonalInfo: Maybe<TranslatedPersonalInfo>;
  /** Trustly identifier for DE and NL */
  trustlyKycEntityId: Maybe<Scalars['String']['output']>;
  upcomingCashbackV2: Maybe<CashbackTransactionItem>;
  username: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  verificationLog: Maybe<PlayerVerificationLog>;
  verificationTypes: Maybe<PlayerKYCVerifiedItem>;
  wallet: Maybe<Wallet>;
  walletWithGlobalLimits: Maybe<Wallet>;
  zipCode: Maybe<Scalars['String']['output']>;
};


/** A player. */
export type PlayercashbacksV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayerclosuresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  closureType?: InputMaybe<ClosureType>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ClosureSearchOrder>;
};


/** A player. */
export type PlayerdocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DocumentSearchOrder>;
};


/** A player. */
export type PlayergameActivityReportV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayergameRoundsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameId?: InputMaybe<Scalars['String']['input']>;
  gameProviderId?: InputMaybe<GameProvider>;
  gameRoundCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  gameRoundId?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GameRoundSearchOrder>;
  status?: InputMaybe<GameRoundStatus>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A player. */
export type PlayerglobalLimitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limitPeriodType?: InputMaybe<LimitTypeEnum>;
  limitStatus?: InputMaybe<LimitStatusEnum>;
  limitType?: InputMaybe<LimitType>;
  orderBy?: InputMaybe<LimitSearchOrder>;
};


/** A player. */
export type PlayerlimitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limitPeriodType?: InputMaybe<LimitTypeEnum>;
  limitStatus?: InputMaybe<LimitStatusEnum>;
  limitType?: InputMaybe<LimitType>;
  orderBy?: InputMaybe<LimitSearchOrder>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A player. */
export type PlayermarketingInfoV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayernotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PlayerNoteSearchOrder>;
  priority?: InputMaybe<PriorityEnum>;
};


/** A player. */
export type PlayernotesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerNoteSearchOrder>>;
  priority?: InputMaybe<PriorityEnum>;
};


/** A player. */
export type PlayerpaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PaymentSearchOrder>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A player. */
export type PlayerpendingCashbackV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayerphoneLoginHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayerplayerKycHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  getOnlyLatestKycPerPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kycProvider?: InputMaybe<KycProviderType>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PlayerKycHistorySearchOrder>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A player. */
export type PlayerplayerRewardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  assignedAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SearchPlayerRewardsOrderBy>;
  rewardId?: InputMaybe<Scalars['String']['input']>;
  rewardStatus?: InputMaybe<PlayerRewardStatus>;
  rewardStatusList?: InputMaybe<Array<PlayerRewardStatus>>;
  rewardTypes?: InputMaybe<Array<RewardTypeEnum>>;
  updatedAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A player. */
export type PlayerselfExclusionHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A player. */
export type PlayerstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};


/** A player. */
export type PlayertransactionsV2Args = {
  adjustmentCategory?: InputMaybe<AdjustmentCategory>;
  adjustmentType?: InputMaybe<AdjustmentType>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  externalTransactionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameRoundId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  minAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  orderBy?: InputMaybe<TransactionSearchOrder>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};


/** A player. */
export type PlayerupcomingCashbackV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Information regarding the player's address */
export type PlayerAddressInfo = {
  __typename: 'PlayerAddressInfo';
  city: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  district: Maybe<Scalars['String']['output']>;
  fullAddress: Scalars['String']['output'];
  state: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Player challenge item which contains information about players for a given challenge */
export type PlayerChallengeItem = {
  __typename: 'PlayerChallengeItem';
  challenge: ChallengeItemConnection;
  challengeCriteriaCompleted: Scalars['Int']['output'];
  challengeCriteriaRemaining: Array<ChallengeCriteria>;
  challengeCriteriaTotal: Scalars['Int']['output'];
  challengeId: Scalars['ID']['output'];
  challengeName: Scalars['String']['output'];
  challengeUUID: Scalars['String']['output'];
  endDate: Scalars['OffsetDateTime']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  startDate: Scalars['OffsetDateTime']['output'];
};


/** Player challenge item which contains information about players for a given challenge */
export type PlayerChallengeItemchallengeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type PlayerChallengeItemConnection = {
  __typename: 'PlayerChallengeItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerChallengeItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerChallengeItemEdge = {
  __typename: 'PlayerChallengeItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerChallengeItem;
};

/** Player challenge item which contains information about players for a given challenge, with globalId field */
export type PlayerChallengeItemV2 = {
  __typename: 'PlayerChallengeItemV2';
  challenge: ChallengeItemV3Connection;
  challengeCriteriaCompleted: Scalars['Int']['output'];
  challengeCriteriaRemaining: Array<ChallengeCriteria>;
  challengeCriteriaTotal: Scalars['Int']['output'];
  challengeId: Scalars['ID']['output'];
  challengeName: Scalars['String']['output'];
  challengeUUID: Scalars['String']['output'];
  endDate: Scalars['OffsetDateTime']['output'];
  globalId: Scalars['ID']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  startDate: Scalars['OffsetDateTime']['output'];
};


/** Player challenge item which contains information about players for a given challenge, with globalId field */
export type PlayerChallengeItemV2challengeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type PlayerChallengeItemV2Connection = {
  __typename: 'PlayerChallengeItemV2Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerChallengeItemV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerChallengeItemV2Edge = {
  __typename: 'PlayerChallengeItemV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerChallengeItemV2;
};

/** Player compliance item */
export type PlayerCompliance = {
  __typename: 'PlayerCompliance';
  brand: Brand;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  needsManualReview: Scalars['Boolean']['output'];
  playerId: Scalars['String']['output'];
  rawPlayerId: Scalars['String']['output'];
  requiredDocumentTypes: Array<FundingOption>;
  requiresSubmissionSince: Maybe<Scalars['OffsetDateTime']['output']>;
  risk: AmlRiskLevel;
  status: PlayerComplianceStatus;
};

/** Player compliance details item */
export type PlayerComplianceDetails = {
  __typename: 'PlayerComplianceDetails';
  brand: Brand;
  playerId: Scalars['String']['output'];
  playerUUID: Scalars['String']['output'];
};

/** Player compliance details grouped by their playerGlobalId */
export type PlayerComplianceGroupedV2 = {
  __typename: 'PlayerComplianceGroupedV2';
  limitReachedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  needsManualReview: Scalars['Boolean']['output'];
  playerGlobalId: Scalars['String']['output'];
  playerGlobalUUID: Scalars['String']['output'];
  players: Array<PlayerComplianceDetails>;
  risk: AmlRiskLevel;
  startedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  status: PlayerComplianceStatus;
  statusChangedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  submittedAt: Maybe<Scalars['OffsetDateTime']['output']>;
};

/** An entry in the SoW history */
export type PlayerComplianceHistorySearchItem = {
  eventType: Scalars['String']['output'];
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type PlayerComplianceHistorySearchItemConnection = {
  __typename: 'PlayerComplianceHistorySearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerComplianceHistorySearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerComplianceHistorySearchItemEdge = {
  __typename: 'PlayerComplianceHistorySearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerComplianceHistorySearchItem;
};

/** The field by which to order the search results. */
export enum PlayerComplianceHistorySearchOrder {
  globalPlayerId = 'globalPlayerId',
  playerId = 'playerId',
  processedAt = 'processedAt'
}

/** The compliance status of a player */
export enum PlayerComplianceStatus {
  FormSubmitted = 'FormSubmitted',
  Pending = 'Pending',
  PendingDocuments = 'PendingDocuments',
  Rejected = 'Rejected',
  UploadedDocuments = 'UploadedDocuments',
  Verified = 'Verified'
}

/** Player compliance item */
export type PlayerComplianceV2 = {
  __typename: 'PlayerComplianceV2';
  brand: Brand;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  needsManualReview: Scalars['Boolean']['output'];
  playerGlobalId: Scalars['String']['output'];
  requiredDocumentTypes: Array<FundingOption>;
  requiresSubmissionSince: Maybe<Scalars['OffsetDateTime']['output']>;
  risk: AmlRiskLevel;
  status: PlayerComplianceStatus;
};

/** A connection to a list of items. */
export type PlayerComplianceV3Connection = {
  __typename: 'PlayerComplianceV3Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerComplianceV3Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerComplianceV3Edge = {
  __typename: 'PlayerComplianceV3Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerComplianceGroupedV2;
};

/** A players export details. */
export type PlayerExportDetails = {
  __typename: 'PlayerExportDetails';
  url: Scalars['String']['output'];
};

/** Player financial data. */
export type PlayerFinancialData = {
  __typename: 'PlayerFinancialData';
  /** Amount of total GGR. */
  ggr: Scalars['BigDecimal']['output'];
  /** Amount of hold. */
  hold: Scalars['BigDecimal']['output'];
  /** Amount of total NGR. */
  ngr: Scalars['BigDecimal']['output'];
  /** Id of the player */
  playerId: Scalars['ID']['output'];
  /** Total deposits. */
  totalDepositAmount: Scalars['BigDecimal']['output'];
  /** Total withdrawals. */
  totalWithdrawalAmount: Scalars['BigDecimal']['output'];
};

/** A connection to a list of items. */
export type PlayerGameActivityReportConnection = {
  __typename: 'PlayerGameActivityReportConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerGameActivityReportEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerGameActivityReportEdge = {
  __typename: 'PlayerGameActivityReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CashbackTransactionItem;
};

/** PlayerInfoHistory */
export type PlayerInfoHistory = {
  __typename: 'PlayerInfoHistory';
  eventType: Scalars['String']['output'];
  initiator: Initiator;
  player: PlayerSearchItem;
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type PlayerInfoHistoryItemConnection = {
  __typename: 'PlayerInfoHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerInfoHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerInfoHistoryItemEdge = {
  __typename: 'PlayerInfoHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerInfoHistory;
};

export type PlayerInitiator = {
  __typename: 'PlayerInitiator';
  player: Maybe<Player>;
};

/** A player kyc item */
export type PlayerKYCHistoryItem = {
  __typename: 'PlayerKYCHistoryItem';
  city: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  dateOfBirth: Maybe<Scalars['LocalDate']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initiator: Initiator;
  kycProvider: Scalars['String']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  playerId: Scalars['ID']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  rawPlayerId: Scalars['String']['output'];
  ssn: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Player KYC Validation status. */
export type PlayerKYCValidationItem = {
  __typename: 'PlayerKYCValidationItem';
  aboutToExpireValidations: Array<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  expiredValidations: Array<Scalars['String']['output']>;
  pendingDocumentIds: Array<Scalars['String']['output']>;
  pendingValidations: Array<Scalars['String']['output']>;
  playerId: Scalars['ID']['output'];
  rawPlayerId: Scalars['String']['output'];
  rejectedDocumentIds: Array<Scalars['String']['output']>;
  upToDateValidations: Array<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type PlayerKYCValidationItemConnection = {
  __typename: 'PlayerKYCValidationItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerKYCValidationItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerKYCValidationItemEdge = {
  __typename: 'PlayerKYCValidationItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerKYCValidationItem;
};

/** KYC validated verifications */
export type PlayerKYCVerifiedItem = {
  __typename: 'PlayerKYCVerifiedItem';
  expiredVerifications: Array<DocumentVerificationType>;
  playerId: Scalars['ID']['output'];
  rawPlayerId: Scalars['String']['output'];
  upToDateVerifications: Array<VerificationWithProvider>;
};

/** A connection to a list of items. */
export type PlayerKycHistoryItemConnection = {
  __typename: 'PlayerKycHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerKycHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerKycHistoryItemEdge = {
  __typename: 'PlayerKycHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerKYCHistoryItem;
};

/** The order to list player kyc history results */
export enum PlayerKycHistorySearchOrder {
  kycProvider = 'kycProvider',
  processedAt = 'processedAt'
}

/** Player label. */
export type PlayerLabel = {
  __typename: 'PlayerLabel';
  color: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Player login info. */
export type PlayerLoginInfo = {
  __typename: 'PlayerLoginInfo';
  lastLogin: PlayerLoginInfoData;
  registration: PlayerLoginInfoData;
  utmParameters: Maybe<UtmParameters>;
};

/** Player login info. */
export type PlayerLoginInfoData = {
  __typename: 'PlayerLoginInfoData';
  /** An ISO 3166-1 country code from where the player logged in. */
  countryCode: Maybe<Scalars['String']['output']>;
  ip: Scalars['String']['output'];
  loggedAt: Scalars['String']['output'];
};

/** Player marketing info. */
export type PlayerMarketingInfo = {
  __typename: 'PlayerMarketingInfo';
  segment: Scalars['String']['output'];
};

/** The order to list transaction search results. */
export enum PlayerNoteSearchOrder {
  createdAt = 'createdAt',
  priority = 'priority',
  priorityValue = 'priorityValue'
}

/** SoW history entry for PEP status set */
export type PlayerPepStatusSetItem = PlayerComplianceHistorySearchItem & {
  __typename: 'PlayerPepStatusSetItem';
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  isPep: Scalars['Boolean']['output'];
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** Player reward item which contains information about a reward for a given player */
export type PlayerRewardFlatItem = {
  __typename: 'PlayerRewardFlatItem';
  agent: Maybe<Agent>;
  assignedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  playerId: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  reward: RewardItem;
  status: PlayerRewardStatus;
  updatedAt: Scalars['OffsetDateTime']['output'];
};

/** A connection to a list of items. */
export type PlayerRewardFlatItemConnection = {
  __typename: 'PlayerRewardFlatItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerRewardFlatItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerRewardFlatItemEdge = {
  __typename: 'PlayerRewardFlatItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerRewardFlatItem;
};

/** Status of a reward for a given player */
export enum PlayerRewardStatus {
  Credited = 'Credited',
  Expired = 'Expired',
  FailedProcessing = 'FailedProcessing',
  Processing = 'Processing',
  Removed = 'Removed',
  Scheduled = 'Scheduled',
  Unclaimed = 'Unclaimed'
}

/** A player search item. */
export type PlayerSearchItem = EntityWithStatistics & {
  __typename: 'PlayerSearchItem';
  accountStatus: Scalars['String']['output'];
  addressInfo: Maybe<PlayerAddressInfo>;
  /** @deprecated Get this field from the object 'registrationInfo' instead */
  affiliateId: Maybe<Scalars['String']['output']>;
  /** @deprecated Get this field from the object 'registrationInfo' instead */
  affiliateToken: Maybe<Scalars['String']['output']>;
  areWithdrawalsBlocked: Scalars['Boolean']['output'];
  balance: Maybe<Scalars['BigDecimal']['output']>;
  birthDate: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  closeAccountCause: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['ISOCurrencyCode']['output']>;
  emailInfo: EmailInfo;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  instantBankingBlock: Maybe<InstantBankingBlock>;
  /** @deprecated Use instantBankingBlock instead, it contains more detailed info */
  isInstantBankingBlocked: Scalars['Boolean']['output'];
  isPhoneMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  isSmsMarketingAccepted: Maybe<Scalars['Boolean']['output']>;
  labels: Array<PlayerLabel>;
  language: Maybe<Scalars['String']['output']>;
  /** @deprecated Get this field from the object 'loginInfo' instead */
  lastLoginAt: Maybe<Scalars['OffsetDateTime']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  loginInfo: Maybe<LoginInfo>;
  marketingBlocked: Scalars['Boolean']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  player: Player;
  playerGlobalId: Maybe<Scalars['ID']['output']>;
  playerGlobalUUID: Maybe<Scalars['String']['output']>;
  playerId: Scalars['ID']['output'];
  privacyPolicyAccepted: Maybe<Scalars['OffsetDateTime']['output']>;
  rawPlayerId: Scalars['String']['output'];
  /** @deprecated Get this field from the object 'registrationInfo' instead */
  registeredAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** @deprecated Use registrationInfoV2 instead */
  registrationInfo: Maybe<RegistrationInfo>;
  registrationInfoV2: Maybe<RegistrationInfo>;
  secondLastName: Maybe<Scalars['String']['output']>;
  shouldReopenAt: Maybe<Scalars['OffsetDateTime']['output']>;
  ssn: Maybe<Scalars['String']['output']>;
  /** Statistics. */
  stats: Maybe<StatsType>;
  title: Maybe<Scalars['String']['output']>;
  translatedPersonalInfoDocument: Maybe<TranslatedPersonalInfoDocument>;
  username: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};


/** A player search item. */
export type PlayerSearchItemstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};

/** A connection to a list of items. */
export type PlayerSearchItemConnection = {
  __typename: 'PlayerSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PlayerSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PlayerSearchItemEdge = {
  __typename: 'PlayerSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PlayerSearchItem;
};

/** The order to list player search results. */
export enum PlayerSearchOrder {
  balance = 'balance',
  brand = 'brand',
  city = 'city',
  countryCode = 'countryCode',
  dateOfBirth = 'dateOfBirth',
  firstName = 'firstName',
  lastLoginAt = 'lastLoginAt',
  lastName = 'lastName',
  phoneNumber = 'phoneNumber',
  playerGlobalUUID = 'playerGlobalUUID',
  playerId = 'playerId',
  registeredAt = 'registeredAt',
  zipCode = 'zipCode'
}

/** Player verification log. */
export type PlayerVerificationLog = {
  __typename: 'PlayerVerificationLog';
  code: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  ip: Maybe<Scalars['String']['output']>;
  target: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  verificationSentAt: Scalars['String']['output'];
  verifiedAt: Maybe<Scalars['String']['output']>;
};

/** A player wallet summary */
export type PlayerWalletType = {
  __typename: 'PlayerWalletType';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  /** Id of the player */
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  /** the wallet of the player */
  wallet: Maybe<Wallet>;
};

/** Priorities. */
export enum PriorityEnum {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export type Query = {
  __typename: 'Query';
  /** Fetches an object given its ID */
  node: Maybe<Node>;
  /** Fetches objects given their IDs */
  nodes: Array<Maybe<Node>>;
  player: Player;
  playerGlobal: GlobalPlayer;
  viewer: Viewer;
};


export type QuerynodeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerynodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryplayerArgs = {
  playerId: Scalars['ID']['input'];
};


export type QueryplayerGlobalArgs = {
  playerGlobalId: Scalars['ID']['input'];
};

export type RangeDate = {
  from: Scalars['OffsetDateTime']['input'];
  to: Scalars['OffsetDateTime']['input'];
};

export type RangeInt = {
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

/** A sub-part of the total risk assessment. Is made up of several rules */
export type Region = {
  __typename: 'Region';
  /** The final score of the region. How much this region will contribute to the total risk assessment final score */
  finalScore: Scalars['BigDecimal']['output'];
  /** The maximum total score possible for the region */
  maxScore: Scalars['Int']['output'];
  /** The unweighted percentage of the region */
  percentage: Scalars['BigDecimal']['output'];
  /** The name of the region */
  regionName: Scalars['String']['output'];
  /** If an agent review is required */
  reviewRequired: Scalars['Boolean']['output'];
  /** A list of risks that belong to this region */
  risks: Array<Risk>;
  /** The total score of all rules of the region */
  score: Scalars['Int']['output'];
  /** The percentage of how much this region contributes to the total risk assessment */
  weight: Scalars['BigDecimal']['output'];
};

/** Information regarding the registration of the player */
export type RegistrationInfo = {
  __typename: 'RegistrationInfo';
  affiliateId: Maybe<Scalars['String']['output']>;
  affiliateToken: Maybe<Scalars['String']['output']>;
  registeredAt: Scalars['OffsetDateTime']['output'];
  registrationIp: Scalars['String']['output'];
  registrationIpCountryCode: Scalars['String']['output'];
};

/** Registration scheme */
export enum RegistrationScheme {
  ByBrite = 'ByBrite',
  ByEmailPassword = 'ByEmailPassword',
  ByFinshark = 'ByFinshark',
  ByPhoneNumber = 'ByPhoneNumber',
  BySimpleRegistration = 'BySimpleRegistration',
  BySsn = 'BySsn',
  ByTrustly = 'ByTrustly',
  ByZimpler = 'ByZimpler',
  NotRegisteredYet = 'NotRegisteredYet'
}

/** Registration status: Registered or NotRegistered */
export enum RegistrationStatusFilter {
  PlayerNotRegistered = 'PlayerNotRegistered',
  PlayerRegistered = 'PlayerRegistered'
}

/** SoW history entry for SoW funding documents removed */
export type RequestedFundingDocumentsRemovedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'RequestedFundingDocumentsRemovedItem';
  comment: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  remainingRequestedDocuments: Array<FundingOption>;
  removedRequestedDocuments: Array<FundingOption>;
  uuid: Scalars['String']['output'];
};

/** Reserved Balance Adjustment Type */
export enum ReservedAdjustmentType {
  Decrease = 'Decrease',
  Increase = 'Increase'
}

/** Restriction */
export enum Restriction {
  GAMBLING = 'GAMBLING',
  LOTTO = 'LOTTO',
  TOTO = 'TOTO',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Review approved for some trigger */
export type ReviewApproved = Outcome & {
  __typename: 'ReviewApproved';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  reviewTrigger: ReviewTrigger;
};

/** Review needed because of some trigger */
export type ReviewNeeded = Outcome & {
  __typename: 'ReviewNeeded';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  reviewTrigger: ReviewTrigger;
};

/** Review not needed anymore after risk reevaluation */
export type ReviewNotNeeded = Outcome & {
  __typename: 'ReviewNotNeeded';
  /** The name of the outcome */
  name: Scalars['String']['output'];
};

/** Review pending for some trigger */
export type ReviewPending = Outcome & {
  __typename: 'ReviewPending';
  /** The name of the outcome */
  name: Scalars['String']['output'];
};

/** Review rejected for some trigger */
export type ReviewRejected = Outcome & {
  __typename: 'ReviewRejected';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  reviewTrigger: ReviewTrigger;
};

/** Review Status. */
export enum ReviewStatus {
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewNotNeeded = 'ReviewNotNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected'
}

/** Info about the review status */
export type ReviewStatusInfo = {
  __typename: 'ReviewStatusInfo';
  /** Timestamp when the last review happened */
  lastReviewed: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Timestamp when status was last changed */
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The current review status */
  status: ReviewStatus;
};

/** Review trigger */
export type ReviewTrigger = {
  __typename: 'ReviewTrigger';
  byOverallRiskLevel: Scalars['Boolean']['output'];
  byRisks: Array<RiskPath>;
};

/** Review trigger */
export type ReviewTriggerArg = {
  byOverallRiskLevel: Scalars['Boolean']['input'];
  byRisks: Array<RiskPathArg>;
};

/** A reward item */
export type RewardItem = {
  __typename: 'RewardItem';
  campaignId: Scalars['CampaignId']['output'];
  claimType: ClaimType;
  comments: Scalars['String']['output'];
  createdAt: Scalars['OffsetDateTime']['output'];
  createdBy: Maybe<Agent>;
  endsAt: Maybe<Scalars['OffsetDateTime']['output']>;
  expiresIn: Maybe<Scalars['Duration']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  players: PlayerRewardFlatItemConnection;
  rewardId: Scalars['String']['output'];
  rewardReason: RewardReason;
  rewardType: RewardType;
  startsAt: Scalars['OffsetDateTime']['output'];
  status: RewardStatus;
  totalPlayers: Scalars['Long']['output'];
};


/** A reward item */
export type RewardItemplayersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  assignedAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  rewardStatus?: InputMaybe<PlayerRewardStatus>;
  rewardStatusList?: InputMaybe<Array<PlayerRewardStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};

/** A connection to a list of items. */
export type RewardItemConnection = {
  __typename: 'RewardItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RewardItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RewardItemEdge = {
  __typename: 'RewardItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: RewardItem;
};

/** Purpose of the reward. e.g: Incentive, Cashback... */
export enum RewardReason {
  Cashback = 'Cashback',
  Incentive = 'Incentive'
}

/** Possible reward statuses */
export enum RewardStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  NotCreated = 'NotCreated',
  Scheduled = 'Scheduled'
}

/** Details about a reward */
export type RewardType = {
  name: Scalars['String']['output'];
};

/** Reward types */
export enum RewardTypeEnum {
  BonusMoneyDropType = 'BonusMoneyDropType',
  FreeSpinsType = 'FreeSpinsType',
  MoneyDropType = 'MoneyDropType',
  Unknown = 'Unknown'
}

export type RewardTypeInput = {
  bonusMoneyDrop?: InputMaybe<BonusMoneyDropInput>;
  freeSpins?: InputMaybe<FreeSpins>;
  moneyDrop?: InputMaybe<MoneyDropInput>;
  rewardType: RewardTypeEnum;
};

/** The type of event in the risk history */
export enum RgCraAutomationEventType {
  BonusesBlocked = 'BonusesBlocked',
  BonusesEnabled = 'BonusesEnabled',
  CustomerRemovedFromRgWhitelist = 'CustomerRemovedFromRgWhitelist',
  CustomerRgWhitelisted = 'CustomerRgWhitelisted',
  DepositLimitChangesBlocked = 'DepositLimitChangesBlocked',
  DepositsDisabled = 'DepositsDisabled',
  EmailSent = 'EmailSent',
  MarketingBlocked = 'MarketingBlocked',
  MarketingUnblocked = 'MarketingUnblocked',
  PeriodicEmailSent = 'PeriodicEmailSent',
  PlayerAccountsClosed = 'PlayerAccountsClosed',
  PopupDisplayed = 'PopupDisplayed',
  RgNetDepositLimitApplied = 'RgNetDepositLimitApplied',
  WithdrawalsCancellationDisabled = 'WithdrawalsCancellationDisabled',
  WithdrawalsCancellationEnabled = 'WithdrawalsCancellationEnabled'
}

/** RG Automation actions history for a global player */
export type RgCraAutomationHistoryItem = {
  __typename: 'RgCraAutomationHistoryItem';
  eventId: Scalars['String']['output'];
  eventType: RgCraAutomationEventType;
  extraInformation: Maybe<Scalars['String']['output']>;
  playerGlobalId: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  regionId: Maybe<Scalars['String']['output']>;
  regionName: Maybe<Scalars['String']['output']>;
  riskId: Maybe<Scalars['String']['output']>;
  riskLevelAfter: Maybe<RgCraAutomationRiskLevel>;
  riskLevelBefore: Maybe<RgCraAutomationRiskLevel>;
  riskName: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type RgCraAutomationHistoryItemConnection = {
  __typename: 'RgCraAutomationHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RgCraAutomationHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RgCraAutomationHistoryItemEdge = {
  __typename: 'RgCraAutomationHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: RgCraAutomationHistoryItem;
};

/** RG-CRA Automation Risk Levels */
export enum RgCraAutomationRiskLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  VeryHigh = 'VeryHigh'
}

export type RgCraCustomerRiskAssessment = {
  __typename: 'RgCraCustomerRiskAssessment';
  /** The last time the risk assessment was calculated */
  calculatedAt: Scalars['OffsetDateTime']['output'];
  /** Kyc Status for the player global id */
  kycCheckStatus: KycCheckStatus;
  /** If exists, this it the system risk level based on the score. This means that the risk level has been manually overwritten */
  overriddenRiskLevel: Maybe<RgCraRiskLevel>;
  /** Player Global Id */
  playerGlobalId: Scalars['String']['output'];
  /** Players that belong to this risk assessment */
  players: Array<Player>;
  /** A list of the different CRA regions that make up the final score */
  regions: Array<RgCraRegion>;
  /** Current review status */
  reviewStatusInfo: RgCraReviewStatusInfo;
  /** Needs to be passed back in submitReview mutation. Can be considered a token */
  reviewTrigger: RgCraReviewTrigger;
  rgCraNotificationCounters: RgCraNotificationsCounters;
  /** The final risk level of the player */
  riskLevel: RgCraRiskLevel;
};

/** A connection to a list of items. */
export type RgCraCustomerRiskAssessmentConnection = {
  __typename: 'RgCraCustomerRiskAssessmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RgCraCustomerRiskAssessmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RgCraCustomerRiskAssessmentEdge = {
  __typename: 'RgCraCustomerRiskAssessmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: RgCraCustomerRiskAssessment;
};

/** An entry in the risk history */
export type RgCraCustomerRiskAssessmentHistoryItem = {
  __typename: 'RgCraCustomerRiskAssessmentHistoryItem';
  comment: Maybe<Scalars['String']['output']>;
  eventId: Scalars['String']['output'];
  eventType: RgCraEventType;
  initiator: Maybe<Initiator>;
  outcomes: Array<RgCraOutcome>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Maybe<Scalars['String']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
};

/** A connection to a list of items. */
export type RgCraCustomerRiskAssessmentHistoryItemConnection = {
  __typename: 'RgCraCustomerRiskAssessmentHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RgCraCustomerRiskAssessmentHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RgCraCustomerRiskAssessmentHistoryItemEdge = {
  __typename: 'RgCraCustomerRiskAssessmentHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: RgCraCustomerRiskAssessmentHistoryItem;
};

/** The type of event in the risk history */
export enum RgCraEventType {
  ManualRiskUpdate = 'ManualRiskUpdate',
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewNotNeeded = 'ReviewNotNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected',
  RiskLevelOverridden = 'RiskLevelOverridden',
  RiskLevelOverrideRemoved = 'RiskLevelOverrideRemoved',
  RiskRemoved = 'RiskRemoved',
  RiskUpdated = 'RiskUpdated'
}

export type RgCraNotificationsCounter = {
  __typename: 'RgCraNotificationsCounter';
  /** counter for the whole player lifecycle */
  lifecycleCount: Scalars['Int']['output'];
  /** notification type */
  notificationType: Scalars['String']['output'];
  /** counter for the period established */
  periodCount: Scalars['Int']['output'];
  /** days of the period for this counter */
  periodDays: Scalars['Int']['output'];
};

export type RgCraNotificationsCounters = {
  __typename: 'RgCraNotificationsCounters';
  /** counter for communication indicators complain requests */
  commIndicatorsCounter: RgCraNotificationsCounter;
  /** counter for player payout requests */
  payoutsRequestCounter: RgCraNotificationsCounter;
  /** counter for player reward requests */
  rewardsRequestCounter: RgCraNotificationsCounter;
};

/** The outcome of a risk event */
export type RgCraOutcome = {
  /** The name of the outcome */
  comment: Maybe<Scalars['String']['output']>;
  /** The name of the outcome */
  name: Scalars['String']['output'];
};

/** The outcome of a risk event in the history */
export enum RgCraOutcomeEnum {
  FinalRiskLevelUpdated = 'FinalRiskLevelUpdated',
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewNotNeeded = 'ReviewNotNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected',
  RiskLevelUpdated = 'RiskLevelUpdated',
  RiskOverrideAdded = 'RiskOverrideAdded',
  RiskOverrideRemoved = 'RiskOverrideRemoved',
  RiskRemoved = 'RiskRemoved'
}

/** A sub-part of the total risk assessment. Is made up of several rules */
export type RgCraRegion = {
  __typename: 'RgCraRegion';
  /** The name of the region */
  regionName: Scalars['String']['output'];
  /** The risk level of this region risk */
  regionRiskLevel: RgCraRiskLevel;
  /** If an agent review is required */
  reviewRequired: Scalars['Boolean']['output'];
  /** A list of risks that belong to this region */
  risks: Array<RgCraRisk>;
};

/** Review Status */
export enum RgCraReviewStatus {
  ReviewApproved = 'ReviewApproved',
  ReviewNeeded = 'ReviewNeeded',
  ReviewNotNeeded = 'ReviewNotNeeded',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected'
}

/** Info about the review status */
export type RgCraReviewStatusInfo = {
  __typename: 'RgCraReviewStatusInfo';
  /** Timestamp when the last review happened */
  lastReviewed: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Timestamp when status was last changed */
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The current review status */
  status: RgCraReviewStatus;
};

/** Review trigger */
export type RgCraReviewTrigger = {
  __typename: 'RgCraReviewTrigger';
  byOverallRiskLevel: Scalars['Boolean']['output'];
  byRisks: Array<RgCraRiskPath>;
};

/** Review trigger */
export type RgCraReviewTriggerArg = {
  byOverallRiskLevel: Scalars['Boolean']['input'];
  byRisks: Array<RgCraRiskPathArg>;
};

/** The result of a risk calculation attributing a risk score */
export type RgCraRisk = {
  __typename: 'RgCraRisk';
  /** The last time this risk was calculated */
  calculatedAt: Scalars['OffsetDateTime']['output'];
  /** Is an agent review required */
  reviewRequired: Scalars['Boolean']['output'];
  /** The id of the risk */
  riskId: Scalars['String']['output'];
  /** The risk level of this particular risk */
  riskLevel: RgCraRiskLevel;
  /** The name of the risk */
  riskName: Scalars['String']['output'];
};

/** The field to order by */
export enum RgCraRiskAggregationOrderBy {
  calculatedAt = 'calculatedAt',
  reviewStatus = 'reviewStatus',
  riskLevel = 'riskLevel'
}

/** Risk assessment report */
export type RgCraRiskAssessmentReport = {
  __typename: 'RgCraRiskAssessmentReport';
  url: Scalars['String']['output'];
};

/** The field to order by */
export enum RgCraRiskHistoryOrderBy {
  processedAt = 'processedAt'
}

/** RG-CRA Risk Levels */
export enum RgCraRiskLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  VeryHigh = 'VeryHigh'
}

/** risk path */
export type RgCraRiskPath = {
  __typename: 'RgCraRiskPath';
  regionId: Scalars['String']['output'];
  riskId: Scalars['String']['output'];
};

/** Risk path */
export type RgCraRiskPathArg = {
  regionId: Scalars['String']['input'];
  riskId: Scalars['String']['input'];
};

export type RgCraRiskTerm = {
  riskId: Scalars['String']['input'];
  riskLevel: RgCraRiskLevel;
};

/** The type of review that is submitted */
export enum RgCraSubmitReviewStatus {
  ReviewApproved = 'ReviewApproved',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected'
}

/** The result of a risk calculation attributing a risk score */
export type Risk = {
  __typename: 'Risk';
  /** The last time this risk was calculated */
  calculatedAt: Scalars['OffsetDateTime']['output'];
  /** The maximum score possible for this risk */
  maxScore: Scalars['Int']['output'];
  /** Is an agent review required */
  reviewRequired: Scalars['Boolean']['output'];
  /** The id of the risk */
  riskId: Scalars['String']['output'];
  /** The risk level of this particular risk */
  riskLevel: RiskLevel;
  /** The name of the risk */
  riskName: Scalars['String']['output'];
  /** The score that this risk contributes to the region */
  score: Scalars['Int']['output'];
};

/** The field to order by */
export enum RiskAggregationOrderBy {
  calculatedAt = 'calculatedAt',
  finalScore = 'finalScore',
  reviewStatus = 'reviewStatus',
  riskLevel = 'riskLevel',
  score = 'score'
}

/** The field to order by */
export enum RiskHistoryOrderBy {
  processedAt = 'processedAt'
}

/** Customer Risk Assessment Risk Levels */
export enum RiskLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

/** The risk level of an individual risk criteria was updated */
export type RiskLevelUpdated = Outcome & {
  __typename: 'RiskLevelUpdated';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  /** The region id of the risk */
  regionId: Scalars['String']['output'];
  /** The risk id of the risk */
  riskId: Scalars['String']['output'];
  /** The risk level after the event */
  riskLevel: RiskLevel;
  /** The risk level of the risk criteria before the event */
  riskLevelBefore: RiskLevel;
};

/** An override to the final/overall risk level was added */
export type RiskOverrideAdded = Outcome & {
  __typename: 'RiskOverrideAdded';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  /** The risk level of the override that was added */
  riskLevel: RiskLevel;
};

/** An override to the final/overall risk level was removed */
export type RiskOverrideRemoved = Outcome & {
  __typename: 'RiskOverrideRemoved';
  /** The name of the outcome */
  name: Scalars['String']['output'];
  /** The risk level of the override that was removed */
  riskLevel: RiskLevel;
};

/** risk path */
export type RiskPath = {
  __typename: 'RiskPath';
  regionId: Scalars['String']['output'];
  riskId: Scalars['String']['output'];
};

/** Risk path */
export type RiskPathArg = {
  regionId: Scalars['String']['input'];
  riskId: Scalars['String']['input'];
};

/** A rolling period for a limit */
export type RollingPeriod = {
  __typename: 'RollingPeriod';
  period: Maybe<Scalars['Duration']['output']>;
  periodType: RollingPeriodValue;
};

/** The type of rolling period for a limit */
export enum RollingPeriodValue {
  Finite = 'Finite',
  Infinite = 'Infinite'
}

/** The field by which to order the search results. */
export enum SearchChallengesOrderBy {
  challengeId = 'challengeId',
  challengeName = 'challengeName',
  endsAt = 'endsAt',
  processedAt = 'processedAt',
  startsAt = 'startsAt',
  status = 'status'
}

/** The field by which to order the search results. */
export enum SearchPlayerChallengesOrderBy {
  challengeId = 'challengeId',
  challengeName = 'challengeName',
  createdDate = 'createdDate',
  playerId = 'playerId',
  status = 'status'
}

/** The field by which to order the search results. */
export enum SearchPlayerRewardsOrderBy {
  rewardId = 'rewardId',
  status = 'status',
  updatedAt = 'updatedAt'
}

/** The field by which to order the search results. */
export enum SearchRewardsOrderBy {
  createdAt = 'createdAt',
  endsAt = 'endsAt',
  name = 'name',
  startsAt = 'startsAt',
  status = 'status'
}

/** Self exclusion changes count report */
export type SelfExclusionChangesCountReport = {
  __typename: 'SelfExclusionChangesCountReport';
  url: Scalars['String']['output'];
};

/** Self exclusion information */
export type SelfExclusionData = {
  __typename: 'SelfExclusionData';
  activeFrom: Scalars['OffsetDateTime']['output'];
  configuredAt: Scalars['OffsetDateTime']['output'];
  exclusionEndsAt: Maybe<Scalars['OffsetDateTime']['output']>;
};

/** Self Exclusion Details */
export type SelfExclusionDetails = {
  __typename: 'SelfExclusionDetails';
  configuredAt: Maybe<Scalars['OffsetDateTime']['output']>;
  exclusionEndsAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  pending: Maybe<SelfExclusionData>;
  uuid: Scalars['String']['output'];
  willBeCancelledAt: Maybe<Scalars['OffsetDateTime']['output']>;
};

/** Self exclusion history */
export type SelfExclusionHistoryItem = {
  __typename: 'SelfExclusionHistoryItem';
  activeFrom: Maybe<Scalars['OffsetDateTime']['output']>;
  cancelledAt: Maybe<Scalars['OffsetDateTime']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  endsAt: Maybe<Scalars['OffsetDateTime']['output']>;
  eventType: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  playerId: Scalars['ID']['output'];
  previousEndDate: Maybe<Scalars['OffsetDateTime']['output']>;
  processedAt: Scalars['OffsetDateTime']['output'];
  requestedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  requestedBy: Maybe<Initiator>;
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type SelfExclusionHistoryItemConnection = {
  __typename: 'SelfExclusionHistoryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SelfExclusionHistoryItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SelfExclusionHistoryItemEdge = {
  __typename: 'SelfExclusionHistoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SelfExclusionHistoryItem;
};

/** A session search item. */
export type SessionGroupItem = EntityWithStatistics & {
  __typename: 'SessionGroupItem';
  brand: Maybe<Brand>;
  cause: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['OffsetDateTime']['output']>;
  initiator: Maybe<Initiator>;
  player: Player;
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** Statistics. */
  stats: Maybe<StatsType>;
  status: Maybe<Scalars['String']['output']>;
  userAgent: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};


/** A session search item. */
export type SessionGroupItemstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};

/** A session search item. (grouped by ip) */
export type SessionSearchGroupedItem = {
  __typename: 'SessionSearchGroupedItem';
  ip: Scalars['String']['output'];
  sessions: Array<SessionGroupItem>;
};

/** A session search item. */
export type SessionSearchItem = Node & {
  __typename: 'SessionSearchItem';
  brand: Maybe<Brand>;
  cause: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['OffsetDateTime']['output']>;
  /** The id of the object. */
  id: Scalars['ID']['output'];
  initiator: Maybe<Initiator>;
  ip: Maybe<Scalars['String']['output']>;
  player: Maybe<Player>;
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  userAgent: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type SessionSearchItemConnection = {
  __typename: 'SessionSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SessionSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SessionSearchItemEdge = {
  __typename: 'SessionSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SessionSearchGroupedItem;
};

/** A connection to a list of items. */
export type SessionSearchItemV2Connection = {
  __typename: 'SessionSearchItemV2Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SessionSearchItemV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SessionSearchItemV2Edge = {
  __typename: 'SessionSearchItemV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SessionSearchItem;
};

/** The order to list session search results. */
export enum SessionSearchOrder {
  cause = 'cause',
  countryCode = 'countryCode',
  createdAt = 'createdAt',
  status = 'status'
}

/** The status of a session. */
export enum SessionStatus {
  Created = 'Created',
  Destroyed = 'Destroyed'
}

/** The order to list SoW documents */
export enum SoWSearchOrder {
  limitReachedAt = 'limitReachedAt',
  statusChangedAt = 'statusChangedAt',
  submittedAt = 'submittedAt'
}

/** Provided source of fund type */
export enum SourceOfFundType {
  CLIENT_DECLARATION_OF_LINE_OF_BUSINESS = 'CLIENT_DECLARATION_OF_LINE_OF_BUSINESS',
  CRYPTO_MINING = 'CRYPTO_MINING',
  DIVIDENDS_FROM_CAP = 'DIVIDENDS_FROM_CAP',
  DIVIDENDS_FROM_NON_CAP = 'DIVIDENDS_FROM_NON_CAP',
  DONATION_OR_GIFT = 'DONATION_OR_GIFT',
  FUNDS_GENERATED_FROM_GAMBLING_WINNINGS = 'FUNDS_GENERATED_FROM_GAMBLING_WINNINGS',
  INCOME_AND_SALARY_BUSINESS_ACTIVITY = 'INCOME_AND_SALARY_BUSINESS_ACTIVITY',
  INCOME_FROM_INVESTMENTS = 'INCOME_FROM_INVESTMENTS',
  INHERITANCE = 'INHERITANCE',
  LOAN = 'LOAN',
  SALE_OF_COMPANY_FROM_CAP = 'SALE_OF_COMPANY_FROM_CAP',
  SALE_OF_COMPANY_FROM_NON_CAP = 'SALE_OF_COMPANY_FROM_NON_CAP',
  SALE_PROPERTY = 'SALE_PROPERTY',
  SAVINGS_FROM_CAP = 'SAVINGS_FROM_CAP',
  SAVINGS_FROM_NON_CAP = 'SAVINGS_FROM_NON_CAP'
}

export type SourceOfWealth = {
  __typename: 'SourceOfWealth';
  /** In the range of expending, the minimum amount. Same format as salary */
  expendingLowerBound: Maybe<Scalars['Int']['output']>;
  /** In the range of expending, the maximum amount. Same format as salary */
  expendingUpperBound: Maybe<Scalars['Int']['output']>;
  fundingOptions: Array<FundingOption>;
  /**
   * In the range of gross income, the minimum amount.
   * In (0 - 10.000) it would be 0
   * In (10.000 - 30.000) it would be 10000.
   * In 50.000+ it would be 50.000
   */
  grossIncomeLowerBound: Maybe<Scalars['Int']['output']>;
  /**
   * In the range of gross income, the maximum amount.
   * In (0 - 10.000) it would be 10000
   * In 50.000+ it would be null
   */
  grossIncomeUpperBound: Maybe<Scalars['Int']['output']>;
  nationality: Maybe<Scalars['String']['output']>;
  otherFundingDescription: Maybe<Scalars['String']['output']>;
  placeOfBirth: Maybe<Scalars['String']['output']>;
  /**
   * In the range of salary, the minimum amount.
   * In (0 - 10.000) it would be 0
   * In (10.000 - 30.000) it would be 10000.
   * In 50.000+ it would be 50.000
   */
  salaryLowerBound: Maybe<Scalars['Int']['output']>;
  /**
   * In the range of salary, the maximum amount.
   * In (0 - 10.000) it would be 10000
   * In 50.000+ it would be null
   */
  salaryUpperBound: Maybe<Scalars['Int']['output']>;
};

/** SoW history entry for SoW manually requested */
export type SourceOfWealthManuallyRequested = PlayerComplianceHistorySearchItem & {
  __typename: 'SourceOfWealthManuallyRequested';
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** SoW history entry for SoW required */
export type SourceOfWealthRequiredItem = PlayerComplianceHistorySearchItem & {
  __typename: 'SourceOfWealthRequiredItem';
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Maybe<Initiator>;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** SoW history entry for SoW submitted */
export type SourceOfWealthSubmittedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'SourceOfWealthSubmittedItem';
  amlStatus: AmlRiskLevel;
  eventType: Scalars['String']['output'];
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  sourceOfWealth: SourceOfWealth;
  uuid: Scalars['String']['output'];
};

/** SoW history entry for SoW verified */
export type SourceOfWealthVerifiedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'SourceOfWealthVerifiedItem';
  decision: VerificationDecision;
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** The SOW Blocked status of a player */
export enum SowBlockedStatus {
  NotBlocked = 'NotBlocked',
  SowBlock = 'SowBlock'
}

/** SoW history entry for SoW deadline removed */
export type SowDeadlineRemovedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'SowDeadlineRemovedItem';
  comment: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** SoW history entry for sow not requested */
export type SowNotRequestedItem = PlayerComplianceHistorySearchItem & {
  __typename: 'SowNotRequestedItem';
  comment: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** A sow report export details. */
export type SowReportDetails = {
  __typename: 'SowReportDetails';
  url: Scalars['String']['output'];
};

/** Statistics. */
export type StatsEntryType = {
  __typename: 'StatsEntryType';
  /** AvgBetSize */
  AvgBetSize: Maybe<Scalars['Float']['output']>;
  /** AvgDepositAmount */
  AvgDepositAmount: Maybe<Scalars['Float']['output']>;
  /** AvgDepositPerUser */
  AvgDepositPerUser: Maybe<Scalars['Float']['output']>;
  /** AvgRevenuePerUser */
  AvgRevenuePerUser: Maybe<Scalars['Float']['output']>;
  /** NetDeposits */
  NetDeposits: Maybe<Scalars['Float']['output']>;
  /** affiliateId */
  affiliateId: Maybe<Scalars['String']['output']>;
  /** allWins */
  allWins: Maybe<Scalars['Float']['output']>;
  /** amountAdjustments */
  amountAdjustments: Maybe<Scalars['Float']['output']>;
  /** amountAllBonus */
  amountAllBonus: Maybe<Scalars['Float']['output']>;
  /** amountBonusAdjustments */
  amountBonusAdjustments: Maybe<Scalars['Float']['output']>;
  /** amountBonusClaimed */
  amountBonusClaimed: Maybe<Scalars['Float']['output']>;
  /** amountBonusConverted */
  amountBonusConverted: Maybe<Scalars['Float']['output']>;
  /** amountCashbacks */
  amountCashbacks: Maybe<Scalars['Float']['output']>;
  /** amountDeposits */
  amountDeposits: Maybe<Scalars['Float']['output']>;
  /** amountFailedDeposits */
  amountFailedDeposits: Maybe<Scalars['Float']['output']>;
  /** amountFailedWithdrawals */
  amountFailedWithdrawals: Maybe<Scalars['Float']['output']>;
  /** amountFreeSpinWins */
  amountFreeSpinWins: Maybe<Scalars['Float']['output']>;
  /** amountFtd */
  amountFtd: Maybe<Scalars['Float']['output']>;
  /** amountInitWithdrawals */
  amountInitWithdrawals: Maybe<Scalars['Float']['output']>;
  /** amountMoneyDrops */
  amountMoneyDrops: Maybe<Scalars['Float']['output']>;
  /** amountReversals */
  amountReversals: Maybe<Scalars['Float']['output']>;
  /** amountWin */
  amountWin: Maybe<Scalars['Float']['output']>;
  /** amountWithdrawableWinnings */
  amountWithdrawableWinnings: Maybe<Scalars['Float']['output']>;
  /** amountWithdrawals */
  amountWithdrawals: Maybe<Scalars['Float']['output']>;
  /** birthYear */
  birthYear: Maybe<Scalars['String']['output']>;
  /** brand */
  brand: Maybe<Scalars['String']['output']>;
  /** calculatedBalance */
  calculatedBalance: Maybe<Scalars['Float']['output']>;
  /** campaignId */
  campaignId: Maybe<Scalars['String']['output']>;
  /** cashBackEnableLookup */
  cashBackEnableLookup: Maybe<Scalars['String']['output']>;
  /** currency */
  currency: Maybe<Scalars['String']['output']>;
  /** device */
  device: Maybe<Scalars['String']['output']>;
  /** ftd */
  ftd: Maybe<Scalars['Int']['output']>;
  /** ftdOfNrc */
  ftdOfNrc: Maybe<Scalars['Float']['output']>;
  /** ftp */
  ftp: Maybe<Scalars['Int']['output']>;
  /** gameCategoryLookup */
  gameCategoryLookup: Maybe<Scalars['String']['output']>;
  /** @deprecated use gameDescriptorV2 instead. */
  gameDescriptor: Maybe<GameDescriptor>;
  gameDescriptorV2: Maybe<GameDescriptorOrError>;
  /** gameId */
  gameId: Maybe<Scalars['String']['output']>;
  /** gameNameLookup */
  gameNameLookup: Maybe<Scalars['String']['output']>;
  /** gameProvider */
  gameProvider: Maybe<Scalars['String']['output']>;
  /** gameProviderGameId */
  gameProviderGameId: Maybe<Scalars['String']['output']>;
  /** gameStudioLookup */
  gameStudioLookup: Maybe<Scalars['String']['output']>;
  /** gameWin */
  gameWin: Maybe<Scalars['Float']['output']>;
  /** gender */
  gender: Maybe<Scalars['String']['output']>;
  /** ggr */
  ggr: Maybe<Scalars['Float']['output']>;
  /** ggrInRewards */
  ggrInRewards: Maybe<Scalars['Float']['output']>;
  /** ggrInRewardsNoCashback */
  ggrInRewardsNoCashback: Maybe<Scalars['Float']['output']>;
  /** ggr_excFSW */
  ggr_excFSW: Maybe<Scalars['Float']['output']>;
  /** ggr_incFSW */
  ggr_incFSW: Maybe<Scalars['Float']['output']>;
  globalPlayer: Maybe<GlobalPlayer>;
  /** hold_ */
  hold_: Maybe<Scalars['Float']['output']>;
  /** ip */
  ip: Maybe<Scalars['String']['output']>;
  /** isBot */
  isBot: Maybe<Scalars['String']['output']>;
  /** isIncognito */
  isIncognito: Maybe<Scalars['String']['output']>;
  /** isLiveCasinoGameLookup */
  isLiveCasinoGameLookup: Maybe<Scalars['String']['output']>;
  /** jpc */
  jpc: Maybe<Scalars['Float']['output']>;
  /** lastTimeDeposit */
  lastTimeDeposit: Maybe<Scalars['Float']['output']>;
  /** margin */
  margin: Maybe<Scalars['Float']['output']>;
  /** moneyDropType */
  moneyDropType: Maybe<Scalars['String']['output']>;
  /** ngr */
  ngr: Maybe<Scalars['Float']['output']>;
  /** ngrInRewards */
  ngrInRewards: Maybe<Scalars['Float']['output']>;
  /** ngrInRewardsNoCashback */
  ngrInRewardsNoCashback: Maybe<Scalars['Float']['output']>;
  /** ngrSinceLastManualReward */
  ngrSinceLastManualReward: Maybe<Scalars['Float']['output']>;
  /** nrc */
  nrc: Maybe<Scalars['Int']['output']>;
  /** nrcByNsc */
  nrcByNsc: Maybe<Scalars['Float']['output']>;
  /** nsc */
  nsc: Maybe<Scalars['Int']['output']>;
  /** numAdjustments */
  numAdjustments: Maybe<Scalars['Int']['output']>;
  /** numBlockAccountRequests */
  numBlockAccountRequests: Maybe<Scalars['Int']['output']>;
  /** numCancelSelfExclusionRequests */
  numCancelSelfExclusionRequests: Maybe<Scalars['Int']['output']>;
  /** numCashbacks */
  numCashbacks: Maybe<Scalars['Int']['output']>;
  /** numCloseAccountRequests */
  numCloseAccountRequests: Maybe<Scalars['Int']['output']>;
  /** numDeposits */
  numDeposits: Maybe<Scalars['Int']['output']>;
  /** numFailedDeposits */
  numFailedDeposits: Maybe<Scalars['Float']['output']>;
  /** numFailedWithdrawals */
  numFailedWithdrawals: Maybe<Scalars['Int']['output']>;
  /** numMoneyDrops */
  numMoneyDrops: Maybe<Scalars['Int']['output']>;
  /** numReopenAccountRequests */
  numReopenAccountRequests: Maybe<Scalars['Int']['output']>;
  /** numReversals */
  numReversals: Maybe<Scalars['Int']['output']>;
  /** numSelfExclusionRequests */
  numSelfExclusionRequests: Maybe<Scalars['Int']['output']>;
  /** numTotalCloseAccountRequests */
  numTotalCloseAccountRequests: Maybe<Scalars['Float']['output']>;
  /** numUniqueActiveDepositors */
  numUniqueActiveDepositors: Maybe<Scalars['Int']['output']>;
  /** numUniqueActiveGlobalPlayers */
  numUniqueActiveGlobalPlayers: Maybe<Scalars['Int']['output']>;
  /** numUniqueActivePlayers */
  numUniqueActivePlayers: Maybe<Scalars['Int']['output']>;
  /** numUniqueDepositors */
  numUniqueDepositors: Maybe<Scalars['Int']['output']>;
  /** numUniqueFreeSpinAssignations */
  numUniqueFreeSpinAssignations: Maybe<Scalars['Int']['output']>;
  /** numUniquePlayers */
  numUniquePlayers: Maybe<Scalars['Int']['output']>;
  /** numUniqueSessions */
  numUniqueSessions: Maybe<Scalars['Int']['output']>;
  /** numWithdrawals */
  numWithdrawals: Maybe<Scalars['Int']['output']>;
  /** os */
  os: Maybe<Scalars['String']['output']>;
  /** paymentProvider */
  paymentProvider: Maybe<Scalars['String']['output']>;
  /** pendingWithdrawals */
  pendingWithdrawals: Maybe<Scalars['Float']['output']>;
  player: Maybe<Player>;
  /** playerAccountStatusLookup */
  playerAccountStatusLookup: Maybe<Scalars['String']['output']>;
  /** playerCurrentSegment */
  playerCurrentSegment: Maybe<Scalars['String']['output']>;
  /** playerGlobalId */
  playerGlobalId: Maybe<Scalars['String']['output']>;
  /** playerId */
  playerId: Maybe<Scalars['String']['output']>;
  /** registrationDate */
  registrationDate: Maybe<Scalars['String']['output']>;
  /** residenceCountryCode */
  residenceCountryCode: Maybe<Scalars['String']['output']>;
  /** Period of time related to 'Granularity' */
  timePeriod: Maybe<Scalars['String']['output']>;
  /** turnover */
  turnover: Maybe<Scalars['Float']['output']>;
  /** volatilityLookup */
  volatilityLookup: Maybe<Scalars['String']['output']>;
  /** wagers */
  wagers: Maybe<Scalars['Int']['output']>;
};

/** Possible errors when loading statistics. */
export enum StatsError {
  /** No results could be generated within the given time out period. */
  TIMEOUT = 'TIMEOUT',
  /** Statistics are currently unavailable. */
  UNAVAILABLE = 'UNAVAILABLE',
  /** Statistics are currently unavailable for an unhandled reason. */
  UNKNOWN = 'UNKNOWN'
}

/** Statistics. */
export type StatsType = {
  __typename: 'StatsType';
  /** An optional error. */
  error: Maybe<StatsError>;
  /** An optional exported url. */
  exportedUrl: Maybe<Scalars['String']['output']>;
  /** Statistical query. */
  query: Scalars['String']['output'];
  /** Statistical rows. */
  rows: Array<StatsEntryType>;
};

/** Stuck withdrawals report */
export type StuckWithdrawalsReport = {
  __typename: 'StuckWithdrawalsReport';
  url: Scalars['String']['output'];
};

/** The type of review that is submitted */
export enum SubmitReviewStatus {
  ReviewApproved = 'ReviewApproved',
  ReviewPending = 'ReviewPending',
  ReviewRejected = 'ReviewRejected'
}

export type Subscription = {
  __typename: 'Subscription';
  bulkAdjustmentProgress: BulkAdjustmentProgressEventType;
  contentUpdated: UpdateContentEvent;
};


export type SubscriptionbulkAdjustmentProgressArgs = {
  filePath: Scalars['String']['input'];
};

/** The termination cause. */
export enum TerminationCause {
  CreatedByAgent = 'CreatedByAgent',
  FailedLoginAttempt = 'FailedLoginAttempt',
  LimitExceeded = 'LimitExceeded',
  LoggedInFromAnotherDevice = 'LoggedInFromAnotherDevice',
  LoggedOut = 'LoggedOut',
  PlayerInactivated = 'PlayerInactivated',
  PlayerInactivity = 'PlayerInactivity',
  ResponsibleGaming = 'ResponsibleGaming',
  TerminatedBySystem = 'TerminatedBySystem'
}

/** A transaction export details. */
export type TransactionExportDetails = {
  __typename: 'TransactionExportDetails';
  url: Scalars['String']['output'];
};

/** A transaction search item. */
export type TransactionSearchItem = {
  __typename: 'TransactionSearchItem';
  adjustmentType: Maybe<AdjustmentTypeData>;
  amount: Maybe<Scalars['BigDecimal']['output']>;
  bonusTransactionData: Maybe<BonusTransactionData>;
  brand: Maybe<Brand>;
  closingBalance: Maybe<Scalars['BigDecimal']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  cryptoInformation: Maybe<CryptoInformation>;
  currency: Maybe<Scalars['String']['output']>;
  euroQuote: Maybe<EuroQuote>;
  extTransactionId: Maybe<Scalars['String']['output']>;
  fiatQuote: Maybe<FiatQuote>;
  /** @deprecated use gameDescriptorV2 instead. */
  gameDescriptor: Maybe<GameDescriptor>;
  gameDescriptorV2: Maybe<GameDescriptorOrError>;
  gameId: Maybe<Scalars['String']['output']>;
  gameProvider: Maybe<Scalars['String']['output']>;
  gameRoundId: Maybe<Scalars['String']['output']>;
  /** @deprecated Do not ask for this, it will always be null */
  gameRoundStatus: Maybe<Scalars['String']['output']>;
  gameSessionId: Maybe<Scalars['String']['output']>;
  initiator: Maybe<Initiator>;
  isRoundFinished: Maybe<Scalars['Boolean']['output']>;
  openingBalance: Maybe<Scalars['BigDecimal']['output']>;
  payload: Maybe<Scalars['String']['output']>;
  paymentFee: Maybe<Fee>;
  paymentMethod: Maybe<Scalars['String']['output']>;
  paymentStatus: Maybe<Scalars['String']['output']>;
  playerId: Maybe<Scalars['ID']['output']>;
  playerSessionId: Maybe<Scalars['ID']['output']>;
  playerSessionUUID: Maybe<Scalars['String']['output']>;
  processedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  provider: Maybe<Scalars['String']['output']>;
  providerFee: Maybe<Fee>;
  rawTransactionId: Scalars['String']['output'];
  riskScore: Maybe<Scalars['String']['output']>;
  transactionId: Scalars['ID']['output'];
  transactionType: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type TransactionSearchItemConnection = {
  __typename: 'TransactionSearchItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TransactionSearchItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TransactionSearchItemEdge = {
  __typename: 'TransactionSearchItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TransactionSearchItem;
};

/** The order to list transaction search results. */
export enum TransactionSearchOrder {
  amount = 'amount',
  brand = 'brand',
  gameId = 'gameId',
  gameProvider = 'gameProvider',
  gameRoundId = 'gameRoundId',
  playerId = 'playerId',
  processedAt = 'processedAt'
}

/** Type of a transaction. */
export enum TransactionType {
  AdjustmentDeposit = 'AdjustmentDeposit',
  AdjustmentWithdrawal = 'AdjustmentWithdrawal',
  CancelDeposit = 'CancelDeposit',
  CancelWithdrawal = 'CancelWithdrawal',
  Cashback = 'Cashback',
  CasinoBet = 'CasinoBet',
  CasinoDeposit = 'CasinoDeposit',
  CasinoWin = 'CasinoWin',
  Deposit = 'Deposit',
  InitDeposit = 'InitDeposit',
  InitRollbackDeposit = 'InitRollbackDeposit',
  InitRollbackWithdrawal = 'InitRollbackWithdrawal',
  InitWithdrawal = 'InitWithdrawal',
  JackpotWin = 'JackpotWin',
  MoneyDropDeposit = 'MoneyDropDeposit',
  NegativeBet = 'NegativeBet',
  RollbackCasinoBet = 'RollbackCasinoBet',
  RollbackCasinoWin = 'RollbackCasinoWin',
  RollbackDeposit = 'RollbackDeposit',
  RollbackJackpotWin = 'RollbackJackpotWin',
  RollbackWithdrawal = 'RollbackWithdrawal',
  Withdrawal = 'Withdrawal'
}

/** Translated Player's address (for example Japanese players address in Katakana format) */
export type TranslatedPersonalInfo = {
  __typename: 'TranslatedPersonalInfo';
  city: Scalars['String']['output'];
  district: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  state: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
};

/** Contains the address info fields that have translated versions */
export type TranslatedPersonalInfoDocument = {
  __typename: 'TranslatedPersonalInfoDocument';
  city: Maybe<Scalars['String']['output']>;
  district: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
};

/** An update content event triggered by the system. */
export type UpdateContentEvent = Node & {
  __typename: 'UpdateContentEvent';
  contentId: Scalars['String']['output'];
  contentType: Maybe<Scalars['String']['output']>;
  doc: Maybe<Scalars['Json']['output']>;
  /** The id of the object. */
  id: Scalars['ID']['output'];
  repository: Scalars['String']['output'];
  status: Maybe<Scalars['String']['output']>;
};

/** SoW item for uploaded funding document */
export type UploadedFundingDocumentsItem = PlayerComplianceHistorySearchItem & {
  __typename: 'UploadedFundingDocumentsItem';
  eventType: Scalars['String']['output'];
  /** @deprecated Use initiatorV2 */
  initiator: Initiator;
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  remainingToUploadFundingDocuments: Array<FundingOption>;
  uploadedFundingDocument: FundingOption;
  uuid: Scalars['String']['output'];
};

/** UTM parameters. */
export type UtmParameters = {
  __typename: 'UtmParameters';
  /** Identifies a specific product promotion or strategic campaign. */
  utmCampaign: Maybe<Scalars['String']['output']>;
  /** Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link. */
  utmContent: Maybe<Scalars['String']['output']>;
  /** Identifies what type of link was used, such as cost per click or email. */
  utmMedium: Maybe<Scalars['String']['output']>;
  /** Identifies which site sent the traffic. */
  utmSource: Scalars['String']['output'];
  /** Identifies search terms. */
  utmTerm: Maybe<Scalars['String']['output']>;
};

/** Verification decision for Source of Wealth */
export enum VerificationDecision {
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type VerificationEmailSentResponse = {
  __typename: 'VerificationEmailSentResponse';
  email: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
};

/** Verification Type */
export enum VerificationTypeEnum {
  Address = 'Address',
  Age = 'Age',
  Email = 'Email',
  Identity = 'Identity',
  PhoneNumber = 'PhoneNumber',
  SourceOfWealth = 'SourceOfWealth'
}

/** Verification type with the provider that verified it */
export type VerificationWithProvider = {
  __typename: 'VerificationWithProvider';
  provider: Scalars['String']['output'];
  verificationType: DocumentVerificationType;
};

/** A viewer. */
export type Viewer = EntityWithStatistics & Node & {
  __typename: 'Viewer';
  allLinkedAccounts: AllLinkedAccountsItemConnection;
  bonus: Maybe<Bonus>;
  bonusHistory: BonusHistoryConnection;
  bonuses: BonusConnection;
  brands: Array<Brand>;
  cashbackPeriodsForPlayer: Array<CashbackPeriodInfo>;
  /** @deprecated Soon to be deprecated, moving to challengesV2 */
  challenges: ChallengeItemConnection;
  challengesV2: ChallengeItemV3Connection;
  content: ContentSearchItemConnection;
  contentNode: Maybe<ContentSearchItem>;
  detailedCashbackReport: DetailedCashbackReport;
  expiredKycValidations: PlayerKYCValidationItemConnection;
  exportClosingBalancesReport: ClosingBalancesExportDetails;
  exportRiskAssessment: Scalars['Boolean']['output'];
  exportSowReport: SowReportDetails;
  exportedCashbackReportV4: TransactionExportDetails;
  exportedDailyPlayerActivityFromElastic: TransactionExportDetails;
  exportedGlobalPayments: PaymentExportDetails;
  exportedLiabilityReport: TransactionExportDetails;
  /** Mutation to generate the MGA self exclusion report */
  exportedMGASelfExclusionReport: TransactionExportDetails;
  exportedMarketingPlayers: PlayerExportDetails;
  exportedMultipleAccounts: PlayerExportDetails;
  exportedPayments: PaymentExportDetails;
  exportedPlayerKycHistory: PlayerExportDetails;
  exportedPlayers: PlayerExportDetails;
  exportedPlayersKycCheckStatus: LongRunningPlayerExportDetails;
  exportedSelfExclusionsReport: TransactionExportDetails;
  exportedTransactionsV2: TransactionExportDetails;
  getKycRequestCount: GetKycRequestCountRequest;
  /** Allow to get any wallet (from Wallet-Service), used for debugging */
  getSpecificWallet: Wallet;
  globalNotes: NoteResponseConnection;
  hampiCheckByNamePlusDob: Maybe<HampiCheckResult>;
  hampiCheckBySsn: Maybe<HampiCheckResult>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  identity: Identity;
  identityV2: IdentityV2;
  /** @deprecated Parameter name changes to better reflect functionality, use expiredKycValidation instead */
  kycValidation: PlayerKYCValidationItemConnection;
  limitChangesCountReport: LimitChangesCountReport;
  listPaymentApprovals: PaymentApprovalConnection;
  payments: PaymentConnection;
  pendingKycFlowSearch: PendingKycFlowViewItemConnection;
  pepAndSanctionsPlayerListReport: PepAndSanctionsPlayerListExportDetails;
  /** List of changes to compliance */
  playerComplianceHistory: PlayerComplianceHistorySearchItemConnection;
  playerCompliancePlayersV3: PlayerComplianceV3Connection;
  playerInfoHistory: PlayerInfoHistoryItemConnection;
  playerKycHistory: PlayerKycHistoryItemConnection;
  playersAsYouTypeV2: PlayerSearchItemConnection;
  /** @deprecated Soon to be deprecated, moving to playersChallengeV2 */
  playersChallenge: PlayerChallengeItemConnection;
  /** @deprecated Soon to be deprecated, moving to playersChallengeV3 */
  playersChallengeV2: PlayerChallengeItemV2Connection;
  playersChallengeV3: PlayerChallengeItemV2Connection;
  /**
   * For field marked with 'Wildcard enabled' you can use:
   * '?' which matches any single character.
   * '*' which can match zero or more characters, including an empty one.
   */
  playersSearchV2: PlayerSearchItemConnection;
  /** An existing reward */
  reward: Maybe<RewardItem>;
  /** List of created rewards. It's sorted by createdAt desc if none it's provided */
  rewards: RewardItemConnection;
  rgCraAutomationHistory: RgCraAutomationHistoryItemConnection;
  rgCraRiskAssessment: RgCraCustomerRiskAssessment;
  rgCraRiskAssessmentHistory: RgCraCustomerRiskAssessmentHistoryItemConnection;
  rgCraRiskAssessments: RgCraCustomerRiskAssessmentConnection;
  rgCraRiskAssessmentsReport: RgCraRiskAssessmentReport;
  riskAssessment: CustomerRiskAssessment;
  riskAssessmentHistory: CustomerRiskAssessmentHistoryItemConnection;
  riskAssessments: CustomerRiskAssessmentConnection;
  /** Search Players Matching Personal Base Info for Responsible Gaming Check */
  searchPlayerMatchingResponsibleGaming: PlayerSearchItemConnection;
  selfExclusionsChangesCountReport: SelfExclusionChangesCountReport;
  /**
   * Session grouped by IP address. Deprecated because the pagination is broken.
   * @deprecated The pagination logic is broken because of the grouping
   */
  sessions: SessionSearchItemConnection;
  sessionsV2: SessionSearchItemV2Connection;
  sowPlayerIdFromGlobalId: Maybe<PlayerComplianceHistorySearchItem>;
  /** Statistics. */
  stats: Maybe<StatsType>;
  stuckWithdrawalsReport: StuckWithdrawalsReport;
  transactionsV2: TransactionSearchItemConnection;
};


/** A viewer. */
export type ViewerallLinkedAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A viewer. */
export type ViewerbonusArgs = {
  bonusId: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerbonusHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bonusIds?: InputMaybe<Array<Scalars['String']['input']>>;
  brands?: InputMaybe<Array<BrandEnum>>;
  campaignIds?: InputMaybe<Array<Scalars['String']['input']>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  eventTypes?: InputMaybe<Array<BonusEventType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  playerSessionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  processedAt?: InputMaybe<RangeDate>;
  rewardIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** A viewer. */
export type ViewerbonusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bonusIds?: InputMaybe<Array<Scalars['String']['input']>>;
  brands?: InputMaybe<Array<BrandEnum>>;
  campaignIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<RangeDate>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BonusOrder>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rewardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<BonusStatus>>;
};


/** A viewer. */
export type ViewercashbackPeriodsForPlayerArgs = {
  brand: BrandEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  playerId: Scalars['ID']['input'];
};


/** A viewer. */
export type ViewerchallengesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brands?: InputMaybe<Array<BrandEnum>>;
  challengeCriteria?: InputMaybe<Array<ChallengeCriteria>>;
  challengeId?: InputMaybe<Scalars['String']['input']>;
  countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  eligibilityCriteria?: InputMaybe<Array<EligibilityCriteria>>;
  endDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SearchChallengesOrderBy>;
  startDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerchallengesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brands?: InputMaybe<Array<BrandEnum>>;
  challengeCriteria?: InputMaybe<Array<ChallengeCriteria>>;
  challengeId?: InputMaybe<Scalars['String']['input']>;
  countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  eligibilityCriteria?: InputMaybe<Array<EligibilityCriteria>>;
  endDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SearchChallengesOrderBy>;
  startDate?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewercontentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ContentSearchOrder>;
  query?: InputMaybe<Scalars['String']['input']>;
  repositories?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** A viewer. */
export type ViewercontentNodeArgs = {
  contentId: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerdetailedCashbackReportArgs = {
  brand: BrandEnum;
  from: Scalars['OffsetDateTime']['input'];
  playerUUID: Scalars['String']['input'];
  to: Scalars['OffsetDateTime']['input'];
};


/** A viewer. */
export type ViewerexpiredKycValidationsArgs = {
  aboutToExpireThresholdInDays?: InputMaybe<Scalars['PositiveInt']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  documentVerificationTypes: Array<DocumentVerificationType>;
  filterByAmlPercentageAbove?: InputMaybe<Scalars['PositiveInt']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ignorePendingValidations?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A viewer. */
export type ViewerexportClosingBalancesReportArgs = {
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  finalDate: Scalars['LocalDate']['input'];
  initialDate: Scalars['LocalDate']['input'];
};


/** A viewer. */
export type ViewerexportSowReportArgs = {
  amlLevel?: InputMaybe<AmlRiskLevel>;
  complianceStatus?: InputMaybe<PlayerComplianceStatus>;
};


/** A viewer. */
export type ViewerexportedCashbackReportV4Args = {
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  brand: BrandEnum;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  scrollBatchSize?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerexportedDailyPlayerActivityFromElasticArgs = {
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  brand: BrandEnum;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  parallelism?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['OffsetDateTime']['input'];
  wasSentByAffiliates?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A viewer. */
export type ViewerexportedGlobalPaymentsArgs = {
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameProviderId?: InputMaybe<GameProvider>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<PaymentSearchOrder>;
  org?: InputMaybe<OrgEnum>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  playerGlobalId: Scalars['ID']['input'];
  rawId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerexportedLiabilityReportArgs = {
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerexportedMGASelfExclusionReportArgs = {
  brand?: InputMaybe<BrandEnum>;
  finalDate: Scalars['LocalDate']['input'];
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  initialDate: Scalars['LocalDate']['input'];
  initiatorType?: InputMaybe<InitiatorTypeEnum>;
};


/** A viewer. */
export type ViewerexportedMarketingPlayersArgs = {
  brand?: InputMaybe<BrandEnum>;
  isSmsMarketingAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A viewer. */
export type ViewerexportedPaymentsArgs = {
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameProviderId?: InputMaybe<GameProvider>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<PaymentSearchOrder>;
  org?: InputMaybe<OrgEnum>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerexportedPlayerKycHistoryArgs = {
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  getOnlyLatestKycPerPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kycProvider?: InputMaybe<KycProviderType>;
  orderBy?: InputMaybe<PlayerKycHistorySearchOrder>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerexportedPlayersArgs = {
  accountStatus?: InputMaybe<AccountStatusEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  brand?: InputMaybe<BrandEnum>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastLoginFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  lastLoginIp?: InputMaybe<Scalars['String']['input']>;
  lastLoginIpCountryCode?: InputMaybe<Scalars['String']['input']>;
  lastLoginTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maxBalance?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  minBalance?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  orderBy?: InputMaybe<PlayerSearchOrder>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  playerGlobalUUID?: InputMaybe<Scalars['String']['input']>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  registeredFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  registeredIp?: InputMaybe<Scalars['String']['input']>;
  registeredIpCountryCode?: InputMaybe<Scalars['String']['input']>;
  registeredTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  registrationStatus?: InputMaybe<RegistrationStatusFilter>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerexportedPlayersKycCheckStatusArgs = {
  brand?: InputMaybe<BrandEnum>;
  countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** A viewer. */
export type ViewerexportedSelfExclusionsReportArgs = {
  brand?: InputMaybe<BrandEnum>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerexportedTransactionsV2Args = {
  adjustmentCategory?: InputMaybe<AdjustmentCategory>;
  adjustmentType?: InputMaybe<AdjustmentType>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameRoundId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  minAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  orderBy?: InputMaybe<TransactionSearchOrder>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};


/** A viewer. */
export type ViewergetKycRequestCountArgs = {
  kycRequestStatuses: Array<KrofortKycRequestStatus>;
  requestRangeDate: RangeDate;
};


/** A viewer. */
export type ViewergetSpecificWalletArgs = {
  brand: BrandEnum;
  currency: Scalars['String']['input'];
  playerUUID: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerglobalNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerNoteSearchOrder>>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<PriorityEnum>;
};


/** A viewer. */
export type ViewerhampiCheckByNamePlusDobArgs = {
  birthDate: Scalars['LocalDate']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerhampiCheckBySsnArgs = {
  countryCode: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerkycValidationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amlPercentage?: InputMaybe<Scalars['PositiveInt']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  daysBeforeExpire?: InputMaybe<Scalars['PositiveInt']['input']>;
  documentVerificationTypes: Array<DocumentVerificationType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ignorePendingValidations?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A viewer. */
export type ViewerlimitChangesCountReportArgs = {
  from: Scalars['OffsetDateTime']['input'];
  limitType: LimitType;
  to: Scalars['OffsetDateTime']['input'];
};


/** A viewer. */
export type ViewerlistPaymentApprovalsArgs = {
  PaymentIntegration?: InputMaybe<PaymentIntegration>;
  address?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  autoDecision?: InputMaybe<DecisionType>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manualDecision?: InputMaybe<DecisionSearchType>;
  orderBy?: InputMaybe<Array<PaymentApprovalSearchOrder>>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<PaymentType>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  processedAt?: InputMaybe<RangeDate>;
  tag?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerpaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PaymentSearchOrder>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerpendingKycFlowSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  kycCheckDeadlineFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  kycCheckDeadlineTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  kycCheckStartedFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  kycCheckStartedTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  kycFlowId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PendingKycFlowViewSearchOrder>;
  playerGlobalUUID?: InputMaybe<Scalars['String']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerpepAndSanctionsPlayerListReportArgs = {
  registeredFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerplayerComplianceHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PlayerComplianceHistorySearchOrder>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerplayerCompliancePlayersV3Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  amlLevel?: InputMaybe<AmlRiskLevel>;
  before?: InputMaybe<Scalars['String']['input']>;
  complianceStatus?: InputMaybe<PlayerComplianceStatus>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limitReachedAt?: InputMaybe<RangeDate>;
  orderBy?: InputMaybe<SoWSearchOrder>;
  statusChangedAt?: InputMaybe<RangeDate>;
  submittedAt?: InputMaybe<RangeDate>;
};


/** A viewer. */
export type ViewerplayerInfoHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  playerId: Scalars['ID']['input'];
};


/** A viewer. */
export type ViewerplayerKycHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  getOnlyLatestKycPerPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kycProvider?: InputMaybe<KycProviderType>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PlayerKycHistorySearchOrder>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerplayersAsYouTypeV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<PlayerSearchOrder>;
  playerGlobalId?: InputMaybe<Scalars['String']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerplayersChallengeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  challengeIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  challengeStatuses?: InputMaybe<Array<ChallengeStatus>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  globalGlobalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalPlayerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<RangeInt>;
  playerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  searchPlayerChallengeOrderBy?: InputMaybe<SearchPlayerChallengesOrderBy>;
};


/** A viewer. */
export type ViewerplayersChallengeV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  challengeIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  challengeStatuses?: InputMaybe<Array<ChallengeStatus>>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  globalGlobalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalPlayerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<RangeInt>;
  playerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  searchPlayerChallengeOrderBy?: InputMaybe<SearchPlayerChallengesOrderBy>;
};


/** A viewer. */
export type ViewerplayersChallengeV3Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  challengeIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  challengeStatus?: InputMaybe<ChallengeStatus>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  globalGlobalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  globalPlayerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<RangeInt>;
  playerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  searchPlayerChallengeOrderBy?: InputMaybe<SearchPlayerChallengesOrderBy>;
};


/** A viewer. */
export type ViewerplayersSearchV2Args = {
  accountStatus?: InputMaybe<AccountStatusEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  brand?: InputMaybe<BrandEnum>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexPrefix?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastLoginFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  lastLoginIp?: InputMaybe<Scalars['String']['input']>;
  lastLoginIpCountryCode?: InputMaybe<Scalars['String']['input']>;
  lastLoginTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maxBalance?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  minBalance?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  orderBy?: InputMaybe<PlayerSearchOrder>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  playerGlobalUUID?: InputMaybe<Scalars['String']['input']>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  registeredFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  registeredIp?: InputMaybe<Scalars['String']['input']>;
  registeredIpCountryCode?: InputMaybe<Scalars['String']['input']>;
  registeredTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  registrationStatus?: InputMaybe<RegistrationStatusFilter>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerrewardArgs = {
  id: Scalars['String']['input'];
};


/** A viewer. */
export type ViewerrewardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['CampaignId']['input']>;
  createdAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  endsAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  endsAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  excludeRewardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SearchRewardsOrderBy>;
  playerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rewardId?: InputMaybe<Scalars['String']['input']>;
  rewardName?: InputMaybe<Scalars['String']['input']>;
  rewardReason?: InputMaybe<RewardReason>;
  rewardStatus?: InputMaybe<RewardStatus>;
  rewardTypeEnum?: InputMaybe<RewardTypeEnum>;
  rewardTypes?: InputMaybe<Array<RewardTypeEnum>>;
  startsAtFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  startsAtTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
};


/** A viewer. */
export type ViewerrgCraAutomationHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  playerGlobalId: Scalars['ID']['input'];
  showSuspended?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A viewer. */
export type ViewerrgCraRiskAssessmentArgs = {
  playerGlobalId: Scalars['ID']['input'];
};


/** A viewer. */
export type ViewerrgCraRiskAssessmentHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initiatorId?: InputMaybe<Scalars['String']['input']>;
  initiatorType?: InputMaybe<InitiatorTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  rgCraEventId?: InputMaybe<Scalars['String']['input']>;
  rgCraEventType?: InputMaybe<RgCraEventType>;
  rgCraHistoryOrderBy?: InputMaybe<RgCraRiskHistoryOrderBy>;
  rgCraOutcomes?: InputMaybe<Array<RgCraOutcomeEnum>>;
  rgCraProcessedAt?: InputMaybe<RangeDate>;
};


/** A viewer. */
export type ViewerrgCraRiskAssessmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  calculatedAt?: InputMaybe<RangeDate>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastReviewedAt?: InputMaybe<RangeDate>;
  orderBy?: InputMaybe<RgCraRiskAggregationOrderBy>;
  percentage?: InputMaybe<RangeInt>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  rgCraOrderBy?: InputMaybe<RgCraRiskAggregationOrderBy>;
  rgCraReviewStatus?: InputMaybe<Array<RgCraReviewStatus>>;
  rgCraRiskLevels?: InputMaybe<Array<RgCraRiskLevel>>;
  rgCraRiskTerms?: InputMaybe<Array<RgCraRiskTerm>>;
};


/** A viewer. */
export type ViewerrgCraRiskAssessmentsReportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  calculatedAt?: InputMaybe<RangeDate>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastReviewedAt?: InputMaybe<RangeDate>;
  orderBy?: InputMaybe<RgCraRiskAggregationOrderBy>;
  percentage?: InputMaybe<RangeInt>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  rgCraReviewStatus?: InputMaybe<Array<RgCraReviewStatus>>;
  rgCraRiskLevels?: InputMaybe<Array<RgCraRiskLevel>>;
  rgCraRiskTerms?: InputMaybe<Array<RgCraRiskTerm>>;
};


/** A viewer. */
export type ViewerriskAssessmentArgs = {
  playerGlobalId: Scalars['ID']['input'];
};


/** A viewer. */
export type ViewerriskAssessmentHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<EventType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initiatorId?: InputMaybe<Scalars['String']['input']>;
  initiatorType?: InputMaybe<InitiatorTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RiskHistoryOrderBy>;
  outcomes?: InputMaybe<Array<OutcomeEnum>>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  processedAt?: InputMaybe<RangeDate>;
};


/** A viewer. */
export type ViewerriskAssessmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  calculatedAt?: InputMaybe<RangeDate>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  finalScore?: InputMaybe<RangeInt>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastReviewedAt?: InputMaybe<RangeDate>;
  orderBy?: InputMaybe<RiskAggregationOrderBy>;
  percentage?: InputMaybe<RangeInt>;
  playerGlobalId?: InputMaybe<Scalars['ID']['input']>;
  reviewStatus?: InputMaybe<Array<ReviewStatus>>;
  riskLevels?: InputMaybe<Array<RiskLevel>>;
  score?: InputMaybe<RangeInt>;
};


/** A viewer. */
export type ViewersearchPlayerMatchingResponsibleGamingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewerselfExclusionsChangesCountReportArgs = {
  from: Scalars['OffsetDateTime']['input'];
  to: Scalars['OffsetDateTime']['input'];
};


/** A viewer. */
export type ViewersessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  cause?: InputMaybe<TerminationCause>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  initiatorId?: InputMaybe<Scalars['String']['input']>;
  initiatorType?: InputMaybe<InitiatorTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionSearchOrder>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SessionStatus>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewersessionsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  cause?: InputMaybe<TerminationCause>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  initiatorId?: InputMaybe<Scalars['String']['input']>;
  initiatorType?: InputMaybe<InitiatorTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionSearchOrder>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SessionStatus>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};


/** A viewer. */
export type ViewersowPlayerIdFromGlobalIdArgs = {
  playerGlobalId: Scalars['ID']['input'];
};


/** A viewer. */
export type ViewerstatsArgs = {
  dataSourceVersion?: InputMaybe<DataSourceVersionEnum>;
  exchangeRateBaseCurrency?: InputMaybe<Scalars['String']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['LocalDate']['input']>;
  from: Scalars['OffsetDateTime']['input'];
  granularity: GranularityEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnOrderSpecType>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['OffsetDateTime']['input'];
};


/** A viewer. */
export type ViewertransactionsV2Args = {
  adjustmentCategory?: InputMaybe<AdjustmentCategory>;
  adjustmentType?: InputMaybe<AdjustmentType>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandEnum>;
  currency?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  externalTransactionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  gameRoundId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  minAmount?: InputMaybe<Scalars['PositiveBigDecimal']['input']>;
  orderBy?: InputMaybe<TransactionSearchOrder>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  playerId?: InputMaybe<Scalars['ID']['input']>;
  rawId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};

/** A wallet. */
export type Wallet = {
  __typename: 'Wallet';
  /** Amount of balance. */
  balance: Scalars['BigDecimal']['output'];
  /** Brand of the wallet. */
  brand: Maybe<Brand>;
  /** Wallet ISO currency. */
  currency: Scalars['String']['output'];
  /** Deposit limit. */
  depositLimit: Limit;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  /** Loss limit. */
  lossLimit: Limit;
  /** Id of the player */
  playerId: Scalars['ID']['output'];
  /** Amount of reserved balance. */
  reservedBalance: Scalars['BigDecimal']['output'];
  /** Session limit. */
  sessionLimit: Limit;
  uuid: Scalars['String']['output'];
  /** Wager limit. */
  wagerLimit: Limit;
  /** Withdrawal limit. */
  withdrawalLimit: Limit;
};

/** Represents a KycProvider */
export enum kycProvider {
  Brite = 'Brite',
  Dino = 'Dino',
  Eniro = 'Eniro',
  Finshark = 'Finshark',
  Infinipi = 'Infinipi',
  Krofort = 'Krofort',
  Sofort = 'Sofort',
  SumSub = 'SumSub',
  Trustly = 'Trustly',
  Zimpler = 'Zimpler'
}

export type origin = Brand | GlobalOrigin;

/** A players overwritable screening status check result */
export type overwritableStatusCheck = {
  __typename: 'overwritableStatusCheck';
  /** Status check was done */
  checkWasDone: Scalars['Boolean']['output'];
  /** KYC Provider which made the check */
  kycProvider: Maybe<KycProviderType>;
  /** Manual Overwrite */
  overwrite: Maybe<overwrite>;
  /** The result of the status check */
  statusCheck: Maybe<statusCheck>;
};

/** Overwrites a players screening status */
export type overwrite = {
  __typename: 'overwrite';
  at: Scalars['OffsetDateTime']['output'];
  decision: Scalars['Boolean']['output'];
  details: Maybe<Scalars['String']['output']>;
  madeBy: Maybe<Initiator>;
};

/** A player sharing the same source of wealth requirements */
export type playerLinkedBySoW = {
  __typename: 'playerLinkedBySoW';
  id: Scalars['ID']['output'];
  player: Player;
  uuid: Scalars['String']['output'];
};

/** Screening statuses of the player */
export type screeningStatuses = {
  __typename: 'screeningStatuses';
  fraud: overwritableStatusCheck;
  pep: overwritableStatusCheck;
  sanctioned: overwritableStatusCheck;
};

/** SoW threshold reached */
export type sourceOfWealthThresholdReached = PlayerComplianceHistorySearchItem & {
  __typename: 'sourceOfWealthThresholdReached';
  depositProcessedAt: Scalars['OffsetDateTime']['output'];
  eventType: Scalars['String']['output'];
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** SoW threshold unreached */
export type sourceOfWealthThresholdUnreached = PlayerComplianceHistorySearchItem & {
  __typename: 'sourceOfWealthThresholdUnreached';
  depositProcessedAt: Scalars['OffsetDateTime']['output'];
  eventType: Scalars['String']['output'];
  initiatorV2: Maybe<Initiator>;
  playerGlobalId: Scalars['String']['output'];
  playerId: Scalars['ID']['output'];
  playerUUID: Scalars['String']['output'];
  processedAt: Scalars['OffsetDateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** Player screening status */
export type statusCheck = {
  __typename: 'statusCheck';
  checkedAt: Maybe<Scalars['OffsetDateTime']['output']>;
  decision: Scalars['Boolean']['output'];
  details: Maybe<Scalars['String']['output']>;
  score: Maybe<Scalars['Int']['output']>;
  status: Scalars['Boolean']['output'];
};
