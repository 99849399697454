import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import {
  RgRiskAssessmentReportQuery,
  RgRiskAssessmentReportQueryVariables,
} from './__generated__/useRgRiskAssessmentReport';
import { queryParams } from './queryParams';

const RG_RISK_ASSESSMENT_REPORT = gql`
  query RgRiskAssessmentReport(
    $playerGlobalId: ID
    $rgCraRiskLevels: [RgCraRiskLevel!]
    $rgCraReviewStatus: [RgCraReviewStatus!]
    $calculatedAt: RangeDate
    $lastReviewedAt: RangeDate
    $orderBy: RgCraRiskAggregationOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      rgCraRiskAssessmentsReport(
        playerGlobalId: $playerGlobalId
        rgCraRiskLevels: $rgCraRiskLevels
        rgCraReviewStatus: $rgCraReviewStatus
        calculatedAt: $calculatedAt
        lastReviewedAt: $lastReviewedAt
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        url
      }
    }
  }
`;

export default function useRgRiskAssessmentReport() {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    return client
      .query<RgRiskAssessmentReportQuery, RgRiskAssessmentReportQueryVariables>(
        RG_RISK_ASSESSMENT_REPORT,
        mapVariables(query),
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.rgCraRiskAssessmentsReport.url;

        if (url) {
          saveAs(url, 'rg-cra-risk-assessments-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
