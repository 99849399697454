import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { SoWSearchOrder } from '@/globalTypes';
import {
  AmlRiskLevelParam,
  PlayerComplianceStatusParam,
  enumParam,
} from '@/utils/query/params';

const SoWSearchOrderParam = enumParam(SoWSearchOrder);

export const queryParams = {
  after: StringParam,
  amlLevel: AmlRiskLevelParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(SoWSearchOrderParam, SoWSearchOrder.statusChangedAt),
  complianceStatus: PlayerComplianceStatusParam,
  statusChangedAtFrom: DateTimeParam,
  statusChangedAtTo: DateTimeParam,
  limitReachedAtFrom: DateTimeParam,
  limitReachedAtTo: DateTimeParam,
  submittedAtFrom: DateTimeParam,
  submittedAtTo: DateTimeParam,
  first: withDefault(NumberParam, 20),
  last: NumberParam,
};
