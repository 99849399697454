import { useParams } from '@reach/router';
import { formatISO, subHours } from 'date-fns';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { useQuery } from 'urql';

import { KpiMetricBox } from '@/blocks/kpi-metrics-block/KpiMetricBox';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTime, useTranslate } from '@/contexts';
import { predefinedTimeFrames } from '@/contexts/TimeContext/predefinedTimeFrames';
import { mapVariables } from '@/utils';
import { getKpiStats, getKpiValue } from '@/utils/kpi';
import {
  KpiMetricsBoxStatsQuery,
  KpiMetricsBoxStatsQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityKpiMetricsBlock on SanityKpiMetricsBlock {
    title {
      ...SanityLocaleString
    }
    allTime {
      ...SanityLocaleString
    }
    preselectedPeriod {
      ...SanityLocaleString
    }
    lastHour {
      ...SanityLocaleString
    }
    isGlobal
    metrics {
      title {
        ...SanityLocaleString
      }
      name
    }
  }
`;

const statsFragment = gql`
  fragment KpiMetricsStats on StatsType {
    rows {
      AvgBetSize @include(if: $AvgBetSize)
      AvgDepositAmount @include(if: $AvgDepositAmount)
      AvgDepositPerUser @include(if: $AvgDepositPerUser)
      AvgRevenuePerUser @include(if: $AvgRevenuePerUser)
      NetDeposits @include(if: $NetDeposits)
      amountAdjustments @include(if: $amountAdjustments)
      amountBonusAdjustments @include(if: $amountBonusAdjustments)
      amountCashbacks @include(if: $amountCashbacks)
      amountDeposits @include(if: $amountDeposits)
      amountFreeSpinWins @include(if: $amountFreeSpinWins)
      amountMoneyDrops @include(if: $amountMoneyDrops)
      amountWithdrawals @include(if: $amountWithdrawals)
      currency @include(if: $requireCurrency)
      ggr @include(if: $ggr)
      lastTimeDeposit @include(if: $lastTimeDeposit)
      ngr @include(if: $ngr)
      ngrInRewards @include(if: $ngrInRewards)
      ngrSinceLastManualReward @include(if: $ngrSinceLastManualReward)
      numCashbacks @include(if: $numCashbacks)
      numMoneyDrops @include(if: $numMoneyDrops)
      numUniqueFreeSpinAssignations @include(if: $numUniqueFreeSpinAssignations)
    }
    error
  }
`;

const query = gql`
  query KpiMetricsBoxStats(
    $AvgBetSize: Boolean!
    $AvgDepositAmount: Boolean!
    $AvgDepositPerUser: Boolean!
    $AvgRevenuePerUser: Boolean!
    $NetDeposits: Boolean!
    $allTimeFrom: OffsetDateTime!
    $allTimeTo: OffsetDateTime!
    $amountAdjustments: Boolean!
    $amountBonusAdjustments: Boolean!
    $amountCashbacks: Boolean!
    $amountDeposits: Boolean!
    $amountFreeSpinWins: Boolean!
    $amountMoneyDrops: Boolean!
    $amountWithdrawals: Boolean!
    $entityId: ID!
    $from: OffsetDateTime!
    $ggr: Boolean!
    $includeAllTime: Boolean!
    $lastTimeDeposit: Boolean!
    $ngr: Boolean!
    $ngrInRewards: Boolean!
    $ngrSinceLastManualReward: Boolean!
    $numCashbacks: Boolean!
    $numMoneyDrops: Boolean!
    $numUniqueFreeSpinAssignations: Boolean!
    $requireCurrency: Boolean!
    $timeZone: String
    $to: OffsetDateTime!
  ) {
    node(id: $entityId) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(
          granularity: All
          from: $from
          to: $to
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) @include(if: $includeAllTime) {
          ...KpiMetricsStats
        }
        allTimeStats: stats(
          granularity: All
          from: $allTimeFrom
          to: $allTimeTo
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) @include(if: $includeAllTime) {
          ...KpiMetricsStats
        }
      }
    }
  }
  ${statsFragment}
`;

const getLastHour = () => formatISO(subHours(new Date(), 1));

const defaultVariables = {
  AvgBetSize: false,
  AvgDepositAmount: false,
  AvgDepositPerUser: false,
  AvgRevenuePerUser: false,
  NetDeposits: false,
  amountAdjustments: false,
  amountBonusAdjustments: false,
  amountCashbacks: false,
  amountDeposits: false,
  amountFreeSpinWins: false,
  amountMoneyDrops: false,
  amountWithdrawals: false,
  ggr: false,
  lastTimeDeposit: false,
  ngr: false,
  ngrInRewards: false,
  ngrSinceLastManualReward: false,
  numCashbacks: false,
  numMoneyDrops: false,
  numUniqueFreeSpinAssignations: false,
};

const KpiMetricsBlock: FC<{
  block: Queries.SanityKpiMetricsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId, playerGlobalId } = useParams();
  const { timeFrame, time } = useTime();

  const allTimeVariables = mapVariables(time);
  const allTime = mapVariables(predefinedTimeFrames['all-time'].getInterval());

  const isAllTimeSelected =
    timeFrame.name === predefinedTimeFrames['all-time'].name;

  const enabledMetrics = block.metrics?.reduce(
    (acc, key) => ({ ...acc, [key?.name || '']: true }),
    {},
  );

  const entityId = block.isGlobal ? playerGlobalId : playerId;

  const variables = useMemo(() => {
    return {
      entityId,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
      includeAllTime: true,
      requireCurrency: !!entityId,
      ...allTimeVariables,
      ...defaultVariables,
      ...enabledMetrics,
    };
  }, [
    entityId,
    allTime.from,
    allTime.to,
    allTimeVariables,
    enabledMetrics,
    defaultVariables,
  ]);

  const lastHourVariables = useMemo(() => {
    return {
      entityId,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
      from: getLastHour(),
      to: formatISO(new Date()),
      includeAllTime: false,
      requireCurrency: !!entityId,
      ...defaultVariables,
      ...enabledMetrics,
    };
  }, [
    entityId,
    allTime.from,
    allTime.to,
    getLastHour,
    formatISO,
    defaultVariables,
    enabledMetrics,
  ]);

  const [{ data, fetching }, refresh] = useQuery<
    KpiMetricsBoxStatsQuery,
    KpiMetricsBoxStatsQueryVariables
  >({
    query,
    variables,
    pause: !entityId,
  });

  const [{ data: lastHourData, fetching: lastHourFetching }, refreshLastHour] =
    useQuery<KpiMetricsBoxStatsQuery, KpiMetricsBoxStatsQueryVariables>({
      query,
      variables: lastHourVariables,
      pause: !entityId,
    });

  const currency = getKpiStats(data, block.isGlobal).currency;
  const kpiStats = getKpiStats(data, block.isGlobal).stats;
  const kpiAllTimeStats = getKpiStats(data, block.isGlobal).allTimeStats;
  const kpiLastHourStats = getKpiStats(lastHourData, block.isGlobal).stats;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => {
              refresh({ requestPolicy: 'network-only' });
              refreshLastHour({ requestPolicy: 'network-only' });
            }}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody className="p-3 pb-6 container mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-3 self-start">
        {block.metrics?.map((metric) => (
          <KpiMetricBox
            title={t(metric?.title)}
            key={metric?.name}
            block={block}
            fetching={fetching && lastHourFetching}
            currency={currency}
            allTimeValue={getKpiValue(metric?.name, kpiAllTimeStats)}
            lastHourValue={getKpiValue(metric?.name, kpiLastHourStats)}
            preselectedValue={getKpiValue(metric?.name, kpiStats)}
            isAllTimeSelected={isAllTimeSelected}
          />
        ))}
      </CardBody>
    </Card>
  );
};

export default KpiMetricsBlock;
