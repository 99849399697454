import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  ChallengeDetailQuery,
  ChallengeDetailQueryVariables,
} from './__generated__/useChallengeDetail';
import { queryParams } from './queryParams';

const CHALLENGE_DETAIL_QUERY = gql`
  query ChallengeDetail(
    $challengeId: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      challengesV2(challengeId: $challengeId) {
        edges {
          node {
            challengeId
            name
            numberOfPlayers
            status
            startDate
            endDate
            countries
            brands
            eligibleCriteria
            challengeActions
            players(
              first: $first
              last: $last
              after: $after
              before: $before
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  playerUUID
                  playerId
                  globalId
                  challengeCriteriaCompleted
                  challengeCriteriaTotal
                }
              }
            }
            challengeRewards {
              activateAfter
              expiresIn
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useChallengeDetail(challengeId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    ChallengeDetailQuery,
    ChallengeDetailQueryVariables
  >({
    query: CHALLENGE_DETAIL_QUERY,
    variables: {
      first: 20,
      challengeId,
      ...mapVariables(query),
    },
    requestPolicy: 'cache-and-network',
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.challengesV2.edges?.map((e) => e?.node)[0]?.players.pageInfo,
    ),
    challenge: data?.viewer.challengesV2?.edges?.map((e) => e?.node)[0],
    players: data?.viewer.challengesV2?.edges
      ?.map((e) => e?.node)[0]
      ?.players?.edges?.map((e) => e?.node),
  };
}
