import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { assert } from '@/utils/error';
import formatDate from '@/utils/formatter/formatDate';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import { PlayerInfoHistoryQuery } from './__generated__/usePlayerInfoHistory';
import usePlayerInfoHistory from './usePlayerInfoHistory';

export const Fragment = graphql`
  fragment SanityPlayerInfoHistoryBlock on SanityPlayerInfoHistoryBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const formatEvent = (event: string) => {
  return event
    .split(/(?=[A-Z])/)
    .join(' ')
    .replace('Event', '')
    .toString();
};

const getEventValue = (
  node: NonNullable<
    NonNullable<
      PlayerInfoHistoryQuery['viewer']['playerInfoHistory']['edges']
    >[number]
  >['node'],
) => {
  switch (node.eventType) {
    case 'ChangePhoneNumberEvent':
      return formatPhoneNumber(node.player.phoneNumber);
    case 'ChangeEmailAddressEvent':
      return node.player.emailInfo.email;
    case 'ChangePersonalDetailsEvent':
      return [
        node.player.firstName,
        node.player.middleName,
        node.player.lastName,
      ]
        .filter(Boolean)
        .join(' ');
    case 'ChangeAddressEvent':
      return node.player.addressInfo?.fullAddress;
    case 'ChangeBirthDateEvent':
      return formatDate(node.player.birthDate, true);
    case 'SetBrandEvent':
      return node.player.brand?.name;
    default:
      return '';
  }
};

const PlayerInfoHistoryBlock: FC<{
  block: Queries.SanityPlayerInfoHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    defaultFilter,
    updateFilter,
  } = usePlayerInfoHistory(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerInfoHistory',
    data: history,
    fetching,
    setOrderBy: () => {},
    desc: true,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.stringValue({
        field: 'eventType',
        title: 'Event',
        getValue: ({ row }) => formatEvent(row.eventType),
      }),
      s.stringValue({
        field: 'value',
        title: 'Value',
        getValue: ({ row }) => getEventValue(row),
      }),
    ],
  });
  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerInfoHistoryBlock;
