import { GameProvider } from '@/globalTypes';

const availableGameProviders: GameProvider[] = [
  GameProvider.Relax,
  GameProvider.Pragmatic,
  GameProvider.Evolution,
  GameProvider.Aleaplay,
];

export const rewardGameProviders = Object.values(availableGameProviders).map(
  (provider) => {
    return { label: provider, value: provider };
  },
);
