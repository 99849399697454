import classNames from 'classnames';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';

import {
  useGetPlayerOverviewPageLink,
  useGetPlayerRgRiskAssessmentOverviewPageLink,
} from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  EyeIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  BrandEnum,
  ReviewStatus,
  RgCraRiskAggregationOrderBy,
  RgCraRiskLevel,
  RiskAggregationOrderBy,
} from '@/globalTypes';
import { getRgRiskLevelColor } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';
import { splitStringIntoTwoWords } from '@/utils/split-string-in-two-words';
import useRgRiskAssessment from './useRgRiskAssessment';
import useRgRiskAssessmentReport from './useRgRiskAssessmentReport';

const BLOCK_STATIC_QUERY = graphql`
  query SanityRgRiskAssessmentBlockStatic {
    sanityRgRiskAssessmentBlock {
      title {
        ...SanityLocaleString
      }
      playerGlobalId {
        ...SanityLocaleString
      }
      brands {
        ...SanityLocaleString
      }
      risk {
        ...SanityLocaleString
      }
      calculatedAt {
        ...SanityLocaleString
      }
      reviewStatus {
        ...SanityLocaleString
      }
      lastReviewedDate {
        ...SanityLocaleString
      }
    }
  }
`;

const RiskAssessmentBlock: FC = () => {
  const { t } = useTranslate();
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();
  const getRgRiskAssessmentOverviewPage =
    useGetPlayerRgRiskAssessmentOverviewPageLink();

  const report = useRgRiskAssessmentReport();

  const block =
    useStaticQuery<Queries.SanityRgRiskAssessmentBlockStaticQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityRgRiskAssessmentBlock;

  const {
    entries,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useRgRiskAssessment();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'rgRiskAssessments',
    data: entries,
    fetching,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'playerGlobalId',
        title: t(block?.playerGlobalId),
        filterField: 'playerGlobalId',
        linkTo: ({ row }) =>
          (row?.playerGlobalId &&
            getRgRiskAssessmentOverviewPage(row.playerGlobalId)) ??
          '',
      }),
      s.enumValue({
        field: 'brand',
        title: t(block?.brands),
        e: BrandEnum,
        wrap: ({ row }) => (
          <div className="flex items-center">
            {row.players.length < 3 ? (
              <span>
                {row.players.map((player) => player.brand.name).join(', ')}
              </span>
            ) : (
              <>
                <span className="text-blue-600">{row.players.length}</span>
                <ControlledModal
                  content={
                    <Card
                      title={t(block?.brands)}
                      size="lg"
                      options={
                        <CardOptions>
                          <CardCloseButton />
                        </CardOptions>
                      }
                    >
                      <div className="p-3">
                        <Table
                          headings={['Player ID', 'Brand']}
                          rows={row.players.map((player) => ({
                            key: player.id,
                            data: [
                              <Link
                                className="w-full h-full p-3 block"
                                to={getPlayerOverviewLink(player.id) ?? ''}
                              >
                                {player.rawPlayerId}
                              </Link>,
                              player.brand.name,
                            ],
                          }))}
                        />
                      </div>
                    </Card>
                  }
                >
                  <InlineIconButton>
                    <EyeIcon className="text-gray-400 text-lg" />
                  </InlineIconButton>
                </ControlledModal>
              </>
            )}
          </div>
        ),
      }),
      s.enumValue({
        field: 'rgCraRiskLevels',
        title: t(block?.risk),
        filterField: 'rgCraRiskLevels',
        e: RgCraRiskLevel,
        orderBy: RgCraRiskAggregationOrderBy.riskLevel,
        wrap: ({ row }) => (
          <span>
            <MdOutlineErrorOutline
              className={classNames(
                'inline-block',
                'mr-2',
                'text-lg',
                getRgRiskLevelColor(row.riskLevel),
              )}
            />
            {splitStringIntoTwoWords(row.riskLevel)}
          </span>
        ),
      }),
      s.dateTimeValue({
        field: 'calculatedAt',
        orderBy: RiskAggregationOrderBy.calculatedAt,
        title: t(block?.calculatedAt),
        fromFilterField: 'calculatedAtFrom',
        toFilterField: 'calculatedAtTo',
        getValue: ({ row }) => formatDate(row.calculatedAt),
      }),
      s.enumValue({
        field: 'reviewStatusInfo.status',
        title: t(block?.reviewStatus),
        filterField: 'rgCraReviewStatus',
        e: ReviewStatus,
        getValue: ({ row }) => row.reviewStatusInfo.status,
      }),
      s.dateTimeValue({
        field: 'reviewStatusInfo.lastReviewed',
        title: t(block?.lastReviewedDate),
        fromFilterField: 'lastReviewedFrom',
        toFilterField: 'lastReviewedTo',
        getValue: ({ row }) => formatDate(row.reviewStatusInfo.lastReviewed),
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default RiskAssessmentBlock;
