import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { Nullable } from '@/types';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  GenericSowHistoryQuery,
  GenericSowHistoryQueryVariables,
} from './__generated__/useGenericSowHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query GenericSowHistory(
    $after: String
    $before: String
    $desc: Boolean
    $eventType: String
    $first: Int
    $from: OffsetDateTime
    $last: Int
    $orderBy: PlayerComplianceHistorySearchOrder
    $playerGlobalId: ID
    $to: OffsetDateTime
  ) {
    viewer {
      id
      playerComplianceHistory(
        after: $after
        before: $before
        desc: $desc
        eventType: $eventType
        first: $first
        from: $from
        last: $last
        orderBy: $orderBy
        playerGlobalId: $playerGlobalId
        to: $to
      ) {
        edges {
          node {
            uuid
            playerId
            playerUUID
            playerGlobalId
            eventType
            processedAt
            ... on PlayerPepStatusSetItem {
              isPep
              initiator: initiatorV2 {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on SourceOfWealthRequiredItem {
              initiator: initiatorV2 {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on SourceOfWealthSubmittedItem {
              sourceOfWealth {
                fundingOptions
                salaryLowerBound
                salaryUpperBound
                expendingLowerBound
                expendingUpperBound
                otherFundingDescription
                nationality
                placeOfBirth
              }
              amlStatus
            }
            ... on SourceOfWealthVerifiedItem {
              decision
              initiator: initiatorV2 {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on AmlRiskLevelChangedItem {
              amlRiskLevel
              reason
              initiator: initiatorV2 {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on FundingDocumentsRequestedItem {
              requestedFundingDocuments
              initiator: initiatorV2 {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on CraRiskLevelChangedItem {
              customerRisk
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useGenericSowHistory(playerGlobalId: Nullable<string>) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [{ data, fetching }, refresh] = useQuery<
    GenericSowHistoryQuery,
    GenericSowHistoryQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: playerGlobalId!,
      ...mapVariables(query),
    },
    pause: !playerGlobalId,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.playerComplianceHistory.pageInfo,
    ),
    history: data?.viewer.playerComplianceHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
