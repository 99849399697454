import { gql, useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { usePlayer } from '@/hooks';
import { mapVariables } from '@/utils';
import { makePagination } from '@/utils/pagination';
import {
  PlayerRgAutomationHistoryQuery,
  PlayerRgAutomationHistoryQueryVariables,
} from './__generated__/usePlayerRgAutomationHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerRgAutomationHistory(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $playerGlobalId: ID!
  ) {
    viewer {
      id
      rgCraAutomationHistory(
        after: $after
        before: $before
        first: $first
        last: $last
        playerGlobalId: $playerGlobalId
      ) {
        edges {
          node {
            eventId
            eventType
            extraInformation
            playerGlobalId
            processedAt
            regionId
            regionName
            riskId
            riskName
            riskLevelBefore
            riskLevelAfter
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerRgAutomationHistory() {
  const [query, setQuery] = useQueryParams(queryParams);
  const { playerGlobalId } = usePlayer();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRgAutomationHistoryQuery,
    PlayerRgAutomationHistoryQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: playerGlobalId || '',
      ...mapVariables(query),
    },
    pause: !playerGlobalId,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.rgCraAutomationHistory.pageInfo,
    ),
    history: data?.viewer.rgCraAutomationHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
