import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import {
  RgReviewStatusParam,
  RgRiskAggregationOrderBy,
  RgRiskLevelsParam,
} from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  calculatedAtFrom: DateTimeParam,
  calculatedAtTo: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  lastReviewedFrom: DateTimeParam,
  lastReviewedTo: DateTimeParam,
  orderBy: RgRiskAggregationOrderBy,
  playerGlobalId: StringParam,
  rgCraReviewStatus: RgReviewStatusParam,
  rgCraRiskLevels: RgRiskLevelsParam,
};
