import React from 'react';

import formatMoney from '@/utils/formatter/formatMoney';
import { GenericSowHistoryQuery } from './__generated__/useGenericSowHistory';

type PlayerComplianceHistoryNode = NonNullable<
  NonNullable<
    GenericSowHistoryQuery['viewer']['playerComplianceHistory']['edges']
  >[number]
>['node'];

const extractSow = (row: PlayerComplianceHistoryNode) =>
  ('sourceOfWealth' in row && row.sourceOfWealth) || null;

export const renderFundingOptions = (row: PlayerComplianceHistoryNode) => {
  const sow = extractSow(row);
  if (sow?.fundingOptions) {
    return <li>Funding Options: {sow.fundingOptions.join(', ')}</li>;
  }
  return null;
};

export const renderDecision = (row: PlayerComplianceHistoryNode) => {
  if ('decision' in row && row.decision) {
    return <li>Decision: {row.decision}</li>;
  }
  return null;
};

export const renderAmlStatus = (row: PlayerComplianceHistoryNode) => {
  if ('amlStatus' in row && row.amlStatus) {
    return <li>AML Status: {row.amlStatus}</li>;
  }
  return null;
};

export const renderSalaryLowerBound = (row: PlayerComplianceHistoryNode) => {
  const sow = extractSow(row);

  if (sow?.salaryUpperBound) {
    return (
      <li>
        Salary Bound: {formatMoney(sow.salaryLowerBound)}
        {sow.salaryUpperBound ? ` - ${formatMoney(sow.salaryUpperBound)}` : ''}
      </li>
    );
  }
  return null;
};

export const renderExpendingLowerBound = (row: PlayerComplianceHistoryNode) => {
  const sow = extractSow(row);
  if (sow?.expendingUpperBound) {
    return (
      <li>
        Expending Bound: {formatMoney(sow.expendingLowerBound)}
        {sow.expendingUpperBound
          ? ` - ${formatMoney(sow.expendingUpperBound)}`
          : ''}
      </li>
    );
  }
  return null;
};

export const renderNationality = (row: PlayerComplianceHistoryNode) => {
  const sow = extractSow(row);
  if (sow?.nationality) {
    return <li>Nationality: {sow.nationality}</li>;
  }
  return null;
};

export const renderPlaceOfBirth = (row: PlayerComplianceHistoryNode) => {
  const sow = extractSow(row);
  if (sow?.placeOfBirth) {
    return <li>Place of Birth: {sow.placeOfBirth}</li>;
  }
  return null;
};

export const renderOtherFundingDescription = (
  row: PlayerComplianceHistoryNode,
) => {
  const sow = extractSow(row);
  if (sow?.otherFundingDescription) {
    return <li>Description: {sow.otherFundingDescription}</li>;
  }
  return null;
};

export const renderRequestedFundingOptions = (
  row: PlayerComplianceHistoryNode,
) => {
  if (
    'requestedFundingDocuments' in row &&
    row.requestedFundingDocuments.length
  ) {
    return (
      <li>Requested documents: {row.requestedFundingDocuments.join(', ')}</li>
    );
  }
  return null;
};

export const renderAmlRiskLevel = (row: PlayerComplianceHistoryNode) => {
  if ('amlRiskLevel' in row && row.amlRiskLevel) {
    return <li>New AML Risk Level: {row.amlRiskLevel}</li>;
  }
  return null;
};

export const renderCraRiskLevel = (row: PlayerComplianceHistoryNode) => {
  if ('customerRisk' in row && row.customerRisk) {
    return <li>{row.customerRisk}</li>;
  }
  return null;
};

export const renderReason = (row: PlayerComplianceHistoryNode) => {
  if ('reason' in row && row.reason) {
    return <li>AML Risk level Reason: {row.reason}</li>;
  }
  return null;
};
