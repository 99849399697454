import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { CombinedError, useMutation } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { FileDrop, useFileDropFile } from '@/bits/file-drop/component';
import { playerDocuments_document } from '@/blocks/player-documents-block/usePlayerDocuments';
import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { FundingOption } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ApproveFundingDocumentMutation,
  ApproveFundingDocumentMutationVariables,
  FundingDocumentUploadedMutation,
  FundingDocumentUploadedMutationVariables,
  PlayerGenerateDocumentUrlMutation,
  PlayerGenerateFundingDocumentUploadUrlMutationVariables,
  UploadDocumentMutation,
  UploadDocumentMutationVariables,
} from './__generated__/PlayerDocumentUploadForm';

const query = graphql`
  query PlayerDocumentUploadFormStaticQuery {
    sanityPlayerDocumentUploadForm {
      title {
        ...SanityLocaleString
      }
      fundingOptionLabel {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      autoApproveLabel {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const generateDocumentUrlMutation = gql`
  mutation PlayerGenerateDocumentUrl {
    generateDocumentUrl {
      url
      documentId
    }
  }
`;

export const generateFundingDocumentUploadUrlMutation = gql`
  mutation PlayerGenerateFundingDocumentUploadUrl {
    generateFundingDocumentUploadUrl {
      url
      documentId
    }
  }
`;

const fundingDocumentUploadedMutation = gql`
  mutation FundingDocumentUploaded(
    $documentId: ID!
    $playerId: ID!
    $fundingOption: FundingOption!
  ) {
    fundingDocumentUploaded(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

const approveFundingDocumentMutation = gql`
  mutation ApproveFundingDocument(
    $playerId: ID!
    $documentId: ID!
    $fundingOption: FundingOption!
    $comment: String
  ) {
    approveFundingDocument(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
      comment: $comment
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

export const uploadDocumentMutation = gql`
  mutation UploadDocument($documentId: ID!, $playerId: ID!, $comment: String!) {
    uploadDocumentV2(
      documentId: $documentId
      playerId: $playerId
      comment: $comment
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

const Layout: FC<{
  form: Queries.PlayerDocumentUploadFormStaticQueryQuery['sanityPlayerDocumentUploadForm'];
  children: ReactNode;
}> = ({ form, children }) => {
  const { t } = useTranslate();

  return (
    <Card
      size="lg"
      title={t(form?.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">{children}</div>
    </Card>
  );
};

type Props = {
  isGlobalPlayer: boolean;
  playerOptions?: SelectOption[];
};

type UploadReason = 'kyc' | 'sow';

type FormValues = {
  comment: string;
  playerId: string;
  fundingOption: FundingOption;
  autoApprove: boolean;
};

const useGetDocumentUploadInfo = (reason: UploadReason) => {
  const [, generateDocumentUrl] =
    useMutation<PlayerGenerateDocumentUrlMutation>(generateDocumentUrlMutation);

  const [, generateFundingDocumentUploadUrl] =
    useMutation<PlayerGenerateFundingDocumentUploadUrlMutationVariables>(
      generateFundingDocumentUploadUrlMutation,
    );

  const getDocumentUploadInfo = useCallback(async () => {
    if (reason === 'kyc') {
      return generateDocumentUrl().then((res) => ({
        error: res.error,
        documentInfo: res.data?.generateDocumentUrl,
      }));
    }

    return generateFundingDocumentUploadUrl().then((res) => ({
      error: res.error,
      documentInfo: res.data?.generateFundingDocumentUploadUrl,
    }));
  }, [generateDocumentUrl, generateFundingDocumentUploadUrl, reason]);

  return getDocumentUploadInfo;
};

const useMakeUpload = (reason: UploadReason) => {
  const [, fundingDocumentUploaded] = useMutation<
    FundingDocumentUploadedMutation,
    FundingDocumentUploadedMutationVariables
  >(fundingDocumentUploadedMutation);

  const [, approveFundingDocument] = useMutation<
    ApproveFundingDocumentMutation,
    ApproveFundingDocumentMutationVariables
  >(approveFundingDocumentMutation);

  const [, uploadDocument] = useMutation<
    UploadDocumentMutation,
    UploadDocumentMutationVariables
  >(uploadDocumentMutation);

  const upload = useCallback(
    async ({
      documentId,
      values,
    }: {
      documentId: string;
      values: FormValues;
    }) => {
      if (reason === 'kyc') {
        return uploadDocument({
          playerId: values.playerId,
          documentId,
          comment: values.comment,
        }).then((res) => ({
          error: res.error,
        }));
      }

      const uploadRes = await fundingDocumentUploaded({
        documentId,
        playerId: values.playerId,
        fundingOption: values.fundingOption,
      });

      if (uploadRes.error) {
        return {
          error: uploadRes.error,
        };
      }

      if (values.autoApprove) {
        return approveFundingDocument({
          playerId: values.playerId,
          documentId,
          fundingOption: values.fundingOption,
          comment: values.comment,
        }).then((res) => ({
          error: res.error,
        }));
      }

      return {
        error: undefined,
      };
    },
    [approveFundingDocument, fundingDocumentUploaded, reason, uploadDocument],
  );

  return upload;
};

const PlayerDocumentUploadForm: FC<
  Props & {
    reason: UploadReason;
    form: Queries.PlayerDocumentUploadFormStaticQueryQuery['sanityPlayerDocumentUploadForm'];
  }
> = ({ isGlobalPlayer, playerOptions, form, reason }) => {
  const feedbackMessages = useFeedbackMessages();
  const params = useParams();
  const { close } = useModalContext();
  const { file, dispatch } = useFileDropFile();
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const getDocumentUploadInfo = useGetDocumentUploadInfo(reason);
  const triggerUpload = useMakeUpload(reason);

  const [isUploading, setIsUploading] = useState(false);

  const defaultValues: FormValues = {
    comment: '',
    playerId: params.playerId,
    fundingOption: '' as FundingOption,
    autoApprove: reason === 'sow',
  };

  const handleError = (err: Nullable<CombinedError>) => {
    if (err?.message && isMounted) {
      setErrorMessage(err.message);
    }
    if (err?.message) {
      throw new Error('Mutation error');
    }
  };

  const onSubmit = async (values: FormValues) => {
    if (!file) {
      return;
    }

    try {
      setErrorMessage(null);
      setIsUploading(true);

      const docInfo = await getDocumentUploadInfo();

      handleError(docInfo.error);

      const { documentId, url } = docInfo.documentInfo!;

      await fetch(url, {
        body: file,
        method: 'PUT',
        headers: { 'Content-Type': file.type as string },
      });

      const res = await triggerUpload({
        documentId,
        values,
      });

      const errorMessage = res.error?.message;

      if (errorMessage && isMounted) {
        setErrorMessage(errorMessage);
        setIsUploading(false);
      }

      if (errorMessage) {
        return;
      }

      close?.();

      feedback.success(t(feedbackMessages.success));
    } finally {
      if (isMounted) {
        setIsUploading(false);
      }
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-1 gap-6"
    >
      <FileDrop
        dispatch={dispatch}
        file={file}
        accept={{
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
        }}
        setErrorMessage={setErrorMessage}
      />
      {isGlobalPlayer ? (
        <SelectField
          title="Select Player"
          id="PlayerDocumentUploadForm__selectPlayer"
          name="playerId"
          options={playerOptions ? playerOptions : []}
          required
        />
      ) : (
        <TextField
          title="Player Id"
          name="playerId"
          id="PlayerDocumentUploadForm__playerId"
          value={params.playerId}
          readOnly
        />
      )}
      {reason === 'sow' && (
        <SelectField
          title={t(form?.fundingOptionLabel)}
          name="fundingOption"
          options={Object.values(FundingOption).map((fundingOption) => ({
            label: fundingOption,
            value: fundingOption,
          }))}
          required
        />
      )}
      <TextareaField
        name="comment"
        id="PlayerDocumentUploadForm__comment"
        title={t(form?.commentLabel)}
        rows={3}
      />
      {reason === 'sow' && (
        <CheckboxField
          name="autoApprove"
          id="PlayerDocumentUploadForm__autoApprove"
          title={t(form?.autoApproveLabel)}
        />
      )}
      <ErrorMessage message={errorMessage} />
      <SubmitButton value={t(form?.submit)} disabled={isUploading} />
    </Form>
  );
};

const PlayerDocumentUploadFormWrapper = (props: Props) => {
  const [reason, setReason] = useState<UploadReason | undefined>(undefined);

  const staticData =
    useStaticQuery<Queries.PlayerDocumentUploadFormStaticQueryQuery>(query);
  const form = staticData.sanityPlayerDocumentUploadForm;

  if (!form) {
    return null;
  }

  if (reason) {
    return (
      <Layout form={form}>
        <PlayerDocumentUploadForm {...props} form={form} reason={reason} />
      </Layout>
    );
  }

  return (
    <Layout form={form}>
      <div className="grid grid-cols-2 gap-3">
        <Button
          type="button"
          variant="primary"
          onClick={() => setReason('kyc')}
        >
          KYC
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => setReason('sow')}
        >
          SOW
        </Button>
      </div>
    </Layout>
  );
};

export default PlayerDocumentUploadFormWrapper;
