import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { predefinedTimeFrames } from '@/contexts/TimeContext/predefinedTimeFrames';
import { Nullable } from '@/types';
import { mapVariables } from '@/utils';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerRewardsNgrBoxQuery,
  PlayerRewardsNgrBoxQueryVariables,
  PlayerRewardsNgrSinceLastManualRewardQuery,
  PlayerRewardsNgrSinceLastManualRewardQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerRewardsNgrBlock on SanityPlayerRewardsNgrBlock {
    title {
      ...SanityLocaleString
    }
    ngrChange {
      ...SanityLocaleString
    }
    sinceLastManualReward {
      ...SanityLocaleString
    }
    percentageOfPlayersGgr {
      ...SanityLocaleString
    }
    percentageOfPlayersGgrNoCashback {
      ...SanityLocaleString
    }
  }
`;

const statsFragment = gql`
  fragment KpiStatsFragment on StatsType {
    rows {
      ngr
      ngrInRewards
      ggrInRewards
      ggrInRewardsNoCashback
      currency
    }
    error
  }
`;

const ngrSinceLastManualRewardFragment = gql`
  fragment KpiNgrSinceLastManualRewardFragment on StatsType {
    rows {
      ngrSinceLastManualReward
    }
    error
  }
`;

const QUERY_NGR = gql`
  query PlayerRewardsNgrBox(
    $id: ID!
    $allTimeFrom: OffsetDateTime!
    $allTimeTo: OffsetDateTime!
  ) {
    node(id: $id) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(granularity: All, from: $allTimeFrom, to: $allTimeTo) {
          ...KpiStatsFragment
        }
      }
    }
  }
  ${statsFragment}
`;

const QUERY_NGR_SINCE_LAST_MANUAL_REWARD = gql`
  query PlayerRewardsNgrSinceLastManualReward(
    $id: ID!
    $allTimeFrom: OffsetDateTime!
    $allTimeTo: OffsetDateTime!
  ) {
    node(id: $id) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(granularity: All, from: $allTimeFrom, to: $allTimeTo) {
          ...KpiNgrSinceLastManualRewardFragment
        }
      }
    }
  }
  ${ngrSinceLastManualRewardFragment}
`;

const getBaseNode = (data: Nullable<PlayerRewardsNgrBoxQuery>) => {
  if (
    data?.node?.__typename === 'Viewer' ||
    data?.node?.__typename === 'Player'
  ) {
    return data.node;
  }
  return null;
};

const getBaseNodeNgrSinceLastManualReward = (
  data: Nullable<PlayerRewardsNgrSinceLastManualRewardQuery>,
) => {
  if (
    data?.node?.__typename === 'Viewer' ||
    data?.node?.__typename === 'Player'
  ) {
    return data.node;
  }
  return null;
};

const getPlayerGgrPercentage = (ggrOfPlayer: number) => {
  if (ggrOfPlayer > 0) {
    return (
      <label className="text-4xl text-blue-500 font-semibold">
        {ggrOfPlayer.toFixed(2)} %{' '}
      </label>
    );
  }
  return <label className="text-4xl text-red-500 font-semibold">NA % </label>;
};

const PlayerRewardsNgrBlock: FC<{
  block: Queries.SanityPlayerRewardsNgrBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const allTime = mapVariables(predefinedTimeFrames['all-time'].getInterval());

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRewardsNgrBoxQuery,
    PlayerRewardsNgrBoxQueryVariables
  >({
    query: QUERY_NGR,
    variables: {
      id: params.playerId,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
    },
  });

  const [{ data: res, fetching: fetchingSinceLastManualReward }] = useQuery<
    PlayerRewardsNgrSinceLastManualRewardQuery,
    PlayerRewardsNgrSinceLastManualRewardQueryVariables
  >({
    query: QUERY_NGR_SINCE_LAST_MANUAL_REWARD,
    variables: {
      id: params.playerId,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
    },
  });

  const baseNode = getBaseNode(data);
  const baseNodeSinceLastManualReward =
    getBaseNodeNgrSinceLastManualReward(res);

  const row = baseNode?.stats?.rows[0];
  const rowNgrSinceLastManualReward =
    baseNodeSinceLastManualReward?.stats?.rows[0];

  const ggrOfPlayer = row?.ggrInRewards && row?.ggrInRewards * 100;
  const ggrNoCashbackOfPlayer =
    row?.ggrInRewardsNoCashback && row?.ggrInRewardsNoCashback * 100;

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching && fetchingSinceLastManualReward}
            title={t(block.ngrChange)}
            value={formatMoney(
              rowNgrSinceLastManualReward?.ngrSinceLastManualReward,
              row?.currency,
            )}
          />
          <div>
            <div className="text-center">
              <label className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
                {t(block.percentageOfPlayersGgrNoCashback)}
              </label>
            </div>
            <div className="text-center mt-3">
              {getPlayerGgrPercentage(ggrNoCashbackOfPlayer || 0)}
            </div>
          </div>
          <div>
            <div className="text-center">
              <label className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
                {t(block.percentageOfPlayersGgr)}
              </label>
            </div>
            <div className="text-center mt-3">
              {getPlayerGgrPercentage(ggrOfPlayer || 0)}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerRewardsNgrBlock;
