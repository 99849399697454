import gql from 'graphql-tag';
import { useMemo, useState } from 'react';
import { useQuery } from 'urql';

import {
  PlayersByIdsQuery,
  PlayersByIdsQueryVariables,
} from './__generated__/usePlayersByIds';

const PLAYERS_BY_IDS_QUERY = gql`
  query PlayersByIds($playerIds: [String!]!) {
    viewer {
      id
      playersSearchV2(playerIds: $playerIds, first: 1000) {
        edges {
          node {
            uuid
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export function usePlayersByIds() {
  const [playerIds, setPlayerIds] = useState<string[]>([]);
  const [{ data, fetching }, refresh] = useQuery<
    PlayersByIdsQuery,
    PlayersByIdsQueryVariables
  >({
    query: PLAYERS_BY_IDS_QUERY,
    pause: !playerIds.length,
    variables: {
      playerIds,
    },
  });

  const players = useMemo(() => {
    const edges = data?.viewer.playersSearchV2?.edges;

    return edges?.reduce<
      NonNullable<
        NonNullable<
          PlayersByIdsQuery['viewer']['playersSearchV2']['edges']
        >[number]
      >['node'][]
    >((acc, edge) => {
      if (edge?.node) {
        acc = [...acc, edge.node];
      }

      return acc;
    }, []);
  }, [data?.viewer.playersSearchV2.edges]);

  return {
    refresh,
    fetching,
    players,
    setPlayerIds,
  };
}
