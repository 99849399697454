import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import {
  useGetGlobalPlayerOverviewPageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EyeIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { AmlRiskLevel, PlayerComplianceStatus } from '@/globalTypes';
import useSourceOfWealth from './useSourceOfWealth';

const BLOCK_STATIC_QUERY = graphql`
  query SanitySourceOfWealthBlockStaticQuery {
    sanitySourceOfWealthBlock {
      title {
        ...SanityLocaleString
      }
      playerGlobalId {
        ...SanityLocaleString
      }
      statusChangedAt {
        ...SanityLocaleString
      }
      limitReachedAt {
        ...SanityLocaleString
      }
      submittedAt {
        ...SanityLocaleString
      }
      risk {
        ...SanityLocaleString
      }
      status {
        ...SanityLocaleString
      }
      brands {
        ...SanityLocaleString
      }
    }
  }
`;

const SourceOfWealthBlock: FC = () => {
  const { t } = useTranslate();
  const getGlobalPlayerOverviewPageLink = useGetGlobalPlayerOverviewPageLink();

  const block =
    useStaticQuery<Queries.SanitySourceOfWealthBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanitySourceOfWealthBlock;

  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const {
    entries,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useSourceOfWealth();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'sourceOfWealth',
    data: entries,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'playerGlobalUUID',
        title: 'Global Player ID',
        linkTo: ({ row }) =>
          getGlobalPlayerOverviewPageLink(row.playerGlobalId) || '',
      }),
      s.dateTimeValue({
        field: 'statusChangedAt',
        title: t(block?.statusChangedAt),
        fromFilterField: 'statusChangedAtFrom',
        toFilterField: 'statusChangedAtTo',
        orderBy: 'statusChangedAt',
      }),
      s.dateTimeValue({
        field: 'limitReachedAt',
        title: t(block?.limitReachedAt),
        fromFilterField: 'limitReachedAtFrom',
        toFilterField: 'limitReachedAtTo',
        orderBy: 'limitReachedAt',
      }),
      s.dateTimeValue({
        field: 'submittedAt',
        title: t(block?.submittedAt),
        fromFilterField: 'submittedAtFrom',
        toFilterField: 'submittedAtTo',
        orderBy: 'submittedAt',
      }),
      s.enumValue({
        field: 'risk',
        title: t(block?.risk),
        filterField: 'amlLevel',
        e: AmlRiskLevel,
      }),
      s.enumValue({
        field: 'status',
        title: t(block?.status),
        filterField: 'complianceStatus',
        e: PlayerComplianceStatus,
      }),
      s.stringValue({
        field: 'brands',
        title: t(block?.brands),
        wrap: ({ row }) => (
          <div className="flex items-center">
            {row.players.length}
            <ControlledModal
              content={
                <Card
                  title="Player brands"
                  size="lg"
                  options={
                    <CardOptions>
                      <CardCloseButton />
                    </CardOptions>
                  }
                >
                  <div className="p-3">
                    <Table
                      headings={['Player ID', 'Brand']}
                      rows={row.players.map((player) => ({
                        key: player.playerId,
                        data: [
                          <Link
                            className="w-full h-full p-3 block"
                            to={getPlayerOverviewLink(player.playerId) ?? ''}
                          >
                            {player.playerUUID}
                          </Link>,
                          player.brand.name,
                        ],
                      }))}
                    />
                  </div>
                </Card>
              }
            >
              <InlineIconButton>
                <EyeIcon />
              </InlineIconButton>
            </ControlledModal>
          </div>
        ),
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default SourceOfWealthBlock;
